define("ember-svg-jar/inlined/diagram-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 4a1 1 0 100 2 1 1 0 000-2zm-2.83 0a3.001 3.001 0 110 2H13v5h2.17a3.001 3.001 0 110 2H13v5h2.17a3.001 3.001 0 110 2H11v-7H8.83a3.001 3.001 0 110-2H11V4h4.17zM6 11a1 1 0 100 2 1 1 0 000-2zm12 0a1 1 0 100 2 1 1 0 000-2zm0 7a1 1 0 100 2 1 1 0 000-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});