define("ember-svg-jar/inlined/float", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.88 16.34A8.981 8.981 0 0021 12c0-1.57-.41-3.05-1.12-4.34.2-.96-.07-1.99-.81-2.74a2.978 2.978 0 00-2.74-.81 8.981 8.981 0 00-8.67.01 2.985 2.985 0 00-3.54 3.54A8.981 8.981 0 003 12c0 1.57.41 3.05 1.12 4.34a2.985 2.985 0 003.54 3.54C8.95 20.59 10.43 21 12 21c1.57 0 3.05-.41 4.34-1.12a2.985 2.985 0 003.54-3.54zM6.34 7.76a.996.996 0 111.41-1.41l1.41 1.41a.996.996 0 11-1.41 1.41L6.34 7.76zm0 5.65l-.93.93C5.15 13.61 5 12.82 5 12c0-.82.15-1.61.41-2.34l.93.93c.22.22.48.39.75.53-.05.28-.09.58-.09.88 0 .3.03.6.09.89-.27.13-.52.3-.75.52zM12 7c-.3 0-.6.04-.89.09-.14-.27-.31-.52-.53-.75l-.93-.93C10.39 5.15 11.18 5 12 5c.82 0 1.61.15 2.35.41l-.93.93c-.22.22-.39.48-.53.74A5.26 5.26 0 0012 7zm4.24-.66a.996.996 0 111.41 1.41l-1.41 1.41a.996.996 0 11-1.41-1.41l1.41-1.41zM7.76 17.66a.996.996 0 11-1.41-1.41l1.41-1.41a.996.996 0 111.41 1.41l-1.41 1.41zm4.84-2.72c-.2.04-.4.06-.6.06-.2 0-.4-.02-.6-.07-.11-.56-.38-1.09-.81-1.52-.43-.43-.96-.7-1.52-.81-.05-.19-.07-.4-.07-.6 0-.21.02-.41.06-.6.56-.11 1.09-.38 1.53-.82.43-.43.7-.97.82-1.53.19-.03.39-.05.59-.05.2 0 .4.02.6.06.11.56.38 1.09.82 1.53.43.43.97.7 1.53.82.03.19.05.39.05.59 0 .21-.02.41-.06.6-.56.11-1.09.38-1.53.82-.44.44-.7.96-.81 1.52zm5.06-4.35l.93-.93c.26.73.41 1.52.41 2.34 0 .82-.15 1.61-.41 2.34l-.93-.93c-.22-.22-.48-.39-.75-.53.05-.28.09-.58.09-.88 0-.3-.03-.6-.09-.89.27-.13.52-.3.75-.52zM12 19c-.82 0-1.61-.15-2.35-.41l.93-.93c.22-.22.39-.48.53-.74.29.05.59.08.89.08.3 0 .6-.04.89-.09.14.27.31.52.53.75l.93.93c-.74.26-1.53.41-2.35.41zm4.24-1.34l-1.41-1.41a.996.996 0 111.41-1.41l1.41 1.41a.996.996 0 11-1.41 1.41z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});