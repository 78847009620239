define("ember-svg-jar/inlined/trashcan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 5h-6c0-1.65-1.35-3-3-3S9 3.35 9 5H3v2h2v11.75C5 20.54 6.66 22 8.7 22h6.6c2.04 0 3.7-1.46 3.7-3.25V7h2V5zm-9-1c.55 0 1 .45 1 1h-2c0-.55.45-1 1-1zm5 14.75c0 .68-.78 1.25-1.7 1.25H8.7c-.92 0-1.7-.57-1.7-1.25V7h10v11.75zM15 9h-2v9h2V9zM9 9h2v9H9V9z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});