define("ember-svg-jar/inlined/sign-jpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.521 5l3.382 6.5H7v2h3.944l.056.109V15H7v2h4v3h2v-3h4v-2h-4v-1.393l.055-.107H17v-2h-2.913L17.44 5h-2.25l-3.194 6.19L8.776 5H6.52z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});