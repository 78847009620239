define("@square/sq-ember-eventstream/components/eventstream-click", ["exports", "@ember/service", "@ember/component", "ember-jquery-legacy", "@square/sq-ember-eventstream/utils/dom"], function (_exports, _service, _component, _emberJqueryLegacy, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // In the case of Ember engines it is possible for usages of this component
  // to be nested. To avoid changing any behavior of the click event while also
  // preventing this component from tracking a single click more than once
  // we store processed events in a map.
  const processedEvents = new WeakMap();

  var _default = _component.default.extend({
    eventstream: (0, _service.inject)(),
    metadata: null,

    // This action is triggered by all clicks within this component
    // in order to ensure that all clicks are tracked by Eventstream.
    click(event) {
      event = (0, _emberJqueryLegacy.normalizeEvent)(event);

      if (processedEvents.has(event)) {
        return;
      }

      const element = event.target;
      const ancestor = (0, _dom.closestAncestorWithId)(element);
      this.get('eventstream').trackPageClick(Object.assign({
        elementIdentifier: ancestor ? ancestor.id : '',
        elementTitle: element.title,
        elementType: element.tagName.toLowerCase()
      }, this.get('metadata')));
      processedEvents.set(event, 1);
    }

  });

  _exports.default = _default;
});