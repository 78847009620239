define("ember-svg-jar/inlined/drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 14a2 2 0 100-4 2 2 0 000 4zM16 12a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zM5 11a1 1 0 100 2h2a1 1 0 100-2H5z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 4a3 3 0 00-3 3v10a3 3 0 003 3h2a1 1 0 001 1h3a1 1 0 001-1h4a1 1 0 001 1h3a1 1 0 001-1h2a3 3 0 003-3V7a3 3 0 00-3-3H3zm18 14a1 1 0 001-1V7a1 1 0 00-1-1H3a1 1 0 00-1 1v10a1 1 0 001 1h18z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});