define("ember-svg-jar/inlined/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 2a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V7.586L13.414 2H8zM7 5a1 1 0 011-1h4v5h5v10a1 1 0 01-1 1H8a1 1 0 01-1-1V5zm8.586 2L14 5.414V7h1.586z\" fill=\"#7B61FF\"/><path d=\"M15 18H9v-2h6v2zM9 14h6v-2H9v2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});