define("ember-svg-jar/inlined/square-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 7c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h14zm0-2H5C3.34 5 2 6.34 2 8v8c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3z\" fill=\"#7B61FF\"/><path d=\"M10.92 11.067c-.01.034-.01.077-.01.162v1.542c0 .085 0 .128.01.162.023.07.077.124.147.147.034.01.077.01.162.01h1.542c.085 0 .128 0 .162-.01a.228.228 0 00.147-.147c.01-.034.01-.077.01-.162v-1.542c0-.085 0-.128-.01-.162a.228.228 0 00-.147-.147c-.034-.01-.077-.01-.162-.01h-1.542c-.085 0-.128 0-.162.01a.228.228 0 00-.147.147z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 9.58c0-.245 0-.367.016-.47a1.317 1.317 0 011.095-1.094C9.213 8 9.335 8 9.58 8h4.84c.245 0 .367 0 .47.016.563.09 1.005.531 1.094 1.095.016.102.016.224.016.469v4.84c0 .245 0 .367-.016.47a1.317 1.317 0 01-1.095 1.094c-.102.016-.224.016-.469.016H9.58c-.245 0-.367 0-.47-.016a1.317 1.317 0 01-1.094-1.095C8 14.787 8 14.665 8 14.42V9.58zm1.6.394c0-.206.168-.374.374-.374h4.052c.207 0 .374.168.374.374v4.052a.374.374 0 01-.374.374H9.974a.374.374 0 01-.374-.374V9.974z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});