define("ember-svg-jar/inlined/bell-z", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.117 4H16V2h6v2.003L18.883 7H22v2h-6V6.997L19.117 4z\" fill=\"#7B61FF\"/><path d=\"M12 3a2 2 0 012 2v2.535A4 4 0 008 11v6h8v-6h2v6h2v2h-6a2 2 0 11-4 0H4v-2h2v-6a6.002 6.002 0 014-5.659V5a2 2 0 012-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});