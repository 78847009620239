define("ember-svg-jar/inlined/service-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 14c0-.523.435-1.264 1.35-1.95.886-.665 1.93-1.05 2.65-1.05V9c-1.28 0-2.736.615-3.85 1.45C7.065 11.264 6 12.523 6 14h2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 2H9v2h2v2.048C5.868 6.551 2 10.908 2 17v1h7v2H2v2h20v-2h-7v-2h7v-1c0-6.091-3.868-10.45-9-10.952V4h2V2zm-3 6c4.108 0 7.55 3.064 7.96 8H4.04c.41-4.936 3.852-8 7.96-8zm-1 12v-2h2v2h-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});