define("ember-svg-jar/inlined/alipay-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><g clip-path=\"url(#clip0_118097_225794)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#1677FF\"><path d=\"M22.808 13.917c2.144.724 2.627.763 2.627.763V6.903c0-1.327-1.065-2.403-2.38-2.403H12.948a2.392 2.392 0 00-2.382 2.403v10.193a2.393 2.393 0 002.382 2.404h10.109c1.314 0 2.38-1.078 2.38-2.404v-.098s-3.867-1.625-5.821-2.574c-1.31 1.626-3 2.612-4.755 2.612-2.967 0-3.974-2.618-2.57-4.343.307-.375.828-.734 1.637-.935 1.264-.313 3.278.196 5.167.824.351-.657.631-1.349.836-2.065h-5.816v-.595h2.999V8.858h-3.631v-.595h3.631V6.744s0-.256.257-.256h1.466v1.775h3.59v.595h-3.59v1.065h2.93a12.151 12.151 0 01-1.238 3.162c.889.324 1.688.632 2.282.832\"/><path d=\"M14.18 12.647c-.373.038-1.07.203-1.45.543-1.144 1.004-.46 2.844 1.854 2.844 1.344 0 2.688-.867 3.744-2.256-1.502-.739-2.774-1.267-4.149-1.13z\"/></g><defs><clipPath id=\"clip0_118097_225794\"><path fill=\"#fff\" transform=\"translate(10.5 4.5)\" d=\"M0 0h15v15H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});