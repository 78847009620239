define("ember-svg-jar/inlined/donation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_102974_213056)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#7B61FF\"><path d=\"M16.2 3C14.98 3 14 4.004 14 5.229v.156c0 .59.23 1.156.642 1.574L18 10.37l3.358-3.411c.412-.418.642-.985.642-1.574V5.23C22 4.004 21.02 3 19.8 3h-.154c-.586 0-1.146.236-1.558.655l-.088.09-.088-.09A2.186 2.186 0 0016.354 3H16.2zm-.346 2.756a.652.652 0 01-.212-.465c0-.342.277-.62.62-.62.189 0 .346.095.472.22L18 6.177l1.25-1.27c.127-.127.29-.235.486-.235a.619.619 0 01.428 1.065L18 7.937l-2.146-2.18z\"/><path d=\"M1 7a1 1 0 00-1 1v12a1 1 0 001 1h3a1 1 0 001-1h2.647c.163.117.381.257.656.394.622.312 1.524.606 2.697.606h6.835a1 1 0 00.707-.293l4.582-4.581a3 3 0 00-4.243-4.243l-2.019 2.02A3.001 3.001 0 0014 10h-1.447a5.542 5.542 0 00-.596-.707C11.348 8.683 10.36 8 9 8H5a1 1 0 00-1-1H1zm7 11H5v-8h4c.641 0 1.152.316 1.543.707a3.554 3.554 0 01.563.741v.002l.002.002A1 1 0 0012 12h2a1 1 0 01.003 2H11a2 2 0 01-2-2H7a4 4 0 004 4h6.178a1 1 0 00.707-.293l2.41-2.41a1 1 0 111.414 1.415L17.421 19H11c-.827 0-1.425-.206-1.803-.394a2.703 2.703 0 01-.492-.316l-.008-.007A1 1 0 008 18zm-6 1V9h1v10H2z\"/></g><defs><clipPath id=\"clip0_102974_213056\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});