define("ember-svg-jar/inlined/trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 1a3 3 0 00-3 3v1H1v1a6.001 6.001 0 005.071 5.929A6.005 6.005 0 0011 16.917V21H7v2h10v-2h-4v-4.083a6.005 6.005 0 004.929-4.988A6.001 6.001 0 0023 6V5h-5V4a3 3 0 00-3-3H9zm7 6H8v4a4 4 0 008 0V7zM8 5h8V4a1 1 0 00-1-1H9a1 1 0 00-1 1v1zM6 7H3.126A4.007 4.007 0 006 9.874V7zm14.874 0A4.007 4.007 0 0118 9.874V7h2.874z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});