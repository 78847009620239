define("ember-svg-jar/inlined/money-bag-sign-jpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.022 13.514L8.22 10h2.248l1.534 2.992L13.524 10h2.244l-1.787 3.514h.98v2h-1.955v.53h1.954v2h-1.954v.955h-2v-.955h-1.96v-2h1.96v-.53h-1.96v-2h.976z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.94 7H18V5h-2.72l1-4H7.72l1.13 4.53c-4.06 2.7-6.43 7.96-5.34 12.08C4.42 21.04 7.52 23 12 23s7.58-1.96 8.48-5.38c.92-3.47-.61-7.74-3.54-10.62zm-3.22-4l-.5 2h-2.44l-.5-2h3.44zm4.83 14.1C17.87 19.65 15.61 21 12 21s-5.87-1.35-6.55-3.9c-.9-3.39 1.31-7.95 4.84-10.1h3.43c3.52 2.15 5.73 6.71 4.83 10.1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});