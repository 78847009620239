define("@square/environment-utils/environment", ["exports", "ember"], function (_exports, _ember2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = environment;

  /**
   * Returns a plain object describing the current environment.
   *
   * @param  {Object} [opts={}] Object containing overrides for information that
   *   is derived from the global environment.
   * @return {Object} Environment object
   */
  function environment() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    const _window = opts.window || window;

    const _ember = opts.Ember || _ember2.default;

    const _userAgent = opts.userAgent || navigator.userAgent;

    const isMobile = isUserAgentMobile(_userAgent);
    const isDevelopment = isDevelopmentHost(_window.location.hostname);
    const isStaging = isStagingHost(_window.location.hostname);
    const isTesting = isTestEnvironment(_ember, _window);
    const isSandbox = isSandboxHost(_window.location.hostname);
    const isProduction = !isDevelopment && !isSandbox && !isStaging && !isTesting;
    return {
      isMobile,
      isDevelopment,
      isTesting,
      isStaging,
      isSandbox,
      isProduction,
      name: getEnvironmentName(isDevelopment, isTesting, isStaging, isSandbox)
    };
  }
  /**
   * Returns a name for the current environment.
   *
   * @param  {Boolean} isDevelopment
   * @param  {Boolean} isTesting
   * @param  {Boolean} isStaging
   * @param  {Boolean} isSandbox
   * @return {String} Name of the environment
   */


  function getEnvironmentName(isDevelopment, isTesting, isStaging, isSandbox) {
    if (isTesting) {
      return 'testing';
    } else if (isDevelopment) {
      return 'development';
    } else if (isStaging) {
      return 'staging';
    } else if (isSandbox) {
      return 'sandbox';
    } else {
      return 'production';
    }
  }

  function isUserAgentMobile(userAgent) {
    // using mobi instead of mobile since mobi is in the Opera UA strings, not mobile
    // as of 02/12/14 (b)
    return /mobi|android|iphone/i.test(userAgent) && !/ipad/i.test(userAgent);
  }

  function isDevelopmentHost(hostname) {
    return hostname === 'localhost' || /squareup\.com\.[-a-z,A-Z]*\.local/.test(hostname);
  }

  function isSandboxHost(hostname) {
    return /^squareupsandbox.com/.test(hostname);
  }

  function isStagingHost(hostname) {
    return /^broadway\.squareup\.com|^(\w*\.)?squareupstaging.com|^[A-Za-z0-9-_]*.sso.stage.global.square/.test(hostname);
  }

  function isTestEnvironment(emberGlobal, windowObject) {
    return emberGlobal.testing === true || windowObject.runningTests === true;
  }
});