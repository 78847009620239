define("ember-svg-jar/inlined/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 12a1 1 0 100-2 1 1 0 000 2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 6V4a2 2 0 012-2h10a2 2 0 012 2v2h2a3 3 0 013 3v7a3 3 0 01-3 3h-2v3H5v-3H3a3 3 0 01-3-3V9a3 3 0 013-3h2zm2-2h10v2H7V4zM5 17v-3h14v3h2a1 1 0 001-1V9a1 1 0 00-1-1H3a1 1 0 00-1 1v7a1 1 0 001 1h2zm12-1H7v4h10v-4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});