define("ember-svg-jar/inlined/receipt-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 15a3 3 0 00-3 3H7a5 5 0 0110 0h-2a3 3 0 00-3-3z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.997 6a3 3 0 100 6 3 3 0 000-6zm-1 3a1 1 0 112 0 1 1 0 01-2 0z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 0v24h2a1 1 0 112 0h2a1 1 0 112 0h2a1 1 0 112 0h2a1 1 0 112 0h2V0h-2a1 1 0 11-2 0h-2a1 1 0 11-2 0h-2a1 1 0 11-2 0H7a1 1 0 01-2 0H3zm16 2.83c-.313.11-.65.17-1 .17-.768 0-1.47-.289-2-.764A2.989 2.989 0 0114 3c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0110 3c-.768 0-1.47-.289-2-.764A2.989 2.989 0 016 3c-.35 0-.687-.06-1-.17v18.34c.313-.11.65-.17 1-.17.768 0 1.47.289 2 .764A2.989 2.989 0 0110 21c.768 0 1.47.289 2 .764A2.989 2.989 0 0114 21c.768 0 1.47.289 2 .764A2.989 2.989 0 0118 21c.35 0 .687.06 1 .17V2.83z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});