define("ember-svg-jar/inlined/sign-dollar-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.925 7.36c-.99 0-2.09.43-2.09 1.47 0 .892 1.045 1.306 2.283 1.797 1.787.71 3.975 1.578 3.987 4.273 0 2.05-1.57 3.56-3.52 3.95V21h-2.32v-2.17c-1.34-.26-2.58-.91-3.64-1.9l1.7-1.78c.77.85 1.95 1.49 3.19 1.49 1.16 0 1.97-.71 1.97-1.7 0-1.205-1.208-1.681-2.556-2.212-.599-.236-1.224-.482-1.784-.808-1.07-.62-1.92-1.55-1.92-3.08 0-1.87 1.35-3.15 3.02-3.61V3h2.32v2.09c1.05.12 2.05.48 3.06 1.22l-1.36 1.94c-.54-.5-1.45-.89-2.34-.89zM13.625 10.997h4.588l-1.792-1.793 1.414-1.414 3.5 3.5a1 1 0 010 1.414l-3.5 3.5-1.414-1.414 1.793-1.793h-4.589v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});