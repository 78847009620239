define("ember-svg-jar/inlined/seat-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 3a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 5a4 4 0 00-4 4v6a4 4 0 004 4h6a4 4 0 004-4V9a4 4 0 00-4-4H9zm6 2H9a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2V9a2 2 0 00-2-2z\" fill=\"#7B61FF\"/><path d=\"M10 20a1 1 0 100 2h4a1 1 0 100-2h-4zM21 9a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM4 10a1 1 0 00-2 0v4a1 1 0 102 0v-4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});