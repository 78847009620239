define("ember-svg-jar/inlined/calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 5a1 1 0 011-1h8a1 1 0 011 1v6a1 1 0 01-1 1H8a1 1 0 01-1-1V5zm2 1v4h6V6H9z\" fill=\"#7B61FF\"/><path d=\"M9 16v-2H7v2h2zM7 18h6v2H7v-2zM13 14h-2v2h2v-2zM17 14v2h-2v-2h2zM17 20v-2h-2v2h2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 3a3 3 0 013-3h12a3 3 0 013 3v18a3 3 0 01-3 3H6a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v18a1 1 0 001 1h12a1 1 0 001-1V3a1 1 0 00-1-1H6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});