define("ember-svg-jar/inlined/pencil-in-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.21.83c-.55-.55-1.28-.83-2-.83s-1.45.28-2 .83l-11 11v4h4l11-11c1.1-1.1 1.1-2.9 0-4zm-2.58 1.41a.843.843 0 011.18 0c.22.21.24.46.24.59 0 .13-.03.37-.24.59l-.59.59-1.17-1.17.58-.6zm-9.25 11.59h-1.17v-1.17l8.41-8.41 1.17 1.17-8.41 8.41z\" fill=\"#7B61FF\"/><path d=\"M6 3h8.229l-2 2H6a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1v-6.139l2-2V18a3 3 0 01-3 3H6a3 3 0 01-3-3V6a3 3 0 013-3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});