define("ember-svg-jar/inlined/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 5H5C3.34 5 2 6.34 2 8v8c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3zm1 11c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-5h16v5zM4 9V8c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v1H4z\" fill=\"#7B61FF\"/><path d=\"M17 13h-3v2h3v-2zM12 13H6v2h6v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});