define("ember-page-title/services/page-title", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageTitleService extends _service.default {
    titleDidUpdate()
    /* title */
    {}

  }

  _exports.default = PageTitleService;
});