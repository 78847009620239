define("ember-svg-jar/inlined/arrows-outward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 3a1 1 0 00-1 1v7h2V6.414l5.293 5.293 1.414-1.414L6.414 5H11V3H4zM21 20a1 1 0 01-1 1h-7v-2h4.586l-5.293-5.293 1.414-1.414L19 17.586V13h2v7z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});