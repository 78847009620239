define("ember-svg-jar/inlined/send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22 12L2 4l2 8-2 8 20-8zM5.81 13h8.3l-9.23 3.69.93-3.69zm8.3-2h-8.3l-.92-3.69L14.11 11z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});