define("ember-svg-jar/inlined/dual-rotating-square-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.414 7l1.293 1.293-1.414 1.414-3-3a1 1 0 010-1.414l3-3 1.414 1.414L16.414 5h1.583a3 3 0 013 3v3h-2V8a1 1 0 00-1-1h-1.583z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.997 3h-8v8h8V3zm-6 6V5h4v4h-4zM20.997 13h-8v8h8v-8zm-2 6h-4v-4h4v4z\" fill=\"#7B61FF\"/><path d=\"M2.997 13v3a3 3 0 003 3h1.589l-1.293 1.293 1.414 1.414 3-3a1 1 0 000-1.414l-3-3-1.414 1.414L7.586 17h-1.59a1 1 0 01-1-1v-3h-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});