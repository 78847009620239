define("ember-svg-jar/inlined/food-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 17H9v-2h6v2zM9 13h6v-2H9v2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 4.63c0-1.952-2.054-3.221-3.799-2.349L5.553 6.106v.003A1.01 1.01 0 005 7.01V19a3 3 0 003 3h8a3 3 0 003-3V9a3.001 3.001 0 00-2-2.83V4.63zm-2.905-.56a.625.625 0 01.905.56V6h-4.764l3.86-1.93zM7 19V8h9a1 1 0 011 1v10a1 1 0 01-1 1H8a1 1 0 01-1-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});