define("ember-svg-jar/inlined/chart-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21 5c-1.66 0-3 1.34-3 3 0 .46.11.89.3 1.29l-2.01 2.01c-.4-.18-.83-.3-1.29-.3-.31 0-.61.06-.89.15L11.5 7.66c.31-.47.5-1.04.5-1.66 0-1.66-1.34-3-3-3S6 4.35 6 6c0 .77.3 1.46.77 2l-3.3 6.05C3.32 14.02 3.16 14 3 14c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3c0-.77-.3-1.46-.77-1.99l3.3-6.05c.15.02.31.04.47.04.31 0 .61-.06.89-.15l2.62 3.49c-.32.48-.51 1.05-.51 1.66 0 1.66 1.34 3 3 3s3-1.34 3-3c0-.46-.11-.89-.3-1.29l2.01-2.01c.4.19.83.3 1.29.3 1.66 0 3-1.34 3-3 0-1.65-1.34-3-3-3zM3 17.99c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM9 7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 7.99c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM21 9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});