define("ember-svg-jar/inlined/id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.838 8.452C4.838 7.65 4.21 7 3.433 7s-1.405.65-1.405 1.452c0 .802.629 1.452 1.405 1.452s1.405-.65 1.405-1.452zM20.43 12.6c0 3.073-.945 4.505-2.973 4.505-.699 0-1.792-.26-2.85-.512H14.6c-.85-.203-1.66-.395-2.22-.439V9.046c.561-.044 1.373-.236 2.226-.44h.003c1.057-.251 2.149-.51 2.847-.51 2.028 0 2.973 1.43 2.973 4.504zm-9.155-4.205c-.347 0-.752-.048-1.205-.143a10.677 10.677 0 01-1.595-.473h-.001a.214.214 0 00-.28.126.226.226 0 00.123.29c.029.01.676.27 1.494.457v7.9c-.818.187-1.465.446-1.494.458a.226.226 0 00-.123.29.214.214 0 00.28.126h.001c.04-.016.754-.298 1.595-.474a5.978 5.978 0 011.204-.143c.596 0 1.582.264 2.626.542l.004.001c1.193.319 2.426.648 3.302.648 1.495 0 2.887-.45 3.92-1.265C22.352 15.767 23 14.338 23 12.6c0-1.736-.648-3.165-1.874-4.132-1.034-.815-2.425-1.265-3.92-1.265-.876 0-2.11.33-3.302.648l-.004.001c-1.044.279-2.03.542-2.626.542zm-7.022 8.66a10.037 10.037 0 00-.819-.033c-.382 0-.747.019-1.084.056-.72.085-1.042.23-1.045.232a.214.214 0 01-.285-.112.226.226 0 01.106-.294c.014-.007.32-.147.983-.243v-5.263a3.891 3.891 0 01-.991-.32.227.227 0 01-.094-.3.213.213 0 01.292-.097c.025.013.434.219 1.04.308a3.564 3.564 0 002.092-.311.213.213 0 01.288.105.226.226 0 01-.102.298c-.035.017-.07.031-.104.046l-.043.018v5.483c.851.09 1.234.268 1.251.276a.224.224 0 01.106.296.213.213 0 01-.286.11c-.01-.005-.41-.183-1.305-.255z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});