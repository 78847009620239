define("ember-svg-jar/inlined/mastercard-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.599 17.498H21.4V6.502h-6.802v10.997z\" fill=\"#F16022\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.398 12.001c-.002-2.148.957-4.178 2.602-5.504-2.958-2.388-7.24-1.86-9.564 1.178-2.324 3.04-1.81 7.44 1.147 9.828a6.667 6.667 0 008.417 0c-1.644-1.326-2.603-3.354-2.602-5.502z\" fill=\"#E91D25\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M29.02 12.001c0 3.866-3.05 6.999-6.811 6.999a6.696 6.696 0 01-4.21-1.497c2.958-2.388 3.471-6.788 1.147-9.828A6.91 6.91 0 0018 6.497c2.956-2.389 7.238-1.862 9.563 1.177a7.124 7.124 0 011.457 4.325v.002z\" fill=\"#F69E1E\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});