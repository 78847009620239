define("ember-svg-jar/inlined/dogeared-paper-sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.236 10v.965c-.706.284-1.23.872-1.23 1.708 0 .707.486 1.415 1.354 1.73.696.253 1.056.403 1.261.558a.38.38 0 01.124.133.483.483 0 01.037.203.577.577 0 01-.13.204c-.102.104-.31.245-.733.245-.486 0-1.015-.353-1.37-.735l-1.099 1.02c.357.385.99.916 1.786 1.125V18h1.5v-.866c1.075-.315 1.547-1.227 1.547-1.81 0-.304-.051-.603-.188-.885a1.867 1.867 0 00-.57-.675c-.421-.318-1.02-.54-1.653-.77-.341-.125-.365-.312-.366-.321 0-.071.029-.156.14-.24.118-.087.34-.176.683-.161.233.01.366.038.49.087.134.053.302.15.581.362l.907-1.195c-.322-.244-.616-.435-.935-.562a2.616 2.616 0 00-.636-.162V10h-1.5z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 2a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V7.586L13.414 2H8zM7 5a1 1 0 011-1h4v5h5v10a1 1 0 01-1 1H8a1 1 0 01-1-1V5zm8.586 2L14 5.414V7h1.586z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});