define("ember-svg-jar/inlined/eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.09 11.58C3.97 7.52 7.77 5 12 5s8.03 2.52 9.91 6.58c.12.26.12.57 0 .84C20.03 16.48 16.23 19 12 19s-8.03-2.52-9.91-6.58c-.12-.26-.12-.57 0-.84zm2.02.42c1.61 3.1 4.6 5 7.89 5s6.28-1.9 7.89-5c-1.61-3.1-4.6-5-7.89-5s-6.28 1.9-7.89 5zM8 12c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4zm2 0c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});