define("ember-svg-jar/inlined/afterpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.594 6.583l-2.782-1.592-2.822-1.616c-1.865-1.07-4.197.274-4.197 2.428v.362c0 .201.105.386.282.482l1.31.748a.543.543 0 00.812-.474v-.86c0-.427.459-.692.829-.483l2.572 1.48 2.565 1.47c.37.21.37.748 0 .957l-2.565 1.472-2.572 1.48a.554.554 0 01-.829-.483v-.426c0-2.155-2.331-3.506-4.196-2.428l-2.814 1.624-2.782 1.591c-1.873 1.078-1.873 3.787 0 4.865l2.782 1.591 2.822 1.616c1.865 1.07 4.197-.273 4.197-2.428v-.361a.545.545 0 00-.282-.483l-1.31-.747a.543.543 0 00-.812.474v.86a.554.554 0 01-.829.482l-2.572-1.479-2.565-1.471a.551.551 0 010-.957l2.565-1.471 2.572-1.48a.554.554 0 01.829.483v.426c0 2.155 2.331 3.505 4.196 2.428l2.822-1.616 2.782-1.592c1.865-1.085 1.865-3.795-.008-4.872z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});