define("ember-svg-jar/inlined/car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_142945_223717)\" fill=\"#7B61FF\"><path d=\"M10 13h4v2h-4v-2z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.421 3h6.422a3 3 0 012.6 1.505l3.136 5.453H22a2 2 0 012 2V17a2 2 0 01-2 2h-1.145a3.502 3.502 0 01-6.71 0h-4.29a3.502 3.502 0 01-6.71 0H2a2 2 0 01-2-2v-5.042a2 2 0 011.362-1.896L5.422 3zm-.398 15.261v-.522a1.5 1.5 0 110 .522zM3.145 17H2v-5.042h17V12a3 3 0 003 3v2h-1.145a3.502 3.502 0 00-6.71 0h-4.29a3.502 3.502 0 00-6.71 0zm14.355-.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM3.728 9.958H8V5H6.579l-2.85 4.958zM10 5v4.958h5.272L12.71 5.502A1 1 0 0011.843 5H10zm11 6.958V12a1 1 0 001 1v-1.042h-1z\"/></g><defs><clipPath id=\"clip0_142945_223717\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});