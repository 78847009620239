define("ember-svg-jar/inlined/house-account-sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.288 17.036a3.881 3.881 0 01-1.614-.815l-.174-.148 1.137-1.074.174.172a1.92 1.92 0 001.31.557c.4 0 .686-.204.686-.502 0-.329-.33-.478-1.033-.728l-.08-.03c-.276-.098-.543-.194-.788-.323-.668-.353-1.007-.846-1.007-1.48 0-.761.539-1.403 1.407-1.678V10h1.553v.909c.47.078.903.25 1.329.525l.19.125-.92 1.183-.2-.164c-.225-.188-.598-.314-.928-.314-.026 0-.738.008-.738.408 0 .227.443.392.912.556l.022.008c.84.305 1.974.716 1.974 1.99 0 .854-.66 1.583-1.64 1.826V18h-1.572v-.964z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 5a3 3 0 013-3h9v4.17c1.165.413 2 1.524 2 2.83v10a3 3 0 01-3 3H8a3 3 0 01-3-3V5zm3-1h7v2H8a1 1 0 010-2zM7 8h9a1 1 0 011 1v10a1 1 0 01-1 1H8a1 1 0 01-1-1V8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});