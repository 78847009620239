define("ember-svg-jar/inlined/question-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 4C8.975 4 7 5.764 7 8.5h2C9 7.762 10.031 6 12 6s3 1.065 3 2.5c0 1.576-2.194 2.229-3.154 2.515-.168.05-.298.088-.372.119-.477.196-.475.507-.471 1.254v.112C11.006 13.329 11 14 11 14h2v-1.272c0-.284.198-.345.623-.477.324-.1.779-.24 1.377-.551l.115-.059C15.841 11.271 17 10.682 17 8.5 17 6 15.025 4 12 4zM12 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});