define("ember-svg-jar/inlined/arrow-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.997 5a7 7 0 00-7 7v.589l1.296-1.296 1.414 1.414-3 3a1 1 0 01-1.414 0l-3-3 1.414-1.414 1.29 1.29V12a9 9 0 112.254 5.957l1.498-1.324A7 7 0 1011.997 5z\" fill=\"#7B61FF\"/><path d=\"M11 12V7h2v4.586l2.704 2.704-1.414 1.414-2.997-2.997A1 1 0 0111 12z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});