define("ember-svg-jar/inlined/exclamation-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 9h2v5.5h-2V9zM12 18a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.866 2.18a1 1 0 00-1.732 0l-10 17.32A1 1 0 002 21h20a1 1 0 00.866-1.5l-10-17.32zM12 4.68L20.268 19H3.732L12 4.68z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});