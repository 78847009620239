define("ember-svg-jar/inlined/dumbbell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.5 4A2.5 2.5 0 003 6.5v.55A2.5 2.5 0 000 9.5v5a2.5 2.5 0 003 2.45v.55a2.5 2.5 0 005 0V15h8v2.5a2.5 2.5 0 005 0v-.55a2.5 2.5 0 003-2.45v-5a2.5 2.5 0 00-3-2.45V6.5a2.5 2.5 0 00-5 0V9H8V6.5A2.5 2.5 0 005.5 4zM3 14.5v-5a.5.5 0 00-1 0v5a.5.5 0 001 0zm2 3a.5.5 0 001 0v-11a.5.5 0 00-1 0v11zm14 0a.5.5 0 01-1 0v-11a.5.5 0 011 0v11zm2.5-2.5a.5.5 0 01-.5-.5v-5a.5.5 0 011 0v5a.5.5 0 01-.5.5zM8 11v2h8v-2H8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});