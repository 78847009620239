define("ember-svg-jar/inlined/receipt-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.71 10.8L11 13.09l4.31-4.29 1.41 1.41-5 5c-.2.19-.45.29-.71.29-.26 0-.51-.09-.71-.29l-3-3 1.41-1.41z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 0h2a1 1 0 002 0h2a1 1 0 002 0h2a1 1 0 102 0h2a1 1 0 102 0h2v24h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0H7a1 1 0 10-2 0H3V0zm16 2.83v18.34c-.313-.11-.65-.17-1-.17-.768 0-1.47.289-2 .764A2.989 2.989 0 0014 21c-.768 0-1.47.289-2 .764A2.989 2.989 0 0010 21c-.768 0-1.47.289-2 .764A2.989 2.989 0 006 21c-.35 0-.687.06-1 .17V2.83c.313.11.65.17 1 .17.768 0 1.47-.289 2-.764.53.475 1.232.764 2 .764s1.47-.289 2-.764c.53.475 1.232.764 2 .764s1.47-.289 2-.764c.53.475 1.232.764 2 .764.35 0 .687-.06 1-.17z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});