define("ember-svg-jar/inlined/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 8c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V8zM5 5h14c1.66 0 3 1.34 3 3v8c0 1.66-1.34 3-3 3H5c-1.66 0-3-1.34-3-3V8c0-1.66 1.34-3 3-3zm0 9h14v2H5v-2zm14-3h-2v2h2v-2zm-5 0h2v2h-2v-2zm-1 0h-2v2h2v-2zm-5 0h2v2H8v-2zm-1 0H5v2h2v-2zm10-3h2v2h-2V8zm-1 0h-2v2h2V8zm-5 0h2v2h-2V8zm-1 0H8v2h2V8zM5 8h2v2H5V8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});