define("ember-svg-jar/inlined/emotion-happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 12a9 9 0 1118.001.001A9 9 0 013 12zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7zm4.24 1.11A2.98 2.98 0 0012 14.99c1.21 0 2.3-.73 2.76-1.88l1.86.76A4.987 4.987 0 0112 16.99c-2.03 0-3.84-1.22-4.62-3.12l1.86-.76zM14.5 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM11 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});