define("ember-svg-jar/inlined/sign-eur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.612 11a7.434 7.434 0 00.007 2H14v2H9.257c.847 1.573 2.383 2.496 4.217 2.496 1.192 0 2.209-.373 2.999-1.011l1.256 1.556c-1.162.938-2.628 1.455-4.255 1.455-3.052 0-5.4-1.806-6.4-4.496H5v-2h1.605a9.359 9.359 0 01-.007-2H5V9h2.065c1.015-2.716 3.43-4.5 6.409-4.5 1.611 0 3.086.53 4.258 1.483L16.47 7.535c-.807-.657-1.834-1.035-2.996-1.035-1.796 0-3.36.921-4.221 2.5H14v2H8.612z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});