define("ember-svg-jar/inlined/calendar-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17 5V3h-2v2H9V3H7v2C5.34 5 4 6.34 4 8v10c0 1.66 1.34 3 3 3h10c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3zm1 13c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v2zm0-4.82c-.31-.11-.65-.18-1-.18h-2c-1.66 0-3 1.34-3 3v2c0 .35.07.69.18 1H7c-.55 0-1-.45-1-1v-7h12v2.18zM6 9V8c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v1H6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});