define("ember-svg-jar/inlined/laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22 17V8c0-1.66-1.34-3-3-3H5C3.34 5 2 6.34 2 8v9H0v2h24v-2h-2zM4 17V8c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v9H4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});