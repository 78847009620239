define("ember-svg-jar/inlined/gift-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 7h-2.18c.11-.31.18-.65.18-1 0-1.65-1.35-3-3-3-.77 0-1.47.3-2 .78-.53-.48-1.23-.78-2-.78-1.65 0-3 1.35-3 3 0 .35.07.69.18 1H5c-1.66 0-3 1.34-3 3v8c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8c0-1.66-1.34-3-3-3zm1 3v3h-7v-2.59l1.29 1.29 1.41-1.41L14.41 9H19c.55 0 1 .45 1 1zm-6-5c.55 0 1 .45 1 1s-.45 1-1 1h-1V6c0-.55.45-1 1-1zM9 6c0-.55.45-1 1-1s1 .45 1 1v1h-1c-.55 0-1-.45-1-1zM5 9h4.59L8.3 10.29l1.41 1.41L11 10.41V13H4v-3c0-.55.45-1 1-1zm0 10c-.55 0-1-.45-1-1v-3h7v4H5zm14 0h-6v-4h7v3c0 .55-.45 1-1 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});