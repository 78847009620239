define("ember-svg-jar/inlined/four-pointed-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 2a1 1 0 01.894.553l2.851 5.702 5.702 2.85a1 1 0 010 1.79l-5.702 2.85-2.85 5.702a1 1 0 01-1.79 0l-2.85-5.702-5.702-2.85a1 1 0 010-1.79l5.702-2.85 2.85-5.702A1 1 0 0112 2zm0 3.236L9.894 9.447a1 1 0 01-.447.447L5.237 12l4.21 2.106a1 1 0 01.447.447L12 18.763l2.106-4.21a1 1 0 01.447-.447L18.763 12l-4.21-2.106a1 1 0 01-.447-.447L12 5.237z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});