define("ember-svg-jar/inlined/audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.76 20l-1.48-1.45c3.53-3.61 3.53-9.48 0-13.09L18.76 4c4.32 4.41 4.32 11.59 0 16zm-3.16-3.23l-1.48-1.45c1.79-1.83 1.79-4.8 0-6.63l1.48-1.45c2.57 2.62 2.57 6.9 0 9.53zM9 15.17V8.83l-1.58 1.58-.59.59H4v2h2.83l.58.59L9 15.17zM6 9l5-5v16l-5-5H2V9h4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});