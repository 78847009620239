define("ember-svg-jar/inlined/cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.404 1c-2.029 0-3.978 1.33-4.676 3.313-1.3-.42-2.7-.003-3.613.864a3.526 3.526 0 00-1.114 2.478c-.01.446.061.897.219 1.345H4v6a7.98 7.98 0 002.708 6H3v2h18v-2h-3.708a8.017 8.017 0 002.127-3H21a3 3 0 003-3v-3a3 3 0 00-3-3h-2.27c.42-1.137.343-2.28-.167-3.175-.38-.665-1.008-1.181-1.805-1.34a2.737 2.737 0 00-1.532.145 1.54 1.54 0 01-.181-.577c-.046-.409.04-.94.306-1.734L15.795 1h-1.391zm2.084 8c.662-.947.57-1.776.338-2.183-.133-.232-.305-.34-.46-.372-.15-.03-.42-.016-.802.267l-.69.513-.61-.605c-.696-.69-1.106-1.45-1.207-2.344-.034-.31-.03-.624.004-.94-.923.484-1.603 1.442-1.603 2.574v2.398L9.754 6.62c-.68-.673-1.64-.583-2.262.007A1.527 1.527 0 007 7.705c-.008.34.096.782.455 1.295h9.032zM6 11v4a6 6 0 0012 0v-4H6zm14 0v5h1a1 1 0 001-1v-3a1 1 0 00-1-1h-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});