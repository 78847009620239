define("ember-svg-jar/inlined/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_45548_153652)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.874 2.137l.119.699c.847 4.97.183 8.452-2.082 11.114-.408.48-.864.928-1.365 1.349l.718 3.59-5.116 5.116-1.522-5.33c-.93.395-1.915.779-2.948 1.164l-.602.225-5.13-5.13.225-.602c.386-1.032.77-2.016 1.164-2.947L.006 9.863l5.116-5.116 3.59.718c.421-.5.869-.957 1.348-1.365 2.663-2.265 6.144-2.929 11.115-2.082l.699.119zM7.426 7.247l-1.647-.329-1.955 1.955 2.344.67c.399-.82.814-1.587 1.258-2.296zm7.042 10.596c.82-.4 1.587-.815 2.296-1.258l.329 1.646-1.955 1.956-.67-2.345zM14.303 4.05a7.74 7.74 0 00-2.947 1.573C9.321 7.355 7.863 10.25 6.271 14.43l3.308 3.308c4.181-1.591 7.077-3.049 8.808-5.084a7.739 7.739 0 001.573-2.948l-.014.014-5.657-5.657.014-.013zm6.031 3.23c.045-1.005-.02-2.131-.202-3.403-1.271-.182-2.397-.246-3.402-.201l3.604 3.604zM1.996 22.015l1.414-4.789 3.375 3.375-4.79 1.414zM14.289 9.721c-.251-.251-1.026-.387-1.767.354-.742.741-.605 1.516-.354 1.768.252.251 1.027.387 1.768-.354.741-.741.605-1.516.353-1.768zm-3.182-1.06c1.212-1.212 3.286-1.665 4.597-.354 1.31 1.31.857 3.385-.354 4.596-1.211 1.212-3.285 1.665-4.596.354-1.31-1.31-.858-3.385.353-4.596z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_45548_153652\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});