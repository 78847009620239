define("ember-svg-jar/inlined/bold-b", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.43 21V2.975h7.518c1.768 0 3.116.403 4.043 1.21.944.807 1.416 1.906 1.416 3.296 0 .67-.12 1.253-.36 1.751-.24.48-.541.884-.902 1.21-.36.31-.738.558-1.133.747v.103a4.46 4.46 0 011.468.902 4.13 4.13 0 011.056 1.493c.275.6.412 1.313.412 2.137 0 .996-.249 1.889-.747 2.678-.498.773-1.193 1.382-2.086 1.829-.875.446-1.905.669-3.09.669H5.43zm3.681-3.09h3.734c.807 0 1.468-.206 1.983-.618.515-.43.772-1.056.772-1.88 0-.84-.257-1.468-.772-1.88-.515-.412-1.176-.617-1.983-.617H9.111v4.995zm0-7.957h3.76c.704 0 1.262-.154 1.674-.463.412-.31.618-.799.618-1.468 0-.652-.206-1.133-.618-1.442-.412-.309-.97-.464-1.674-.464H9.11v3.837z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});