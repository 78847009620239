define("ember-svg-jar/inlined/number-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.043 8.488V6.27h-.05a.648.648 0 01-.293.311 1.164 1.164 0 01-.504.094h-.2v-.978h.33c.229 0 .403-.063.524-.187.12-.125.184-.295.193-.511h.997v3.489h.965v.872H1.996v-.872h1.047zM2.02 19v-.854l1.532-1.318c.105-.089.19-.177.256-.262a.832.832 0 00.201-.549.472.472 0 00-.14-.348.507.507 0 00-.384-.146c-.171 0-.303.049-.397.146a.628.628 0 00-.152.39h-.958c.004-.251.063-.481.177-.689.118-.21.287-.38.506-.506.22-.13.49-.195.811-.195.326 0 .6.053.824.159.228.101.398.25.512.445a1.3 1.3 0 01.177.695c0 .192-.026.362-.08.513a1.447 1.447 0 01-.25.427 4.565 4.565 0 01-.444.445l-.879.769v.024h1.672V19H2.02zM7 7.5h15v-2H7v2zM22 13H7v-2h15v2zM7 18.5h15v-2H7v2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});