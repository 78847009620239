define("ember-svg-jar/inlined/paypay-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><path d=\"M13.422 19.5h-.704a2.226 2.226 0 01-2.218-2.219V9.197a27.987 27.987 0 015.477-.04L13.422 19.5zm9.026-6.993c.346-1.433-1.965-2.758-5.662-3.26l-1.343 5.588c3.102.147 6.624-.786 7.003-2.328h.002zm.834-8.007H12.718a2.225 2.225 0 00-2.212 2.1c8.849-.25 13.152 2.827 12.478 5.89-.61 2.762-3.417 3.821-7.972 4.169l-.683 2.836h8.953a2.226 2.226 0 002.218-2.217V6.718A2.224 2.224 0 0023.282 4.5z\" fill=\"#F03\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});