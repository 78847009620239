define("ember-svg-jar/inlined/ebt-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#295997\"/><path d=\"M5.295 7.5h7.453v1.922H8.082v1.43h4.328v1.836H8.082v1.774h4.8V16.5H5.296v-9zM13.674 7.5h5.206c.868 0 1.533.215 1.996.645.466.43.7.961.7 1.596 0 .532-.166.988-.498 1.369-.22.254-.544.454-.97.601.647.156 1.122.424 1.425.805.306.376.46.851.46 1.424 0 .466-.108.886-.325 1.258a2.396 2.396 0 01-.89.884c-.234.136-.586.234-1.056.295-.627.082-1.042.123-1.247.123h-4.8v-9zm2.806 3.53h1.21c.433 0 .734-.074.902-.221.172-.151.258-.368.258-.65 0-.263-.086-.467-.258-.615-.168-.147-.463-.22-.884-.22H16.48v1.706zm0 3.536h1.418c.479 0 .817-.084 1.013-.252a.868.868 0 00.295-.687.78.78 0 00-.295-.639c-.192-.163-.532-.245-1.019-.245H16.48v1.823zM22.252 7.5h8.453v2.222H27.87V16.5h-2.78V9.722h-2.837V7.5z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});