define("ember-svg-jar/inlined/money-bag-sign-eur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.08 14.009c0-.125.004-.246.015-.364h2.948v-2h-2.018A2.561 2.561 0 0112.752 11c.635 0 1.186.206 1.616.556l1.264-1.55A4.518 4.518 0 0012.752 9c-1.874 0-3.405 1.045-4.162 2.646H7.5v2h.59a6.06 6.06 0 00.002.77H7.5v2h1.094c.743 1.587 2.238 2.646 4.158 2.646 1.092 0 2.086-.348 2.877-.987L14.37 16.52c-.418.338-.963.542-1.619.542-.667 0-1.27-.226-1.733-.645h2.024v-2h-2.945a4.392 4.392 0 01-.019-.408z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.94 7H18V5h-2.72l1-4H7.72l1.13 4.53c-4.06 2.7-6.43 7.96-5.34 12.08C4.42 21.04 7.52 23 12 23s7.58-1.96 8.48-5.38c.92-3.47-.61-7.74-3.54-10.62zm-3.22-4l-.5 2h-2.44l-.5-2h3.44zm4.83 14.1C17.87 19.65 15.61 21 12 21s-5.87-1.35-6.55-3.9c-.9-3.39 1.31-7.95 4.84-10.1h3.43c3.52 2.15 5.73 6.71 4.83 10.1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});