define("ember-svg-jar/inlined/fork-knife", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 2h2v4.5a.5.5 0 001 0V2h2v4c0 3.176-.796 5.354-1.643 6.764a7.963 7.963 0 01-1.181 1.537c-.062.062-.121.118-.176.169V22H7v-7.53a6.173 6.173 0 01-.176-.17 7.962 7.962 0 01-1.181-1.536C4.796 11.354 4 9.177 4 6V2h2v4.5a.5.5 0 001 0V2zm.357 9.736c.227.377.451.672.643.894.192-.222.416-.517.643-.895.385-.643.789-1.54 1.054-2.749-.064.01-.13.014-.197.014-.416 0-.773-.188-1-.34a3.196 3.196 0 01-.5-.418c-.156.16-.325.301-.5.418-.227.152-.583.34-1 .34-.067 0-.133-.005-.197-.014.265 1.208.669 2.106 1.054 2.75zM19 22V2h-1.439a3 3 0 00-2.91 2.272L13.305 9.66a3 3 0 00.789 2.849L17 15.414V22h2zM16.591 4.757A.998.998 0 0117 4.173v8.413l-1.492-1.492a1 1 0 01-.263-.95l1.346-5.387z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});