define("ember-svg-jar/inlined/house-account-sign-jpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.211 13l-1.424-2.75h1.69L11.9 13h.199l1.425-2.75h1.69L13.787 13h.794v1.5h-1.571l-.26.5h1.832v1.5h-1.834v1.25h-1.5V16.5H9.418V15h1.829l-.26-.5h-1.57V13h.794z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 2a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V9a3.001 3.001 0 00-2-2.83V2H8zm7 2H8a1 1 0 000 2h7V4zm1 4H7v11a1 1 0 001 1h8a1 1 0 001-1V9a1 1 0 00-1-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});