define("ember-svg-jar/inlined/thumbs-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.42 2a3.013 3.013 0 00-2.836 1.991l-.604 1.69a5 5 0 01-3.983 3.266A1.03 1.03 0 005.97 8H3.03A1.03 1.03 0 002 9.03v11.94c0 .569.461 1.03 1.03 1.03h2.94A1.03 1.03 0 007 20.97v-.14A9 9 0 0011.438 22h3.743a6 6 0 005.789-4.421l1.306-4.79A3 3 0 0019.38 9h-2.85l.818-3.272C17.826 3.82 16.358 2 14.419 2zM5 10H4v10h1V10zm8.468-5.336c.14-.393.523-.664.952-.664.664 0 1.143.624.989 1.243l-1.13 4.514A1 1 0 0015.25 11h4.131a1 1 0 01.965 1.263l-1.306 4.79A4 4 0 0115.181 20h-3.743a7 7 0 01-3.892-1.182L7 18.453v-7.49a7 7 0 005.864-4.609l.604-1.69z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});