define("ember-svg-jar/inlined/diners-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><path d=\"M16.889 19.498c-4.15.02-7.566-3.342-7.566-7.434 0-4.471 3.416-7.566 7.566-7.564h1.945c4.1 0 7.843 3.09 7.843 7.564 0 4.09-3.743 7.434-7.843 7.434h-1.945zm.017-14.384a6.866 6.866 0 106.866 6.872 6.866 6.866 0 00-6.86-6.866m-1.566 10.926V7.925a4.35 4.35 0 000 8.121zm5.912-4.06a4.356 4.356 0 00-2.796-4.063v8.125a4.355 4.355 0 002.796-4.062z\" fill=\"#0D4E96\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});