define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 3h-2v2H9V3H7v2C5.34 5 4 6.34 4 8v10c0 1.66 1.34 3 3 3h10c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3V3zm1 5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v1h12V8zM6 11v7c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-7H6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});