define("ember-svg-jar/inlined/receipt-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_152387_243609)\" fill=\"#7B61FF\"><path d=\"M3 0h2a1 1 0 002 0h2a1 1 0 002 0h2a1 1 0 102 0h2a1 1 0 102 0h2v13h-2V2.83c-.313.11-.65.17-1 .17-.768 0-1.47-.289-2-.764A2.989 2.989 0 0114 3c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0110 3c-.768 0-1.47-.289-2-.764A2.989 2.989 0 016 3c-.35 0-.687-.06-1-.17v18.34c.313-.11.65-.17 1-.17.768 0 1.47.289 2 .764A2.989 2.989 0 0110 21c.768 0 1.47.289 2 .764A3 3 0 0117 24h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0H7a1 1 0 10-2 0H3V0z\"/><path d=\"M17 11H7V9h10v2zM20.589 20l-1.793 1.793 1.414 1.414 3.5-3.5a1 1 0 000-1.414l-3.5-3.5-1.414 1.414L20.589 18H16v2h4.589zM7 15h10v-2H7v2z\"/></g><defs><clipPath id=\"clip0_152387_243609\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});