define("ember-svg-jar/inlined/square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.992 3H6.008A3.007 3.007 0 003 6.008V17.99A3.009 3.009 0 006.008 21h11.984A3.009 3.009 0 0021 17.991V6.008A3.007 3.007 0 0017.992 3zm-.265 13.778a.95.95 0 01-.949.95H7.223a.95.95 0 01-.949-.95V7.223a.949.949 0 01.95-.95h9.554a.95.95 0 01.949.95v9.555z\" fill=\"#7B61FF\"/><path d=\"M10.088 14.442a.545.545 0 01-.545-.547v-3.814a.544.544 0 01.545-.548h3.825a.545.545 0 01.544.548v3.814a.545.545 0 01-.545.547h-3.825z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});