define("ember-svg-jar/inlined/human-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 12.086l3.793-3.793 1.414 1.414-4.5 4.5a1 1 0 01-1.414 0l-2.5-2.5 1.414-1.414L17 12.086zM9 5a2 2 0 100 4 2 2 0 000-4zM5 7a4 4 0 118 0 4 4 0 01-8 0zM9 15a6 6 0 00-6 6H1a8 8 0 1116 0h-2a6 6 0 00-6-6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});