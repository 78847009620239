define("ember-svg-jar/inlined/martini-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.406 1.844L16.68 4h4.438L13 13.86V21h4v2H7v-2h4v-7.141L2.881 4H14.12L16.844.594l1.562 1.25zM12.519 6h-5.4l.824 1h3.776l.8-1zm1.762 1h1.777l.823-1h-1.8l-.8 1zm.13 2H9.59L12 11.927 14.41 9z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});