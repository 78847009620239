define("ember-svg-jar/inlined/magnifying-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.5 17.5c1.57 0 3.02-.53 4.18-1.4l4.11 4.11 1.41-1.41-4.11-4.11c.88-1.17 1.4-2.61 1.4-4.18 0-3.86-3.14-7-7-7s-7 3.14-7 7 3.15 6.99 7.01 6.99zm0-12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});