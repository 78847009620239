define("ember-svg-jar/inlined/square-stand-mount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.285 1.728L9.35 1.104 4.984 21.645l2.935.624a3 3 0 003.558-2.31l.31-1.459H15a3 3 0 003-3V10a3 3 0 00-3-3h-.769l.364-1.714a3 3 0 00-2.31-3.558zM7.356 20.105l3.535-16.629.978.208a1 1 0 01.77 1.186L9.52 19.542a1 1 0 01-1.186.77l-.978-.207zm4.877-3.605L13.812 9H15a1 1 0 011 1v5.5a1 1 0 01-1 1h-2.768z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});