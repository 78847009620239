define("ember-svg-jar/inlined/shopping-cart-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.586 6l-1.793 1.793 1.414 1.414 3.5-3.5a1 1 0 000-1.414l-3.5-3.5-1.414 1.414L18.586 4h-4.589v2h4.589zM7 14c-.55 0-1 .45-1 1s.45 1 1 1h13v2H7c-1.65 0-3-1.35-3-3 0-1.17.68-2.18 1.67-2.67L4 4H2V2h6v2h4v2H6.44l1.2 6h8.72l.2-1h2.04l-.6 3H7zM7.5 22a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM18 20.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});