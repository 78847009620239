define("ember-svg-jar/inlined/dual-rotating-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.77 17.01c.04-.04.07-.09.11-.13l1.31-1.31A5.917 5.917 0 016 12c0-3.31 2.69-6 6-6h1.59L12.3 7.29l1.41 1.41 3-3a.996.996 0 000-1.41l-3-3L12.3 2.7 13.59 4H12c-4.41 0-8 3.59-8 8 0 1.85.62 3.6 1.77 5.01zM18 12c0 3.31-2.69 6-6 6h-1.59l1.29-1.29-1.41-1.41-3 3a.996.996 0 000 1.41l3 3 1.41-1.41-1.29-1.3H12c4.41 0 8-3.59 8-8 0-1.85-.62-3.6-1.77-5.01-.04.04-.07.09-.11.13l-1.31 1.31A5.917 5.917 0 0118 12z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});