define("ember-svg-jar/inlined/deposit-sign-eur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.555 9.025a4.096 4.096 0 00-.528.532l2.061 2.062-1.414 1.414-1.406-1.406c.036.61.299 1.182.76 1.644.459.459.983.697 1.513.753l-.211 1.989c-1.006-.107-1.949-.56-2.716-1.328-1.349-1.349-1.655-3.142-1.065-4.777l-.764-.764L8.2 7.73l.41.41a6.199 6.199 0 01.529-.533L8.73 7.2l1.414-1.414.761.762c1.655-.591 3.464-.25 4.78 1.067a4.49 4.49 0 011.317 2.73l-1.99.204a2.492 2.492 0 00-.741-1.52 2.535 2.535 0 00-1.64-.755l1.402 1.401-1.414 1.414-2.064-2.063z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.468 14.055A9 9 0 103.513 14H0v8h24v-8h-3.512l-.02.055zM15.608 17a7 7 0 10-7.217 0h7.218zM3 17h2.291a9.012 9.012 0 01-.804-1.04l.028.04H2v4h20v-4h-2.515a9.008 9.008 0 01-.776 1H21v2H3v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});