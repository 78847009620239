define("ember-svg-jar/inlined/wi-fi-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.593 21.007l-16.6-16.6 1.414-1.414 3.471 3.471A14.045 14.045 0 0112 5.85c3.74 0 7.26 1.46 9.9 4.1l-1.41 1.41A11.953 11.953 0 0012 7.85c-.844 0-1.674.087-2.48.255l1.774 1.775a9.953 9.953 0 017.766 2.9l-1.41 1.41a7.956 7.956 0 00-4.265-2.22l7.622 7.623-1.414 1.414zM4.067 8.31A14.137 14.137 0 002.1 9.95l1.41 1.41a12.178 12.178 0 011.998-1.61l-1.44-1.44zM6.967 11.21a9.993 9.993 0 00-2.037 1.57l1.41 1.41a7.975 7.975 0 012.098-1.51l-1.471-1.47zM9.96 14.203a5.916 5.916 0 00-2.2 1.407l1.41 1.41c.672-.672 1.568-1.045 2.489-1.119L9.96 14.203zM12 19.85l-1.41-1.41c.78-.78 2.04-.78 2.82 0L12 19.85z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});