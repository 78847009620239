define("ember-svg-jar/inlined/receipt-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 10.598a2 2 0 100-4 2 2 0 000 4zM17 15.4a2 2 0 11-4 0 2 2 0 014 0zM7 17.4l7.56-10.802H17L9.44 17.4H7z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 0h2a1 1 0 002 0h2a1 1 0 002 0h2a1 1 0 102 0h2a1 1 0 102 0h2v24h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0H7a1 1 0 10-2 0H3V0zm16 2.83v18.34c-.313-.11-.65-.17-1-.17-.768 0-1.47.289-2 .764A2.989 2.989 0 0014 21c-.768 0-1.47.289-2 .764A2.989 2.989 0 0010 21c-.768 0-1.47.289-2 .764A2.989 2.989 0 006 21c-.35 0-.687.06-1 .17V2.83c.313.11.65.17 1 .17.768 0 1.47-.289 2-.764.53.475 1.232.764 2 .764s1.47-.289 2-.764c.53.475 1.232.764 2 .764s1.47-.289 2-.764c.53.475 1.232.764 2 .764.35 0 .687-.06 1-.17z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});