define("ember-svg-jar/inlined/american-express-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><path d=\"M22.825 8.18l.48-1.305H25.5V4.5h-15v15h15v-2.37h-2.07l-.765-.895-.795.895h-5.88v-4.765h-1.94l2.425-5.49h2.36l.57 1.245V6.875h2.93l.49 1.305zm-1.655.865l-.005-.525.2.525.975 2.605h.97l.98-2.605.19-.52v3.125h1.02v-4h-1.695l-.77 2.03-.205.55-.21-.55-.775-2.03H20.15v4h1.02V9.045zm-2.195 2.605h1.175l-1.76-4h-1.365l-1.77 4h1.16l.31-.77h1.94l.31.77zm-1.48-2.645l.2-.5.2.5.415 1.01h-1.23l.415-1.01zm-.685 3.365v3.985h3.34v-.865h-2.32v-.695h2.275v-.86H17.83v-.7h2.32v-.865h-3.34zm7.055 3.985h1.325l-1.87-2 1.87-1.985h-1.305l-1.205 1.295-1.2-1.295h-1.33l1.865 2-1.865 1.985h1.29l1.215-1.3 1.21 1.3zm.505-2.005l1.13 1.15v-2.285l-1.13 1.135z\" fill=\"#006FCF\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});