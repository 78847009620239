define("ember-svg-jar/inlined/card-swipe-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.256 8.54l5.125-5.126L21.967 2l-4.45 4.45-2.168-1.981L14 5.946l2.875 2.625a1 1 0 001.381-.032z\" fill=\"#7B61FF\"/><path d=\"M4 8a1 1 0 011-1h7V5H5a3 3 0 00-3 3v8a3 3 0 003 3h13a3 3 0 003-3v-5h-2v5a1 1 0 01-1 1H5a1 1 0 01-1-1v-5h11V9H4V8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});