define("ember-svg-jar/inlined/indicator-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_94354_207812)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12zm2.667 0c0 5.147 4.186 9.333 9.333 9.333s9.333-4.186 9.333-9.333S17.147 2.667 12 2.667 2.667 6.853 2.667 12zm4.946-1.6l3.054 3.053 5.746-5.72 1.88 1.88-6.666 6.667c-.267.253-.6.387-.947.387s-.68-.12-.947-.387l-4-4 1.88-1.88z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_94354_207812\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});