define("ember-svg-jar/inlined/palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.5 10.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM13.75 9a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM11.5 7.75a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM7.75 11.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.997 12a9 9 0 1117.985.528c-.09 1.56-1.475 2.472-2.787 2.472h-2.198a1 1 0 00-1 1v2.198c0 1.312-.911 2.696-2.472 2.787A9 9 0 012.997 12zm9-7a7 7 0 10.413 13.988c.261-.015.587-.278.587-.79V16a3 3 0 013-3h2.198c.512 0 .775-.326.79-.587A7 7 0 0011.997 5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});