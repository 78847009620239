define("ember-svg-jar/inlined/shopping-cart-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 15c0-.55.45-1 1-1h11l.8-4h-2.04l-.4 2H7.64l-1.2-6H11V4H8V2H2v2h2l1.67 8.33A2.98 2.98 0 004 15c0 1.65 1.35 3 3 3h13v-2H7c-.55 0-1-.45-1-1zM9 20.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM18 20.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#7B61FF\"/><path d=\"M16.28 6.29L13.99 4l-1.41 1.41 3 3c.2.2.45.29.71.29.26 0 .51-.1.71-.29l5-5L20.59 2l-4.31 4.29z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});