define("ember-svg-jar/inlined/card-swipe-exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 7a1 1 0 00-1 1v1h13v2H4v5a1 1 0 001 1h13a1 1 0 001-1v-3h2v3a3 3 0 01-3 3H5a3 3 0 01-3-3V8a3 3 0 013-3h12v2H5z\" fill=\"#7B61FF\"/><path d=\"M21.137 10a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.007 2v5.875h-2V2h2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});