define("ember-svg-jar/inlined/lock-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 9h7c1.66 0 3 1.34 3 3v7c0 1.66-1.34 3-3 3H8c-1.66 0-3-1.34-3-3v-7c0-1.3.84-2.4 2-2.82V7c0-2.76 2.24-5 5-5 1.38 0 2.63.56 3.53 1.47l-1.41 1.41C13.58 4.34 12.83 4 12 4c-1.65 0-3 1.35-3 3v2zm7 11c.55 0 1-.45 1-1v-7c0-.55-.45-1-1-1H8c-.55 0-1 .45-1 1v7c0 .55.45 1 1 1h8zm-2.5-4.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});