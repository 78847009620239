define("ember-svg-jar/inlined/chain-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_45548_153756)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 8H3c-1.66 0-3 1.34-3 3v2c0 1.66 1.34 3 3 3h5c1.31 0 2.41-.84 2.82-2H3c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h7.81C10.4 8.84 9.3 8 8 8zm13 0h-5c-1.3 0-2.4.84-2.81 2H21c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1H13.18c.41 1.16 1.51 2 2.82 2h5c1.66 0 3-1.34 3-3v-2c0-1.66-1.34-3-3-3zM7 11h10c.55 0 1 .44 1 1 0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_45548_153756\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});