define("ember-svg-jar/inlined/chart-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 5v2h-4v4H9V9H3v10h18V5h-6zM7 17H5v-6h2v6zm4 0H9v-4h2v4zm4 0h-2V9h2v8zm4 0h-2V7h2v10z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});