define("ember-svg-jar/inlined/card-contactless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.24 7.76l-1.41 1.41a4.004 4.004 0 010 5.66l1.41 1.41A5.928 5.928 0 0016 12c0-1.6-.62-3.11-1.76-4.24zM5 5H0v2h5c1.1 0 2 .9 2 2H0v2h7v4c0 1.1-.9 2-2 2H0v2h5c2.21 0 4-1.79 4-4V9c0-2.21-1.79-4-4-4z\" fill=\"#7B61FF\"/><path d=\"M17.07 4.93l-1.41 1.41A7.949 7.949 0 0118 12c0 2.14-.83 4.15-2.34 5.66l1.41 1.41A9.932 9.932 0 0020 12c0-2.67-1.04-5.18-2.93-7.07z\" fill=\"#7B61FF\"/><path d=\"M19.9 2.1l-1.41 1.41c4.68 4.68 4.68 12.29 0 16.97l1.41 1.41c5.46-5.45 5.46-14.33 0-19.79z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});