define("@square/environment-utils/instance-initializers/environment", ["exports", "@square/environment-utils/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // This initializer automatically instantiates the environment object and
  // registers it in the container.
  function initialize(instance) {
    if (!instance.lookup('environment:current')) {
      const environmentInstance = (0, _environment.default)();
      instance.registerOptionsForType('environment', {
        instantiate: false,
        singleton: true
      });
      instance.register('environment:current', environmentInstance);
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});