define("ember-svg-jar/inlined/arrow-up-letters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.704 3.293a1 1 0 00-1.414 0l-4 4 1.414 1.414 2.293-2.293V20h2V6.414l2.293 2.293 1.414-1.414-4-4z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.997 9.5V11h2V6.999a2.999 2.999 0 00-5.997 0V11h2V9.5h1.997zM17 6a.999.999 0 00-.999.999V7.5h1.997v-.501A.999.999 0 0017 6z\" fill=\"#7B61FF\"/><path d=\"M14 15h3.117L14 17.997V20h6v-2h-3.117L20 15.003V13h-6v2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});