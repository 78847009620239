define("ember-svg-jar/inlined/cake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.997 7.703c1.14-.388 2-1.42 2-2.71 0-1.316-.704-2.277-1.304-2.859a5.721 5.721 0 00-1.204-.887l-.028-.015-.01-.005-.004-.002h-.001L12 1l-.452.225h-.001l-.004.002-.01.005-.028.015a5.137 5.137 0 00-.37.22c-.225.15-.528.371-.834.667-.6.582-1.304 1.543-1.304 2.86 0 1.29.861 2.321 2 2.709V9h-4a3 3 0 00-3 3v9H2v2h20v-2h-2.003v-7.868A3.02 3.02 0 0020 13h-.003v-1a3 3 0 00-3-3h-4V7.703zm-1-4.395a3.593 3.593 0 00-.304.263c-.4.387-.696.863-.696 1.422 0 .419.377.865.979.875h.042c.602-.01.98-.456.98-.875 0-.559-.297-1.035-.697-1.422a3.607 3.607 0 00-.304-.263zm6 17.692v-5.232A3.48 3.48 0 0116.75 16c-.854 0-1.68-.319-2.284-.864-.669.556-1.577.864-2.466.864-.889 0-1.797-.309-2.466-.864A3.436 3.436 0 017.25 16a3.48 3.48 0 01-1.253-.234V21h12zm0-9v1.063c-.04.433-.48.937-1.247.937-.804 0-1.25-.555-1.25-1h-2c0 .356-.452 1-1.5 1s-1.5-.644-1.5-1h-2c0 .445-.446 1-1.25 1S6 13.445 6 13h-.003v-1a1 1 0 011-1h10a1 1 0 011 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});