define("ember-svg-jar/inlined/phone-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.279 1a5.767 5.767 0 00-5.148 8.37l-1.13 3.676 3.675-1.131A5.767 5.767 0 1017.279 1zm-3.767 5.767a3.767 3.767 0 111.8 3.214l-.385-.236-.904.278.278-.904-.236-.385a3.746 3.746 0 01-.553-1.967z\" fill=\"#7B61FF\"/><path d=\"M9 5a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2v-4.5h2V19a4 4 0 01-4 4H9a4 4 0 01-4-4V7a4 4 0 014-4h1v2H9z\" fill=\"#7B61FF\"/><path d=\"M13.5 17.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});