define("ember-svg-jar/inlined/image-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 9a2 2 0 11-4 0 2 2 0 014 0z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 19v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 6a3 3 0 013-3h12a3 3 0 013 3v8h-3.414L16 12.414l-5.5 5.5-2.5-2.5-2.937 2.937A1 1 0 006 19h8v2H6a3 3 0 01-3-3V6zm16 0v6.586l-3-3-5.5 5.5-2.5-2.5-3 3V6a1 1 0 011-1h12a1 1 0 011 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});