define("ember-svg-jar/inlined/coin-sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.7 18.37c-3.51-3.51-3.51-9.22 0-12.73s9.22-3.51 12.72 0c3.51 3.51 3.51 9.22 0 12.73A8.98 8.98 0 0112.06 21c-2.3 0-4.61-.88-6.36-2.63zM7.11 7.05a7.007 7.007 0 000 9.9 7.007 7.007 0 009.9 0 7.007 7.007 0 000-9.9A6.973 6.973 0 0012.06 5c-1.79 0-3.58.69-4.95 2.05zm3.95 8.93v1.23h1.81V16c1.13-.31 1.89-1.24 1.89-2.33 0-1.626-1.307-2.15-2.274-2.54l-.026-.01c-.54-.21-1.05-.42-1.05-.71 0-.51.82-.52.85-.52.38 0 .81.16 1.07.4l.23.21 1.06-1.51-.22-.16c-.49-.35-.99-.57-1.53-.67V7h-1.79v1.26c-1 .35-1.62 1.17-1.62 2.14 0 .81.39 1.44 1.16 1.89.283.164.59.287.907.413l.093.037c.81.32 1.19.51 1.19.93 0 .38-.33.64-.79.64-.54 0-1.11-.27-1.51-.71l-.2-.22L9 14.75l.2.19c.55.51 1.19.87 1.86 1.04z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});