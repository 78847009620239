define("@square/sq-ember-eventstream/services/eventstream", ["exports", "@ember/service", "@ember/string"], function (_exports, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function underscoreKeys(oldObj) {
    return Object.keys(oldObj).reduce((obj, key) => {
      obj[(0, _string.underscore)(key)] = oldObj[key];
      return obj;
    }, {});
  }

  var _default = _service.default.extend({
    // An instance of the EventstreamClient.
    //
    // This is set by instance-initializers/eventstream.js at application
    // initialization
    client: null,

    /**
     * Tracks an individual page click event and automatically populates a number
     * of browser specific default fields. This utilizes the
     * `#trackWithDefaults` method under the hood.
     *
     * @param {object} opts - A mapping of keys and string values to be passed to
     *                        eventstream. Keys will be lowercased and underscored.
     *
     * The following are some common expected values for opts keys:
     * @param {string} opts.element_identifier - The element ID. This does not
     * necessarily have to correspond directly to the element and should be
     * defined with analytics in mind.
     * @param {string} opts.element_parent_identifier - The element's parent's ID.
     * This does not always need to be set. Currently, we do not use this
     * parameter when tracking.
     * @param {string} opts.element_text - The text accessed via `#innerText` of
     * the element
     * @param {string} opts.element_title - The title attribute (e.g. `element.title`
     * of the element
     * @param {string} opts.element_type - The type (e.g. div, button) of the element
     *
     * Reference:
     * https://schema-manager.sqprod.co/schema-manager/production/catalog/page_click
     */
    trackPageClick() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.trackWithDefaults('page_click', Object.assign({}, underscoreKeys(opts)), ...args);
    },

    /**
     * Tracks an individual page view event and automatically populates a number
     * of browser specific default fields. This utilizes the
     * `#trackWithDefaults` method under the hood.
     *
     * @param {String} path - The URL path of the target page.  We need to pass
     * this in as a parameter because this will often be called by Ember before
     * the actual browser location is updated.
     * @param {object} opts - A mapping of keys and string values to be passed to
     *                        eventstream. Keys will be lowercased and underscored.
     *
     * Reference:
     * https://schema-manager.sqprod.co/schema-manager/production/catalog/edit/custom/page_view
     */
    trackPageView(path) {
      let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }

      this.trackWithDefaults('page_view', Object.assign({
        // These override the defaults set in _defaultBrowserParams
        // because the location will not have updated by the time
        // this method is called by the Router.
        webpage_full_url: `${location.origin}/${path}`,
        webpage_path: path
      }, underscoreKeys(opts)), ...args);
    },

    // Makes a request to Eventstream to track an individual event.
    // Note this uses Eventstream 2.
    //
    // @param {String} catalogName - The desired catalog name for the event to
    // be tracked.  This must be set.
    // @param {Object} metadata - The associated schema fields for a given catalog.
    // See go/schema-manager for more info.
    //
    // References:
    // https://git.sqcorp.co/projects/JS/repos/eventstream/browse
    // https://wiki.sqcorp.co/display/DI/How+to+use+Eventstream+2
    track(catalogName, metadata) {
      const event = Object.assign({
        catalog_name: catalogName
      }, metadata);
      this.debug(event);

      for (var _len3 = arguments.length, args = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
        args[_key3 - 2] = arguments[_key3];
      }

      this.get('client').trackV2(event, ...args);
    },

    // Makes a request to Eventstream to track an individual event. Browser
    // specific fields are automatically populated.
    // Note this uses Eventstream 2.
    //
    // @param {String} catalogName - The desired catalog name for the event to
    // be tracked.  This must be set.
    // @param {Object} metadata - The associated schema fields for a given catalog.
    // See go/schema-manager for more info.
    trackWithDefaults(catalogName, metadata) {
      const event = Object.assign({
        catalog_name: catalogName
      }, metadata);
      this.debug(event);

      for (var _len4 = arguments.length, args = new Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
        args[_key4 - 2] = arguments[_key4];
      }

      this.get('client').trackV2WithDefaults(event, ...args);
    },

    debug(event) {
      // eslint-disable-next-line no-console
      console.debug('ES2 Event', event);
    }

  });

  _exports.default = _default;
});