define("ember-svg-jar/inlined/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.293 16.707l-5-5 1.414-1.414 5 5-1.414 1.414zM16 10a2 2 0 100-4 2 2 0 000 4z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.586 2H19a3 3 0 013 3v7.413L12 22.46 1.588 11.998 11.586 2zm.829 2l-8.002 8.002L12 19.625l8-8.038V5a1 1 0 00-1-1h-6.585z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});