define("ember-svg-jar/inlined/cash-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.8 3h14.4A1.8 1.8 0 0121 4.8v14.4a1.8 1.8 0 01-1.8 1.8H4.8A1.8 1.8 0 013 19.2V4.8A1.8 1.8 0 014.8 3zm9.714 6.913a3.6 3.6 0 00-2.333-.873c-.706 0-1.413.254-1.413.898 0 .61.646.845 1.433 1.131l.2.073c1.53.525 2.792 1.17 2.792 2.695 0 1.658-1.266 2.791-3.334 2.92l-.188.9a.352.352 0 01-.347.283l-1.302-.01a.354.354 0 01-.342-.429l.198-.937a4.767 4.767 0 01-2.09-1.174.359.359 0 01-.003-.51l.723-.718a.349.349 0 01.493 0c.698.695 1.598.98 2.457.98.942 0 1.581-.39 1.581-1.05 0-.583-.523-.778-1.52-1.148l-.33-.124c-1.278-.458-2.49-1.119-2.49-2.662 0-1.782 1.464-2.65 3.192-2.737l.19-.9a.353.353 0 01.344-.281h1.3c.223 0 .39.207.344.428l-.21 1.012c.745.246 1.35.63 1.802 1.021.153.133.158.37.016.515l-.672.688a.353.353 0 01-.491.01z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});