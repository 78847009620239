define("@square/sq-ember-eventstream/utils/dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.closestAncestorWithId = closestAncestorWithId;
  const EMBER_ID_REGEX = /^ember/; // Find nearest ancestor with ID that does not start with `ember` (because
  // these elements are generated automatically by Ember).
  //
  // We do this because a user may click a nested child of an element which
  // doesn't have an associated ID.
  //
  // Example:
  // <div id="relevant-container">
  //   <div id="ember123">
  //     <p>Click</p>
  //   </div>
  // </div>
  //
  // We want to capture `relevant-container` as the ancestor.

  function closestAncestorWithId(node) {
    // Ensure getAttribute function exists. The root node of the DOM does not
    // define the getAttribute function.
    const id = node.getAttribute && node.getAttribute('id');

    if (id && !EMBER_ID_REGEX.test(id)) {
      return node;
    } else if (node.parentNode) {
      return closestAncestorWithId(node.parentNode);
    } else {
      return null;
    }
  }
});