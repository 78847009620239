define("ember-svg-jar/inlined/terminal-sign-eur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.567 11.978c0-.107.004-.212.011-.314H14v-2h-2.525A2.531 2.531 0 0113.211 9c.628 0 1.173.203 1.6.55L16.072 8a4.49 4.49 0 00-2.862-1C11.335 7 9.804 8.054 9.06 9.664H8v2h.575a6.032 6.032 0 00.002.664H8v2h1.06C9.79 15.928 11.284 17 13.21 17c1.085 0 2.073-.346 2.86-.981l-1.258-1.556c-.414.335-.953.537-1.602.537-.677 0-1.287-.236-1.75-.672H14v-2h-3.42a4.387 4.387 0 01-.013-.35z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm2 0h14v14H5V5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});