define("@square/ember-qr-code/utils/embed-qrcode", ["exports", "@square/ember-qr-code/utils/qrcode", "@square/ember-qr-code/utils/square-qrcode"], function (_exports, _qrcode, _squareQrcode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmbedImage = _exports.EmbedQRCodeEncoder = _exports.EMBED_POSITION = void 0;
  const ATTRIBUTES_REGEX = /(\S+)\s*=\s*["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))?[^"']*)["']?/g;
  const DIMENSION_MIN = 9;
  const EMBED_VALUE = Object.freeze({
    TRANSPARENT: 'TRANSPARENT',
    TRUE: 'TRUE',
    FALSE: 'FALSE',
    UNDECIDED: 'UNDECIDED'
  });
  const EMBED_POSITION = Object.freeze({
    BOTTOM_BORDER: 'BOTTOM_BORDER',
    BOTTOM_CONTENT: 'BOTTOM_CONTENT',
    CENTER: 'CENTER',
    RIGHT_BORDER: 'RIGHT_BORDER',
    RIGHT_CONTENT: 'RIGHT_CONTENT'
  });
  _exports.EMBED_POSITION = EMBED_POSITION;
  const PADDING_SMALL = 17;
  const PADDING_SPLIT = 128;
  const PADDING_BIG = 236;
  const PIXEL_DIMENSION = 8;
  const PIXEL_THRESHOLD = 37; // Android and iOS both use a scanning approach where true only requires a small difference (10-20%) from background.
  // We use the following to play it safe: <5% = false, >30% = true, undecided elsewhere.

  const THRESHOLD_FALSE = 238.0;
  const THRESHOLD_FALSE_INVERT = 17.0;
  const THRESHOLD_TRUE = 178.5;
  const THRESHOLD_TRUE_INVERT = 76.5;
  /**
   * CLASS EmbedQRCodeEncoder
   * This is used to generate an SVG for a QR code with the Square logo in place of the bottom right
   * alignment pattern and an arbitrary image in the center.
   * It is still in BETA.
   * The following options can be specified:
   *   border          boolean (default false) whether to add a 2-pixel quiet zone
   *   invert          boolean (default false) light data on dark background when on
   *   monochrome      boolean (default false) whether to make it all black and white
   *                   This is useful for things like thermal printers that only print black
   *   position        CENTER | BOTTOM_BORDER | BOTTOM_CONTENT | RIGHT_BORDER | RIGHT_CONTENT
   *                   Where to place the image, content keeps image in content area, border extends to edge
   *   styleBackground string to override style inserted in SVG background rectangle
   *   styleForeground string to override style inserted in SVG foreground data
   *   styleLogo       string to override style inserted in SVG for Square jewel and finder patterns
   */

  class EmbedQRCodeEncoder extends _squareQrcode.SquareQRCodeEncoder {
    static async forFile(file, content, options = {}) {
      const image = await this.imageForFile(file);
      const hasAlpha = this.hasAlpha(file, image);
      const inverted = options.invert === undefined ? false : options.invert;
      const position = this.position(options);
      const imageConstructor = {
        hasAlpha,
        image,
        inverted,
        position
      };

      if (file.type === 'image/svg+xml') {
        imageConstructor.svg = await file.text();
      }

      let imageDimension = DIMENSION_MIN;
      let encoder = new this(content, Object.assign({
        image: new EmbedImage(imageDimension, imageConstructor)
      }, options));
      let maxEncoder = encoder;
      let maxCoverage = encoder.imageCoverage;
      let minVersion = encoder.code.version;

      do {
        imageDimension++;
        encoder = new this(content, Object.assign({
          image: new EmbedImage(imageDimension, imageConstructor)
        }, options));
        const coverage = encoder.imageCoverage;

        if (coverage > maxCoverage) {
          maxEncoder = encoder;
          maxCoverage = coverage;
        }
      } while (encoder.code.version - minVersion < 3 || encoder.imageIsFullyCovered);

      maxEncoder.initializeTurtleData();
      maxEncoder.initializeSvg();
      return maxEncoder;
    }

    static hasAlpha(file, image) {
      if (file.type === 'image/jpeg') return false;
      let width, height;

      if (image.width > image.height) {
        width = 120;
        height = Math.floor(image.height * 120 / image.width);
      } else {
        height = 120;
        width = Math.floor(image.width * 120 / image.height);
      }

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, width, height);
      const data = context.getImageData(0, 0, width, height).data;

      for (let i = 3; i < data.length; i += 4) {
        if (data[i] < 255) return true;
      }

      return false;
    }

    static async imageForFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          resolve(image);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

    static position(options) {
      let position = options.position;
      if (position === undefined) return EMBED_POSITION.CENTER;
      position = EMBED_POSITION[position];
      if (position === undefined) return EMBED_POSITION.CENTER;
      if (options.border === undefined ? false : options.border) return position;

      if (position === EMBED_POSITION.BOTTOM_BORDER) {
        position = EMBED_POSITION.BOTTOM_CONTENT;
      } else if (position === EMBED_POSITION.RIGHT_BORDER) {
        position = EMBED_POSITION.RIGHT_CONTENT;
      }

      return position;
    }

    encode() {
      this.image = this.code.options.image;
      this.position = this.constructor.position(this.code.options);
      this.initializeEncoding();

      for (const errorCorrection of this.errorCorrections) {
        this.code.errorCorrection = errorCorrection;
        this.initializeVersion();
        if (this.code.version < this.minimumVersion) continue;
        if (this.encodeAttempt()) return this.encodeSuccess();
      }

      const start = Math.max(this.minimumVersion, this.code.version + 1);

      for (let version = start; version <= _qrcode.QRCode.MAX_VERSION; version++) {
        this.code.version = version;
        if (this.encodeAttempt()) return this.encodeSuccess();
      }

      if (this.svg === undefined) throw 'this image cannot be embedded at this size';
    }

    encodeAttempt() {
      if (this.cannotEmbedImage) return false;
      this.initializeCodewords();
      this.initializeMapping();
      this.initializeMasking();
      if (this.hasAcceptablePenalty) return true; // If the code does not scan with default padding (alternating PADDING_BIG and PADDING_SMALL),
      // try changing the values (<PADDING_SPLIT change small, >=PADDING_SPLIT change big).
      // Return the first code that will scan.

      this.initializePadding();

      if (this.paddingSmall.length > 0) {
        for (let adjustment = 1; adjustment < PADDING_SPLIT; adjustment++) {
          if (adjustment === PADDING_SMALL) continue;
          this.paddingSmall.forEach(index => this.codewords[index] = adjustment);
          this.initializeMapping();
          this.initializeMasking();
          if (this.hasAcceptablePenalty) return true;
        } // reset the small padding codewords


        this.paddingSmall.forEach(index => this.codewords[index] = PADDING_SMALL);
      }

      if (this.paddingBig.length > 0) {
        for (let adjustment = PADDING_SPLIT; adjustment < 256; adjustment++) {
          if (adjustment === PADDING_BIG) continue;
          this.paddingBig.forEach(index => this.codewords[index] = adjustment);
          this.initializeMapping();
          this.initializeMasking();
          if (this.hasAcceptablePenalty) return true;
        }
      }

      return false;
    }

    encodeSuccess() {
      // only generate the SVG if there is no image
      // otherwise, the encoding is finished by the forFile static method
      if (!this.hasImage) {
        this.initializeTurtleData();
        this.initializeSvg();
      }
    }

    initializePadding() {
      // Go through the codewords from the end and see where there is the default padding
      this.paddingSmall = [];
      this.paddingBig = [];

      for (let index = this.codewords.length - 1; index >= 0; index--) {
        const value = this.codewords[index];

        if (value === PADDING_SMALL) {
          this.paddingSmall.push(index);
        } else if (value === PADDING_BIG) {
          this.paddingBig.push(index);
        } else {
          return;
        }
      }
    }

    initializeSvg() {
      // generate the SVG
      const offset = this.svgOffset;
      const dimension = this.svgDimension;
      const logoStyle = this.svgLogoStyle;
      const dataStyle = this.svgForegroundStyle;
      const source = [];
      source.push(this.svgTag(dimension));
      source.push('<defs>');
      source.push(this.svgFinderPattern(logoStyle));
      source.push(this.svgLogo(logoStyle));
      source.push('</defs>');
      source.push(this.svgBackground(this.svgBackgroundStyle, dimension, offset === 0 ? undefined : 26));
      this.svgFinderPatterns(source, offset);
      this.svgTurtle(source, dataStyle, offset); // Square logo

      const logoPosition = this.logoPosition.x * 8 + offset;
      source.push(`<use xlink:href="#s" x="${logoPosition}" y="${logoPosition}" />`); // The image

      if (this.hasImage) {
        const imageX = this.imageX * PIXEL_DIMENSION + offset;
        const imageY = this.imageY * PIXEL_DIMENSION + offset;
        source.push(this.image.imageElement(imageX, imageY));
      } // finish


      source.push('</svg>');
      this.svg = source.join('');
    }

    initializeTurtleData() {
      super.initializeTurtleData(); // establish the logo position

      const alignmentPatternPositions = this.code.alignmentPatternPositions;
      this.logoPosition = alignmentPatternPositions[alignmentPatternPositions.length - 1]; // clear out one pixel border without the corners for Square logo

      const data = this.turtleData;
      data.fill(this.logoPosition.x, this.logoPosition.y - 1, 5, 7, false);
      data.fill(this.logoPosition.x - 1, this.logoPosition.y, 1, 5, false);
      data.fill(this.logoPosition.x + 5, this.logoPosition.y, 1, 5, false); // clear out the image

      if (this.hasImage) {
        const startY = this.imageY;
        const startX = this.imageX;
        const height = Math.min(this.code.dimension - startY, this.image.height);
        const width = Math.min(this.code.dimension - startX, this.image.width);
        const imageMatrix = this.image.matrix;

        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            if (imageMatrix.at(x, y) !== EMBED_VALUE.TRANSPARENT) {
              data.put(x + startX, y + startY, false);
            }
          }
        }
      }
    }

    get cannotEmbedImage() {
      if (!this.hasImage) return false;
      const dimension = this.code.dimension;
      const imageLeft = this.imageX;
      if (imageLeft < 0) return true;
      const imageTop = this.imageY;
      if (imageTop < 0) return true;
      const imageWidth = Math.min(dimension - imageLeft, this.image.width);
      const imageHeight = Math.min(dimension - imageTop, this.image.height);
      const imageRight = imageLeft + imageWidth;
      const imageBottom = imageTop + imageHeight;
      const imageMatrix = this.image.matrix; // check that the finder patterns aren't obscured

      if (imageLeft < 8 && imageTop < 8) {
        // top-left
        const bottom = Math.min(8 - imageTop, imageHeight);
        const right = Math.min(8 - imageLeft, imageWidth);

        for (let y = 0; y < bottom; y++) {
          for (let x = 0; x < right; x++) {
            if (imageMatrix.at(x, y) !== EMBED_VALUE.TRANSPARENT) return true;
          }
        }
      }

      if (imageLeft < 8 && imageBottom > dimension - 8) {
        // bottom-left
        const top = dimension - 8 - imageTop;
        const bottom = Math.min(imageHeight, dimension - imageTop);
        const right = Math.min(8 - imageLeft, imageWidth);

        for (let y = top; y < bottom; y++) {
          for (let x = 0; x < right; x++) {
            if (imageMatrix.at(x, y) !== EMBED_VALUE.TRANSPARENT) return true;
          }
        }
      }

      if (imageRight > dimension - 8 < 8 && imageTop < 8) {
        // top-right
        const bottom = Math.min(8 - imageTop, imageHeight);
        const left = dimension - 8 - imageLeft;
        const right = Math.min(imageWidth, dimension - imageLeft);

        for (let y = 0; y < bottom; y++) {
          for (let x = left; x < right; x++) {
            if (imageMatrix.at(x, y) !== EMBED_VALUE.TRANSPARENT) return true;
          }
        }
      } // check that the Square jewel is not impacted


      const jewelLeft = Math.max(dimension - 9 - imageLeft, 0);
      const jewelRight = Math.min(dimension - 4 - imageLeft, imageWidth);
      const jewelTop = Math.max(dimension - 9 - imageTop, 0);
      const jewelBottom = Math.min(dimension - 4 - imageTop, imageHeight);

      for (let y = jewelTop; y < jewelBottom; y++) {
        for (let x = jewelLeft; x < jewelRight; x++) {
          if (imageMatrix.at(x, y) !== EMBED_VALUE.TRANSPARENT) return true;
        }
      } // check that timing patterns aren't affected


      if (imageLeft < 7) {
        for (let y = 8; y < dimension - 8; y += 2) {
          if (y >= imageTop && y < imageBottom) {
            const value = imageMatrix.at(6 - imageLeft, y - imageTop);

            switch (value) {
              case EMBED_VALUE.TRANSPARENT:
              case EMBED_VALUE.TRUE:
                break;

              default:
                return true;
            }
          }

          if (y > imageTop && y + 1 < imageBottom) {
            const value = imageMatrix.at(6 - imageLeft, y + 1 - imageTop);

            switch (value) {
              case EMBED_VALUE.TRANSPARENT:
              case EMBED_VALUE.FALSE:
                break;

              default:
                return true;
            }
          }
        }
      }

      if (imageTop < 7) {
        for (let x = 8; x < dimension - 8; x += 2) {
          if (x >= imageLeft && x < imageRight) {
            const value = imageMatrix.at(x - imageLeft, 6 - imageTop);

            switch (value) {
              case EMBED_VALUE.TRANSPARENT:
              case EMBED_VALUE.TRUE:
                break;

              default:
                return true;
            }
          }

          if (x > imageLeft && x + 1 < imageRight) {
            const value = imageMatrix.at(x + 1 - imageLeft, 6 - imageTop);

            switch (value) {
              case EMBED_VALUE.TRANSPARENT:
              case EMBED_VALUE.FALSE:
                break;

              default:
                return true;
            }
          }
        }
      } // check that undecided pixels don't already bring it above the error correction threshold


      const map = _qrcode.QRCodeMap.for(this.code.errorCorrection, this.code.version);

      const errorCorrectionCapacity = map.errorCorrectionCapacity;
      const messedUpBytes = errorCorrectionCapacity.map(() => new Set());

      for (let y = imageTop; y < imageBottom; y++) {
        for (let x = imageLeft; x < imageRight; x++) {
          if (imageMatrix.at(x - imageLeft, y - imageTop) === EMBED_VALUE.UNDECIDED) {
            const pixel = map.at(x, y);
            if (map.isDataPixel(pixel)) messedUpBytes[pixel.chunk].add(pixel.byte);
          }
        }
      }

      for (let index = 0; index < errorCorrectionCapacity.length; index++) {
        if (errorCorrectionCapacity[index] < messedUpBytes[index].size * 2) return true;
      }

      return false;
    }

    get hasAcceptablePenalty() {
      return this.lowestPenalty < this.constructor.PENALTY_FOR_NEGATIVE_CAPACITY;
    }

    get hasImage() {
      return this.image !== undefined;
    }

    get imageCoverage() {
      const functionalDimension = this.code.dimension - 14;
      const image = this.image;
      return (image.width * image.height + 25) / (functionalDimension * functionalDimension);
    }

    get imageIsFullyCovered() {
      const dimension = this.code.dimension;
      const image = this.image;
      return image.width >= dimension || image.height >= dimension;
    }

    get imageX() {
      switch (this.position) {
        case EMBED_POSITION.BOTTOM_BORDER:
        case EMBED_POSITION.BOTTOM_CONTENT:
        case EMBED_POSITION.CENTER:
          return Math.floor((this.code.dimension - this.image.width) / 2);

        case EMBED_POSITION.RIGHT_CONTENT:
          return this.code.dimension - this.image.width;

        case EMBED_POSITION.RIGHT_BORDER:
          return this.code.dimension - this.image.width + 2;

        default:
          return undefined;
      }
    }

    get imageY() {
      switch (this.position) {
        case EMBED_POSITION.CENTER:
        case EMBED_POSITION.RIGHT_BORDER:
        case EMBED_POSITION.RIGHT_CONTENT:
          return Math.floor((this.code.dimension - this.image.height) / 2);

        case EMBED_POSITION.BOTTOM_CONTENT:
          return this.code.dimension - this.image.height;

        case EMBED_POSITION.BOTTOM_BORDER:
          return this.code.dimension - this.image.height + 2;

        default:
          return undefined;
      }
    }

    get isMonochrome() {
      const isMonochrome = this.code.options.monochrome;
      if (isMonochrome === undefined) return false;
      return isMonochrome;
    }

    get minimumVersion() {
      // if the code version is 1, then there is no bottom right alignment pattern
      return 2;
    }

    penaltyForMatrix(matrix) {
      let penalty = 0;
      const errorCorrectionCapacity = this.map.errorCorrectionCapacity;
      const messedUpBytes = errorCorrectionCapacity.map(() => new Set()); // figure out the messed up bytes based on putting the jewel in the bottom right

      const jewelStart = matrix.content.length - 10;
      const borderPixels = [];

      for (let i = 1; i < 6; i++) {
        borderPixels.push({
          x: jewelStart,
          y: jewelStart + i
        });
        borderPixels.push({
          x: jewelStart + 6,
          y: jewelStart + i
        });
        borderPixels.push({
          x: jewelStart + i,
          y: jewelStart
        });
        borderPixels.push({
          x: jewelStart + i,
          y: jewelStart + 6
        });
      }

      for (const point of borderPixels) {
        const pixel = this.map.at(point.x, point.y);

        if (this.map.isDataPixel(pixel) && matrix.at(point.x, point.y)) {
          messedUpBytes[pixel.chunk].add(pixel.byte);
        }
      }

      if (this.hasImage) {
        const startY = this.imageY;
        const startX = this.imageX;
        const map = this.map;
        const imageMatrix = this.image.matrix; // figure out the messed up bytes based on embedding the image

        const heightInBounds = Math.min(this.code.dimension - startY, this.image.height);
        const widthInBounds = Math.min(this.code.dimension - startX, this.image.width);

        for (let y = 0; y < heightInBounds; y++) {
          for (let x = 0; x < widthInBounds; x++) {
            const pixel = map.at(x + startX, y + startY);

            if (map.isDataPixel(pixel)) {
              const embedValue = imageMatrix.at(x, y);

              if (embedValue === EMBED_VALUE.FALSE) {
                if (matrix.at(x + startX, y + startY)) messedUpBytes[pixel.chunk].add(pixel.byte);
              } else if (embedValue === EMBED_VALUE.TRUE) {
                if (!matrix.at(x + startX, y + startY)) messedUpBytes[pixel.chunk].add(pixel.byte);
              } else if (embedValue === EMBED_VALUE.UNDECIDED) {
                messedUpBytes[pixel.chunk].add(pixel.byte);
              }
            }
          }
        }
      } // calculate penalty based on how many messed up bytes


      errorCorrectionCapacity.forEach((capacity, index) => {
        const leftoverCapacity = capacity - messedUpBytes[index].size * 2;

        if (leftoverCapacity < 0) {
          penalty += this.constructor.PENALTY_FOR_NEGATIVE_CAPACITY;
        } else if (leftoverCapacity < this.constructor.PENALTY_FOR_LEFTOVER_CAPACITY.length) {
          penalty += this.constructor.PENALTY_FOR_LEFTOVER_CAPACITY[leftoverCapacity];
        }
      }); // additional penalties for trying to have the corners be filled

      if (!matrix.at(jewelStart, jewelStart)) penalty++;
      if (!matrix.at(jewelStart, jewelStart + 6)) penalty++;
      if (!matrix.at(jewelStart + 6, jewelStart)) penalty++;
      if (!matrix.at(jewelStart + 6, jewelStart + 6)) penalty++;
      return penalty;
    }

    get svgForegroundStyle() {
      const fromOptions = this.code.options.styleForeground;
      if (fromOptions !== undefined) return fromOptions;

      if (this.inverted) {
        return this.isMonochrome ? 'fill="white"' : 'fill="white" fill-opacity="0.7"';
      } else {
        return this.isMonochrome ? 'fill="black"' : 'fill="black" fill-opacity="0.6"';
      }
    }

    svgLogo(style) {
      return `<path id="s" ${style} fill-rule="evenodd" d="M 0 7 C 0 2.8 2.8 0 7 0 L 33 0 C 37.2 0 40 2.8 40 7 L 40 33 C 40 37.2 37.2 40 33 40 L 7 40 C 2.8 40 0 37.2 0 33 Z M 8 10 C 8 8.8 8.8 8 10 8 L 30 8 C 31.2 8 32 8.8 32 10 L 32 30 C 32 31.2 31.2 32 30 32 L 10 32 C 8.8 32 8 31.2 8 30 Z M 15 16 C 15 15.5 15.5 15 16 15 L 24 15 C 24.5 15 25 15.5 25 16 L 25 24 C 25 24.5 24.5 25 24 25 L 16 25 C 15.5 25 15 24.5 15 24 Z" />`;
    }

    get svgLogoStyle() {
      if (this.isMonochrome) return this.svgForegroundStyle;
      const fromOptions = this.code.options.styleLogo;
      if (fromOptions !== undefined) return fromOptions;
      return this.inverted ? 'fill="white"' : 'fill="black"';
    }

    get svgOffset() {
      return this.hasBorder ? 16 : 0;
    }

  }
  /**
   * CLASS EmbedImage
   * A class that to encapsulate the embedded file, used by the EmbedQRCodeEncoder.
   * The following things are specified in the constructor:
   *   dimension  how many data pixels the largest dimension should have
   *   hasAlpha   whether the Image has transparent or translucent pixels
   *   image      the Image from the file
   *   inverted   (from encoder)
   *   position   (from encoder)
   *   svg        the text of the SVG file
   */


  _exports.EmbedQRCodeEncoder = EmbedQRCodeEncoder;

  class EmbedImage {
    constructor(dimension, constructor) {
      this.dimension = dimension;
      this.hasAlpha = constructor.hasAlpha;
      this.image = constructor.image;
      this.inverted = constructor.inverted;
      this.position = constructor.position;
      this.svg = constructor.svg;

      if (this.hasAlpha) {
        this.initializeAlphaData();
        this.initializeAlphaIndexes();
        this.initializeAlphaMatrix();
      } else {
        this.initializeData();
        this.initializeIndexes();
        this.initializeMatrix();
      }
    }

    imageElement(xOffset, yOffset) {
      const x = xOffset + this.drawX;
      const y = yOffset + this.drawY;
      const imageSvg = this.imageSvg(x, y);
      return imageSvg === undefined ? `<image width="${this.drawWidth}" height="${this.drawHeight}" x="${x}" y="${y}" xlink:href="${this.image.src}" />` : imageSvg;
    }

    imageSvg(x, y) {
      // for an SVG image, create an SVG to embed into main SVG
      if (this.svg === undefined) return undefined;
      const start = this.svg.indexOf('<svg');
      if (start === -1) return undefined;
      const svg = this.svg.slice(start);
      const end = svg.indexOf('>');
      if (end === -1) return undefined;
      const tagContents = svg.slice(5, end);
      const attributes = {};
      [...tagContents.matchAll(ATTRIBUTES_REGEX)].forEach(match => {
        if (match.length === 3) {
          attributes[match[1]] = match[2];
        }
      });

      if (attributes.viewBox !== undefined) {// viewBox already established
      } else if (attributes.width !== undefined && attributes.height !== undefined) {
        attributes.viewBox = `0 0 ${attributes.width} ${attributes.height}`;
      } else {
        return undefined;
      }

      attributes.x = x.toString();
      attributes.y = y.toString();
      attributes.width = this.drawWidth.toString();
      attributes.height = this.drawHeight.toString();
      const parts = ['<svg'];
      Object.entries(attributes).forEach(([key, value]) => {
        parts.push(` ${key}="${value}"`);
      });
      parts.push(svg.slice(end));
      return parts.join('');
    }

    initializeAlphaData() {
      const dimension = this.dimension;
      let context;
      let width, height, drawX, drawY, drawWidth, drawHeight;

      if (this.image.width > this.image.height) {
        width = dimension;
        drawWidth = width * PIXEL_DIMENSION;
        drawHeight = drawWidth * this.image.height / this.image.width;
        height = Math.ceil(drawHeight * 0.125);
        drawX = 0;

        switch (this.position) {
          case EMBED_POSITION.BOTTOM_BORDER:
          case EMBED_POSITION.BOTTOM_CONTENT:
            drawY = PIXEL_DIMENSION * height - drawHeight;
            break;

          default:
            drawY = (PIXEL_DIMENSION * height - drawHeight) * 0.5;
        }
      } else {
        height = dimension;
        drawHeight = height * PIXEL_DIMENSION;
        drawWidth = drawHeight * this.image.width / this.image.height;
        width = Math.ceil(drawWidth * 0.125);
        drawY = 0;

        switch (this.position) {
          case EMBED_POSITION.RIGHT_BORDER:
          case EMBED_POSITION.RIGHT_CONTENT:
            drawX = width * PIXEL_DIMENSION - drawWidth;
            break;

          default:
            drawX = (width * PIXEL_DIMENSION - drawWidth) * 0.5;
        }
      }

      this.width = width;
      this.height = height;
      this.drawX = drawX;
      this.drawY = drawY;
      this.drawWidth = drawWidth;
      this.drawHeight = drawHeight;
      const canvas = document.createElement('canvas');
      const canvasWidth = PIXEL_DIMENSION * width;
      const canvasHeight = PIXEL_DIMENSION * height;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      context = canvas.getContext('2d');
      context.drawImage(this.image, drawX, drawY, drawWidth, drawHeight);
      this.data = context.getImageData(0, 0, canvasWidth, canvasHeight).data;
    }

    initializeAlphaMatrix() {
      const matrix = _qrcode.Matrix.withAll(this.width, this.height, EMBED_VALUE.TRANSPARENT);

      const valueAt = (this.inverted ? this.valueAtInvertAlpha : this.valueAtAlpha).bind(this);

      for (let y = 0; y < this.height; y++) {
        for (let x = 0; x < this.width; x++) {
          if (!this.transparentAt(x, y)) {
            matrix.put(x, y, valueAt(x, y));
          }
        }
      }

      this.matrix = matrix;
    }

    initializeAlphaIndexes() {
      const transparentIndexes = [];
      const valueIndexes = [];

      for (let row = 0; row < PIXEL_DIMENSION; row++) {
        const dy = row * 2 - PIXEL_DIMENSION;
        const dys = dy * dy;

        for (let column = 0; column < PIXEL_DIMENSION; column++) {
          const dx = column * 2 - PIXEL_DIMENSION;
          const dxs = dx * dx;
          const index = (row * this.width * PIXEL_DIMENSION + column) * 4;
          transparentIndexes.push(index + 3);

          if (dxs + dys < PIXEL_THRESHOLD) {
            valueIndexes.push(index);
          }
        }
      }

      this.transparentIndexes = transparentIndexes;
      this.valueIndexes = valueIndexes;
    }

    initializeData() {
      const dimension = this.dimension;
      let context;
      let width, height, drawX, drawY, drawWidth, drawHeight;

      if (this.image.width > this.image.height) {
        width = dimension;
        drawWidth = width * PIXEL_DIMENSION - 4;
        drawHeight = drawWidth * this.image.height / this.image.width;
        height = Math.ceil((drawHeight + 4.0) * 0.125);
        drawX = 2;

        switch (this.position) {
          case EMBED_POSITION.BOTTOM_BORDER:
          case EMBED_POSITION.BOTTOM_CONTENT:
            drawY = PIXEL_DIMENSION * height - drawHeight;
            break;

          default:
            drawY = (PIXEL_DIMENSION * height - drawHeight) * 0.5;
        }
      } else {
        height = dimension;
        drawHeight = height * PIXEL_DIMENSION - 4;
        drawWidth = drawHeight * this.image.width / this.image.height;
        width = Math.ceil((drawWidth + 4.0) * 0.125);
        drawY = 2;

        switch (this.position) {
          case EMBED_POSITION.RIGHT_BORDER:
          case EMBED_POSITION.RIGHT_CONTENT:
            drawX = PIXEL_DIMENSION * width - drawWidth;
            break;

          default:
            drawX = (PIXEL_DIMENSION * width - drawWidth) * 0.5;
        }
      }

      this.width = width;
      this.height = height;
      this.drawX = drawX;
      this.drawY = drawY;
      this.drawWidth = drawWidth;
      this.drawHeight = drawHeight;
      const canvas = document.createElement('canvas');
      const canvasWidth = PIXEL_DIMENSION * width;
      const canvasHeight = PIXEL_DIMENSION * height;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      context = canvas.getContext('2d');
      context.fillStyle = this.invert ? 'black' : 'white';
      context.fill();
      context.drawImage(this.image, drawX, drawY, drawWidth, drawHeight);
      this.data = context.getImageData(0, 0, canvasWidth, canvasHeight).data;
    }

    initializeMatrix() {
      const matrix = _qrcode.Matrix.withAll(this.width, this.height, EMBED_VALUE.UNDECIDED);

      const valueAt = (this.inverted ? this.valueAtInvert : this.valueAt).bind(this);

      for (let y = 0; y < this.height; y++) {
        for (let x = 0; x < this.width; x++) {
          matrix.put(x, y, valueAt(x, y));
        }
      }

      this.matrix = matrix;
    }

    initializeIndexes() {
      const valueIndexes = [];

      for (let row = 0; row < PIXEL_DIMENSION; row++) {
        const dy = row * 2 - PIXEL_DIMENSION;
        const dys = dy * dy;

        for (let column = 0; column < PIXEL_DIMENSION; column++) {
          const dx = column * 2 - PIXEL_DIMENSION;
          const dxs = dx * dx;
          const index = (row * this.width * PIXEL_DIMENSION + column) * 4;

          if (dxs + dys < PIXEL_THRESHOLD) {
            valueIndexes.push(index);
          }
        }
      }

      this.valueIndexes = valueIndexes;
    }

    get integerArray() {
      // for testing
      const embedValues = [EMBED_VALUE.FALSE, EMBED_VALUE.TRANSPARENT, EMBED_VALUE.TRUE, EMBED_VALUE.UNDECIDED];
      return this.matrix.content.map(row => {
        let integer = 0;
        row.forEach(value => {
          integer *= 4;
          integer += embedValues.indexOf(value);
        });
        return integer;
      });
    }

    luminance(red, green, blue) {
      // return a luminance value (0.0 = black, 256.0 = white) for RGB values
      return 0.2126 * red + 0.7252 * green + 0.0722 * blue;
    }

    transparentAt(x, y) {
      const start = (y * this.width * PIXEL_DIMENSION + x) * PIXEL_DIMENSION * 4;

      for (let index of this.transparentIndexes) {
        if (this.data[start + index] > 0) {
          return false;
        }
      }

      return true;
    }

    valueAt(x, y) {
      const start = (y * this.width * PIXEL_DIMENSION + x) * PIXEL_DIMENSION * 4;
      let value = this.valueAtIndex(start + this.valueIndexes[0]);

      for (let index of this.valueIndexes) {
        const current = this.valueAtIndex(start + index);

        if (current === EMBED_VALUE.UNDECIDED) {
          return EMBED_VALUE.UNDECIDED;
        } else if (current !== value) {
          return EMBED_VALUE.UNDECIDED;
        }
      }

      return value;
    }

    valueAtAlpha(x, y) {
      const start = (y * this.width * PIXEL_DIMENSION + x) * PIXEL_DIMENSION * 4;
      let value = this.valueAtIndexAlpha(start + this.valueIndexes[0]);

      for (let index of this.valueIndexes) {
        const current = this.valueAtIndexAlpha(start + index);

        if (current === EMBED_VALUE.UNDECIDED) {
          return EMBED_VALUE.UNDECIDED;
        } else if (current !== value) {
          return EMBED_VALUE.UNDECIDED;
        }
      }

      return value;
    }

    valueAtIndex(index) {
      const data = this.data;
      const luminanceValue = this.luminance(data[index], data[index + 1], data[index + 2]);

      if (luminanceValue < THRESHOLD_TRUE) {
        return EMBED_VALUE.TRUE;
      } else if (luminanceValue > THRESHOLD_FALSE) {
        return EMBED_VALUE.FALSE;
      } else {
        return EMBED_VALUE.UNDECIDED;
      }
    }

    valueAtIndexAlpha(index) {
      const data = this.data;
      const luminanceValue = this.luminance(data[index], data[index + 1], data[index + 2]);
      const alphaValue = this.data[index + 3] / 255.0;
      const onWhiteBackground = luminanceValue * alphaValue + 255 * (1.0 - alphaValue);

      if (onWhiteBackground < THRESHOLD_TRUE) {
        return EMBED_VALUE.TRUE;
      } else if (onWhiteBackground > THRESHOLD_FALSE) {
        return EMBED_VALUE.FALSE;
      } else {
        return EMBED_VALUE.UNDECIDED;
      }
    }

    valueAtInvert(x, y) {
      const start = (y * this.width * PIXEL_DIMENSION + x) * PIXEL_DIMENSION * 4;
      let value = this.valueAtIndexInvert(start + this.valueIndexes[0]);

      for (let index of this.valueIndexes) {
        const current = this.valueAtIndexInvert(start + index);

        if (current === EMBED_VALUE.UNDECIDED) {
          return EMBED_VALUE.UNDECIDED;
        } else if (current !== value) {
          return EMBED_VALUE.UNDECIDED;
        }
      }

      return value;
    }

    valueAtInvertAlpha(x, y) {
      const start = (y * this.width * PIXEL_DIMENSION + x) * PIXEL_DIMENSION * 4;
      let value = this.valueAtIndexInvertAlpha(start + this.valueIndexes[0]);

      for (let index of this.valueIndexes) {
        const current = this.valueAtIndexInvertAlpha(start + index);

        if (current === EMBED_VALUE.UNDECIDED) {
          return EMBED_VALUE.UNDECIDED;
        } else if (current !== value) {
          return EMBED_VALUE.UNDECIDED;
        }
      }

      return value;
    }

    valueAtIndexInvert(index) {
      const data = this.data;
      const luminanceValue = this.luminance(data[index], data[index + 1], data[index + 2]);

      if (luminanceValue > THRESHOLD_TRUE_INVERT) {
        return EMBED_VALUE.TRUE;
      } else if (luminanceValue < THRESHOLD_FALSE_INVERT) {
        return EMBED_VALUE.FALSE;
      } else {
        return EMBED_VALUE.UNDECIDED;
      }
    }

    valueAtIndexInvertAlpha(index) {
      const data = this.data;
      const luminanceValue = this.luminance(data[index], data[index + 1], data[index + 2]);
      const alphaValue = this.data[index + 3] / 255.0;
      const onBlackBackground = luminanceValue * alphaValue;

      if (onBlackBackground > THRESHOLD_TRUE_INVERT) {
        return EMBED_VALUE.TRUE;
      } else if (onBlackBackground < THRESHOLD_FALSE_INVERT) {
        return EMBED_VALUE.FALSE;
      } else {
        return EMBED_VALUE.UNDECIDED;
      }
    }

  }

  _exports.EmbedImage = EmbedImage;
});