define("ember-svg-jar/inlined/circle-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.028 13.357c0 1.54-1.177 2.563-2.816 2.563C10.287 15.92 9 14.655 9 12.092 9 9.452 10.32 8 12.223 8c1.694 0 2.651.924 2.794 2.178h-1.925c-.077-.407-.396-.704-.935-.704-.858 0-1.309.968-1.342 2.332h.088c.209-.506.77-1.012 1.661-1.012 1.375 0 2.464.946 2.464 2.563zm-3.916-.044c0 .649.418 1.155 1.078 1.155.66 0 1.078-.506 1.078-1.155s-.418-1.155-1.078-1.155c-.66 0-1.078.506-1.078 1.155z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 100-14 7 7 0 000 14z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});