define("ember-svg-jar/inlined/critical-badge-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.152 11.42a1.146 1.146 0 11-2.292 0 1.146 1.146 0 012.292 0zM8.922 3.4H7.09v5.958h1.833V3.4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});