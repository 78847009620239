define("ember-svg-jar/inlined/chain-links-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_112418_217425)\" fill=\"#7B61FF\"><path d=\"M2.994 4.408L19.595 21.01l1.414-1.414L17.414 16H21c1.66 0 3-1.34 3-3v-2c0-1.66-1.34-3-3-3h-5c-1.3 0-2.4.84-2.81 2H21c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-5.586l-1-1H17c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-4.586L4.408 2.994 2.994 4.408zM5.759 10l-2-2H3c-1.66 0-3 1.34-3 3v2c0 1.66 1.34 3 3 3h5a2.98 2.98 0 002.462-1.297L9.759 14H3c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2.759z\"/><path d=\"M7 13h1.759l-1.976-1.976c-.447.1-.783.5-.783.976 0 .55.45 1 1 1z\"/></g><defs><clipPath id=\"clip0_112418_217425\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});