define("ember-svg-jar/inlined/pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.25 9H5v6h1.5v-2h1.75a.75.75 0 00.75-.75v-2.5A.75.75 0 008.25 9zm-.75 2.5h-1v-1h1v1z\" fill=\"#7B61FF\"/><path d=\"M15 9h4v1.5h-2.5v1h2V13h-2v2H15V9z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.25 9H10v6h2.25A1.75 1.75 0 0014 13.25v-2.5A1.75 1.75 0 0012.25 9zm-.75 4.5v-3h.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-.75z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 2a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H5zM4 5a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1H5a1 1 0 01-1-1V5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});