define("ember-svg-jar/inlined/up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.295 20.715l-4-4 1.42-1.41 2.29 2.29V4.005h2v13.59l2.29-2.29 1.41 1.41-4 4a.996.996 0 01-1.41 0zM3.705 8.705l-1.41-1.41 4-4a.996.996 0 011.41 0l4 4-1.41 1.41-2.29-2.29v13.59h-2V6.415l-2.3 2.29z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});