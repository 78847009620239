define("ember-svg-jar/inlined/circle-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.849 13.628c0 1.386-1.144 2.332-2.882 2.332-1.782 0-2.805-1.111-2.816-2.541h1.738c.055.638.374 1.111 1.1 1.111.671 0 1.1-.418 1.1-1.023 0-.638-.495-.968-1.144-.968h-.638v-1.364h.715c.55 0 .935-.319.935-.913 0-.528-.418-.858-.968-.858-.594 0-1.034.33-1.089.913H9.228c.033-1.21.946-2.277 2.761-2.277 1.782 0 2.662.847 2.662 2.112 0 .847-.55 1.353-1.122 1.584v.044c.627.253 1.32.781 1.32 1.848z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-2 0a7 7 0 11-14 0 7 7 0 0114 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});