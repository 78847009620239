define("ember-svg-jar/inlined/microphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 11c0 1.65-1.35 3-3 3s-3-1.35-3-3V5c0-1.65 1.35-3 3-3s3 1.35 3 3v6zm-3-7c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1s1-.45 1-1V5c0-.55-.45-1-1-1zm5 7h2c0 3.52-2.61 6.43-6 6.92V22h-2v-4.08c-3.39-.49-6-3.4-6-6.92h2c0 2.76 2.24 5 5 5s5-2.24 5-5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});