define("ember-svg-jar/inlined/puzzle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 4h7v2a1 1 0 102 0V4h7v5a3 3 0 110 6v5h-7v-2a1 1 0 10-2 0v2H4v-5a3 3 0 110-6V4zm2 2v5H4a1 1 0 100 2h2v5h3a3 3 0 116 0h3v-5h2a1 1 0 100-2h-2V6h-3a3 3 0 11-6 0H6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});