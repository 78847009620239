define("ember-svg-jar/inlined/card-swipe-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 5V2h2v3h3v2h-3v3h-2V7h-3V5h3z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 7a1 1 0 00-1 1v1h13v2H4v5a1 1 0 001 1h13a1 1 0 001-1v-4h2v4a3 3 0 01-3 3H5a3 3 0 01-3-3V8a3 3 0 013-3h9v2H5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});