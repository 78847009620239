define("ember-svg-jar/inlined/bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 17v-6c0-2.61-1.67-4.82-4-5.65V5c0-1.1-.9-2-2-2s-2 .9-2 2v.35C7.67 6.18 6 8.39 6 11v6H4v2h6c0 1.1.9 2 2 2s2-.9 2-2h6v-2h-2zM8 17v-6c0-2.21 1.79-4 4-4s4 1.79 4 4v6H8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});