define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10 5h8a1 1 0 011 1v8a1 1 0 01-1 1h-8a1 1 0 01-1-1V6a1 1 0 011-1zM7 6a3 3 0 013-3h8a3 3 0 013 3v8a3 3 0 01-3 3h-1v1a3 3 0 01-3 3H6a3 3 0 01-3-3v-8a3 3 0 013-3h1V6zm0 3H6a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1v-1h-5a3 3 0 01-3-3V9z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});