define("ember-svg-jar/inlined/chart-pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.64 5.64A8.942 8.942 0 003 12c0 2.4.94 4.67 2.64 6.36A8.95 8.95 0 0012 21c2.3 0 4.61-.88 6.36-2.63C20.06 16.67 21 14.41 21 12c0-2.4-.94-4.66-2.64-6.36-3.51-3.51-9.21-3.51-12.72 0zm2.17.76A7.15 7.15 0 0111 5.08v4.5L7.81 6.4zM5 12c0-1.53.5-2.98 1.4-4.19L10.59 12 6.4 16.19C5.5 14.99 5 13.53 5 12zm11.95 4.95c-2.49 2.49-6.4 2.7-9.14.65l4.89-4.89c.2-.2.3-.45.3-.71V5.08c1.45.21 2.84.86 3.95 1.97A6.96 6.96 0 0119 12c0 1.87-.73 3.63-2.05 4.95z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});