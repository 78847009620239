define("ember-svg-jar/inlined/emotion-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.997 3V0h2v3h3v2h-3v3h-2V5h-3V3h3zM15.997 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM9.497 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM11.997 15a3.001 3.001 0 01-2.782-1.875l-1.854.75a5.001 5.001 0 009.272 0l-1.854-.75A3.001 3.001 0 0111.997 15z\" fill=\"#7B61FF\"/><path d=\"M4.997 12a7 7 0 017-7h2V3h-2a9 9 0 109 9v-2h-2v2a7 7 0 11-14 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});