define("ember-svg-jar/inlined/dashed-circle-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 3h-1v2h1a7 7 0 11-7 7v-1H3v1a9 9 0 109-9z\" fill=\"#7B61FF\"/><path d=\"M8.762 5.792l.886-.463-.926-1.773-.886.464a9.002 9.002 0 00-3.168 2.76 9.007 9.007 0 00-.645 1.048l-.464.886 1.772.928.464-.885A6.969 6.969 0 017.05 7.05a7.042 7.042 0 011.712-1.259zM14.447 16l-.471-2.887L16 11.018l-2.797-.433L12 8l-1.203 2.585L8 11.018l2.024 2.095L9.553 16 12 14.642 14.447 16z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});