define("ember-svg-jar/inlined/felica-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#007AC3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.378 8.021v7.958h1.074V8.02h-1.074zm4.18 2.573v5.385h1.07v-5.385h-1.07zm0-.911h1.07V8.02h-1.07v1.662zm-15.06-.207H7.28V8.06H4.06c-.556 0-1.035.457-1.035 1.416v6.488H4.1v-2.608h2.862V11.94H4.1V10a.585.585 0 01.112-.365.38.38 0 01.287-.159h-.002zm5.884 5.188a.242.242 0 01-.119-.008.28.28 0 01-.106-.063.358.358 0 01-.079-.11.436.436 0 01-.038-.23v-1.292h2.114c.472 0 .815-.244.815-1.023v-1.814c0-.858-.338-1.399-1.048-1.399h-1.857c-.707 0-1.005.673-1.005 1.49v4.207c0 1.186.57 1.54 1.326 1.54h2.376v-1.304h-2.38v.006zm-.135-4.639h1.527c.025-.003.05 0 .075.01.024.009.047.024.067.044a.24.24 0 01.047.073.285.285 0 01.022.142v1.289a.278.278 0 01-.032.17.19.19 0 01-.117.094.14.14 0 01-.068 0h-1.723v-1.553a.285.285 0 01.03-.172.21.21 0 01.052-.062.165.165 0 01.067-.033.164.164 0 01.053 0v-.002zM24.47 8.06c-.94 0-1.182.634-1.182 1.597v4.95c0 .746.263 1.357 1.224 1.357h2.609V14.55h-2.393c-.3 0-.373-.14-.373-.492v-4.21a.442.442 0 01.019-.139.384.384 0 01.06-.12.302.302 0 01.092-.081.244.244 0 01.11-.032h2.48V8.06h-2.652.006zm7.367.667h-2.485v1.304h2.246a.256.256 0 01.124.004.29.29 0 01.113.063c.034.03.063.068.085.111a.408.408 0 01.044.239v1.332h-1.99a.697.697 0 00-.598.198c-.17.162-.282.4-.31.665a1.329 1.329 0 000 .23v2.04c-.003.135.015.27.054.395.039.126.097.241.171.34a.854.854 0 00.263.23.692.692 0 00.314.09h2.246a.802.802 0 00.613-.329 1.24 1.24 0 00.247-.77v-4.612c0-.991-.467-1.53-1.138-1.53h.001zm-.052 5.937H30.26a.17.17 0 01-.14-.055.262.262 0 01-.068-.161.26.26 0 010-.049v-1.251a.278.278 0 01.032-.17.189.189 0 01.118-.095.183.183 0 01.064 0h1.709v1.514a.285.285 0 01-.032.17.206.206 0 01-.051.061.164.164 0 01-.112.036h.006z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});