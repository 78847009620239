define("ember-svg-jar/inlined/sign-gbp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.969 6.505c-1.794-.094-3.006 1.061-3.006 2.544 0 .043-.002.088-.005.14-.008.162-.019.386.025.775.055.493.2 1.195.575 2.036H16v2h-4.866a5.411 5.411 0 01-1.078 4H18v2H7v-2c-.046 0-.071.005-.072.005 0 0 .034-.008.105-.037a2.66 2.66 0 00.428-.235c.344-.227.736-.57 1.057-1.016.466-.648.81-1.542.593-2.717H7v-2h1.41a8.506 8.506 0 01-.415-1.814c-.074-.66-.032-1.17-.032-1.137 0-2.798 2.349-4.686 5.111-4.541 1.48.078 2.498.49 3.946 1.47L15.9 7.635c-1.242-.84-1.914-1.076-2.931-1.13z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});