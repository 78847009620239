define("ember-svg-jar/inlined/box-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 5h6.586l-7.293 7.293 1.414 1.414 7.29-7.29V13h2V4a1 1 0 00-1-1H11v2z\" fill=\"#7B61FF\"/><path d=\"M5 10a1 1 0 011-1h3V7H6a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3v-3h-2v3a1 1 0 01-1 1H6a1 1 0 01-1-1v-8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});