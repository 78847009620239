define("ember-svg-jar/inlined/card-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 12c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7zm3.71-1.2L17 13.09l4.31-4.29 1.41 1.41-5 5c-.2.19-.45.29-.71.29-.26 0-.51-.09-.71-.29l-3-3 1.41-1.41z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4zm32-3H4a3 3 0 00-3 3v16a3 3 0 003 3h28a3 3 0 003-3V4a3 3 0 00-3-3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});