define("ember-svg-jar/inlined/bracket-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.998 19c0 .55.45 1 1 1h4v2h-4c-1.66 0-3-1.34-3-3V5c0-1.66 1.34-3 3-3H10v2H5.998c-.55 0-1 .45-1 1v14z\" fill=\"#7B61FF\"/><path d=\"M16.59 10.99H8v2h8.59l-2.29 2.3 1.41 1.41 4-4a.996.996 0 000-1.41l-4-4L14.3 8.7l2.29 2.29z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});