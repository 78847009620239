define("ember-svg-jar/inlined/airplane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_142945_223656)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.851 2.136C9.477.853 10.531 0 12 0s2.523.853 3.149 2.136C15.747 3.36 16 5.039 16 6.998a.991.991 0 01-.004.084l-.185 2.186L24 13.003v5.272l-8.767-2.19-.184 2.17L18 20.017V24l-6-1.188-6 1.182v-3.982l2.95-1.757-.183-2.17L0 18.274v-5.274l8.189-3.733-.185-2.186A1 1 0 018 6.998c0-1.96.253-3.637.851-4.862zm-.483 9.248L2 14.287v1.427l6.596-1.648-.228-2.682zm7.036 2.682L22 15.714V14.29l-6.368-2.905-.228 2.682zM10 6.956l1.05 12.377L8 21.149v.413l4-.788 4 .792v-.415l-3.05-1.819L14 6.957c-.004-1.818-.25-3.125-.649-3.944C12.977 2.246 12.531 2 12 2s-.977.247-1.351 1.013c-.4.819-.645 2.126-.649 3.944z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_142945_223656\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});