define("ember-svg-jar/inlined/hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 13h8v-2H8v2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.52 16.072l3.885 4.273a2 2 0 001.48.655h6.23a2 2 0 001.48-.655l3.885-4.273a2 2 0 00.52-1.345V9.273a2 2 0 00-.52-1.345l-3.885-4.273A2 2 0 0015.115 3h-6.23a2 2 0 00-1.48.655L3.52 7.928A2 2 0 003 9.273v5.454a2 2 0 00.52 1.345zM5 9.273L8.885 5h6.23L19 9.273v5.454L15.115 19h-6.23L5 14.727V9.273z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});