define("ember-svg-jar/inlined/sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.49 8.83c0-1.04 1.1-1.47 2.09-1.47.89 0 1.8.39 2.34.89l1.36-1.94c-1.01-.74-2.01-1.1-3.06-1.22V3H10.9v2.23c-1.67.46-3.02 1.74-3.02 3.61 0 1.53.85 2.46 1.92 3.08 1.82 1.06 4.34 1.28 4.34 3.02 0 .99-.81 1.7-1.97 1.7-1.24 0-2.42-.64-3.19-1.49l-1.7 1.78c1.06.99 2.3 1.64 3.64 1.9V21h2.32v-2.15c1.95-.39 3.52-1.9 3.52-3.95-.02-4.56-6.27-3.89-6.27-6.07z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});