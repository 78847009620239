define("ember-svg-jar/inlined/circle-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.874 13.555c0 1.386-1.078 2.365-2.937 2.365-1.859 0-2.937-.979-2.937-2.365 0-.946.671-1.551 1.298-1.782v-.055c-.583-.297-1.1-.792-1.1-1.628 0-1.265 1.144-2.09 2.739-2.09s2.739.825 2.739 2.09c0 .836-.517 1.331-1.1 1.628v.055c.627.231 1.298.836 1.298 1.782zm-3.982-3.311c0 .55.429.858 1.045.858.616 0 1.045-.308 1.045-.858s-.429-.858-1.045-.858c-.616 0-1.045.308-1.045.858zm-.121 3.212c0 .616.462 1.012 1.166 1.012s1.166-.396 1.166-1.012-.462-1.012-1.166-1.012-1.166.396-1.166 1.012z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 100-14 7 7 0 000 14z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});