define("ember-svg-jar/inlined/page-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 3a3 3 0 00-3 3v3h18V6a3 3 0 00-3-3H6zM5 6a1 1 0 011-1h12a1 1 0 011 1v1H5V6z\" fill=\"#7B61FF\"/><path d=\"M3 18a3 3 0 003 3h12a3 3 0 003-3v-7h-2v7a1 1 0 01-1 1H6a1 1 0 01-1-1v-7H3v7z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});