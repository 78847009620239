define("ember-svg-jar/inlined/house-account-sign-gbp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 5a3 3 0 013-3h9v4.17c1.165.413 2 1.524 2 2.83v10a3 3 0 01-3 3H8a3 3 0 01-3-3V5zm3-1h7v2H8a1 1 0 010-2zM7 8h9a1 1 0 011 1v10a1 1 0 01-1 1H8a1 1 0 01-1-1V8z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.436 11.752c-.65-.034-1.014.367-1.014.825 0 .032-.002.059-.003.087-.004.068-.007.139.01.286.02.188.076.464.224.8H13.5v1.5h-1.58a2.54 2.54 0 01-.324 1H14.5v1.5h-5v-1.5a.434.434 0 00-.092.009 1.324 1.324 0 00.214-.1c.161-.094.345-.232.497-.406.134-.154.231-.322.28-.503H9.5v-1.5h.563a3.975 3.975 0 01-.126-.633c-.035-.316-.016-.587-.016-.54 0-1.444 1.218-2.396 2.594-2.323.728.038 1.234.249 1.909.706l-.841 1.242c-.52-.352-.766-.43-1.147-.45z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});