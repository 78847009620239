define("ember-svg-jar/inlined/calendar-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 5h6V3h2v2a3 3 0 013 3v6h-2v-3H6v7a1 1 0 001 1h6v2H7a3 3 0 01-3-3V8a3 3 0 013-3V3h2v2zM6 9h12V8a1 1 0 00-1-1H7a1 1 0 00-1 1v1z\" fill=\"#7B61FF\"/><path d=\"M18 16v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});