define("ember-svg-jar/inlined/deposit-sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.48 14H24v8H0v-8h3.52c-.34-.95-.52-1.96-.52-3 0-2.4.94-4.66 2.64-6.36C7.34 2.94 9.6 2 12 2c2.4 0 4.66.94 6.36 2.64C20.06 6.34 21 8.6 21 11c0 1.04-.18 2.05-.52 3zM12 4c-1.87 0-3.63.73-4.95 2.05A6.96 6.96 0 005 11c0 1.87.73 3.63 2.05 4.95.42.41.87.76 1.36 1.05h7.18c.49-.29.95-.64 1.36-1.05A6.96 6.96 0 0019 11c0-1.87-.73-3.63-2.05-4.95A6.96 6.96 0 0012 4zM2 16v4h20v-4h-2.52c-.23.35-.5.68-.79 1H21v2H3v-2h2.3c-.28-.32-.55-.65-.79-1H2zm7.92-1.74l-.84.84L7.92 14l.85-.85c-.37-.59-.58-1.31-.61-2.07l-.01-.18 1.68-.04-.01.18c-.03.62.2 1.24.6 1.64.37.37.84.41 1.15.1.36-.35.17-.84-.16-1.61l-.008-.018c-.138-.315-.284-.649-.372-.992-.21-.83-.05-1.52.49-2.06.68-.68 1.67-.8 2.61-.32l.88-.88 1.13 1.13-.81.81c.34.46.55.98.64 1.58l.03.17-1.6.27-.01-.2c-.01-.38-.21-.82-.5-1.11-.25-.24-.77-.57-1.11-.23-.247.257-.035.76.212 1.341l.008.019c.41.93.96 2.21-.15 3.32-.75.75-1.94.85-2.93.26z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});