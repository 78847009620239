define("ember-svg-jar/inlined/moving-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 1.882l9 4.5v11.236l-9 4.5-9-4.5V6.382l9-4.5zM5 8.618v7.764l6 3v-7.764l-6-3zm8 3v7.764l6-3V8.618l-6 3zM17.764 7L12 9.882 10.236 9 16 6.118 17.764 7zm-4-2L12 4.118 6.236 7 8 7.882 13.764 5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});