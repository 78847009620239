define("ember-svg-jar/inlined/paper-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 10v2h6v-2H5zM6 15a1 1 0 100-2 1 1 0 000 2zM11 14a1 1 0 11-2 0 1 1 0 012 0z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 10a2 2 0 00-2 2v1a2 2 0 002 2h2a2 2 0 002-2v-1a2 2 0 00-2-2h-2zm0 2h2v1h-2v-1z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 5h-2a1 1 0 01-2 0h-2a1 1 0 11-2 0h-2a1 1 0 11-2 0H7a1 1 0 11-2 0H3c0 .603-.476 1-1 1H1v13h22V6h-1c-.512 0-1-.42-1-1zm-3 3c.77 0 1.472-.29 2.003-.766.294.258.635.458.997.588V17H3V7.826a3.086 3.086 0 001.004-.586C4.534 7.713 5.234 8 6 8c.768 0 1.47-.289 2-.764.53.475 1.232.764 2 .764s1.47-.289 2-.764c.53.475 1.232.764 2 .764s1.47-.289 2-.764c.53.475 1.232.764 2 .764z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});