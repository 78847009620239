define("ember-svg-jar/inlined/wi-fi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.49 11.36A11.953 11.953 0 0012 7.85c-3.21 0-6.22 1.25-8.49 3.51L2.1 9.95c2.64-2.64 6.16-4.1 9.9-4.1s7.26 1.46 9.9 4.1l-1.41 1.41zM4.93 12.78l1.41 1.41c3.12-3.12 8.19-3.12 11.31 0l1.41-1.41c-3.89-3.9-10.23-3.9-14.13 0zM12 13.85c1.6 0 3.11.63 4.24 1.76l-1.41 1.41c-1.51-1.51-4.15-1.51-5.66 0l-1.41-1.41A5.928 5.928 0 0112 13.85zm0 6l-1.41-1.41c.78-.78 2.04-.78 2.82 0L12 19.85z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});