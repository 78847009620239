define("ember-svg-jar/inlined/chef-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.507 4.246C9.681 4.246 8 5.876 8 8v1H6V8c0-.42.044-.83.128-1.225-.51.135-.937.39-1.268.737C4.354 8.042 4 8.862 4 10c0 1.749 1.251 3 3 3 .714 0 1.37-.235 1.9-.633l.8-.6 1.2 1.599-.799.6A5.149 5.149 0 018 14.903V16h1v2H8v1a1 1 0 001 1h6a1 1 0 001-1v-1h-5v-2h5v-3h1c1.749 0 3-1.251 3-3 0-1.138-.354-1.96-.86-2.489-.5-.524-1.223-.84-2.121-.844.097.428.148.875.148 1.333 0 1.696-.709 3.23-1.846 4.29l-.732.682-1.364-1.463.732-.682A3.852 3.852 0 0015.167 8c0-2.114-1.585-3.754-3.66-3.754zm4.708.522c.2-.05.478-.101.785-.101 1.394 0 2.665.499 3.586 1.462C21.505 7.091 22 8.437 22 10c0 2.509-1.66 4.472-4 4.909V19a3 3 0 01-3 3H9a3 3 0 01-3-3v-4.091c-2.34-.438-4-2.4-4-4.909 0-1.563.495-2.908 1.414-3.87.92-.964 2.192-1.463 3.586-1.463h.036c1.006-1.46 2.645-2.421 4.471-2.421a5.565 5.565 0 014.708 2.522z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});