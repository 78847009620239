define("ember-svg-jar/inlined/sliders-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 3c1.3 0 2.4.84 2.82 2H22v2H9.82C9.4 8.16 8.3 9 7 9c-1.3 0-2.4-.84-2.82-2H2V5h2.18C4.6 3.84 5.7 3 7 3zM6 6c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm11 3c-1.3 0-2.4.84-2.82 2H2v2h12.18c.41 1.16 1.51 2 2.82 2 1.31 0 2.4-.84 2.82-2H22v-2h-2.18C19.4 9.84 18.3 9 17 9zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM7 15c-1.3 0-2.4.84-2.82 2H2v2h2.18C4.6 20.16 5.7 21 7 21c1.3 0 2.4-.84 2.82-2H22v-2H9.82C9.4 15.84 8.3 15 7 15zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});