define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 2a1 1 0 00-1 1v6.073C6.237 10.126 5 11.9 5 14v1h6v6l1 1 1-1v-6h6v-1c0-2.1-1.237-3.874-3-4.927V3a1 1 0 00-1-1H9zm1 8.297V4h4v6.297l.566.273c1.153.556 1.951 1.437 2.274 2.43H7.16c.323-.993 1.121-1.874 2.274-2.43l.566-.273z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});