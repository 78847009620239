define("ember-svg-jar/inlined/folder-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.997 7a3 3 0 013-3h4.914l2 2h7.086a3 3 0 013 3v1.5h-2V9a1 1 0 00-1-1h-7.914l-2-2H4.997a1 1 0 00-1 1v10a1 1 0 001 1H14v2H4.997a3 3 0 01-3-3V7z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.997 15a3 3 0 116 0 3 3 0 01-6 0zm3-1a1 1 0 100 2 1 1 0 000-2z\" fill=\"#7B61FF\"/><path d=\"M16 24a3 3 0 116 0h2a5 5 0 00-10 0h2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});