define("ember-svg-jar/inlined/deposit-sign-jpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.197 8.96l1.345-4.18 1.59 1.59-1.187 3.683 3.674-1.196 1.587 1.586-4.168 1.358.853.853-1.414 1.414-1.563-1.563-.53.53 1.563 1.563-1.414 1.415-1.564-1.564-.795.796-1.415-1.414.796-.796-1.568-1.568L8.4 10.053l1.568 1.568.53-.53-1.568-1.569 1.414-1.414.852.851z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.488 14a9 9 0 10-16.975 0H0v8h24v-8h-3.512zm-3.538 1.95A7.013 7.013 0 0115.609 17H8.39a7 7 0 118.558-1.05zM5.29 17a9.004 9.004 0 01-.776-1H2v4h20v-4h-2.515a9.008 9.008 0 01-.776 1H21v2H3v-2h2.291z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});