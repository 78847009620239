define("ember-svg-jar/inlined/underline-u", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_112418_217428)\" fill=\"#7B61FF\"><path d=\"M12.018 21.258c-2.3 0-4.034-.679-5.202-2.035-1.167-1.356-1.75-3.218-1.75-5.587V2.975h3.063v10.764c0 1.476.327 2.643.979 3.502.67.84 1.64 1.261 2.91 1.261 1.253 0 2.206-.42 2.858-1.261.67-.859 1.004-2.026 1.004-3.502V2.975h3.065v10.66c0 1.58-.267 2.945-.799 4.095-.515 1.133-1.287 2.008-2.317 2.626-1.013.601-2.283.901-3.811.901zM3.752 22.712h16.506V24H3.752v-1.288z\"/></g><defs><clipPath id=\"clip0_112418_217428\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});