define("ember-svg-jar/inlined/dogeared-paper-sign-jpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.211 13l-1.424-2.75h1.69L11.9 13h.199l1.425-2.75h1.69L13.787 13h.794v1.5h-1.571l-.26.5h1.832v1.5h-1.834v1.25h-1.5V16.5H9.418V15h1.829l-.26-.5h-1.57V13h.794z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 2a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V7.586L13.414 2H8zM7 5a1 1 0 011-1h4v5h5v10a1 1 0 01-1 1H8a1 1 0 01-1-1V5zm8.586 2L14 5.414V7h1.586z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});