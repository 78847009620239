define("ember-svg-jar/inlined/afterpay-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#B2FCE4\"/><path d=\"M25.305 7.497L22.94 6.143 20.542 4.77c-1.586-.909-3.568.232-3.568 2.064v.307a.463.463 0 00.24.41l1.114.636a.461.461 0 00.69-.403v-.732a.472.472 0 01.704-.41L21.908 7.9l2.18 1.25a.469.469 0 010 .814l-2.18 1.25-2.186 1.258a.472.472 0 01-.704-.41v-.363c0-1.831-1.982-2.98-3.567-2.063l-2.392 1.38-2.365 1.353c-1.592.916-1.592 3.219 0 4.135l2.365 1.353 2.398 1.373c1.586.91 3.568-.232 3.568-2.064v-.307a.462.462 0 00-.24-.41l-1.113-.636a.46.46 0 00-.69.403v.732a.471.471 0 01-.705.41L14.091 16.1l-2.18-1.25a.469.469 0 010-.813l2.18-1.251 2.186-1.258a.472.472 0 01.704.41v.363c0 1.831 1.982 2.98 3.568 2.064l2.398-1.374 2.365-1.353c1.585-.923 1.585-3.226-.007-4.141z\" fill=\"#000\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});