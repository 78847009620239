define("ember-svg-jar/inlined/gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.879 2.808a3 3 0 014.242 0l.9.9a1 1 0 00.707.292H17a3 3 0 013 3v1.272a1 1 0 00.293.707l.9.9a3 3 0 010 4.242l-.9.9a1 1 0 00-.293.707V17a3 3 0 01-3 3h-1.272a1 1 0 00-.707.293l-.9.9a3 3 0 01-4.242 0l-.9-.9A1 1 0 008.272 20H7a3 3 0 01-3-3v-1.272a1 1 0 00-.293-.707l-.9-.9a3 3 0 010-4.242l.9-.9A1 1 0 004 8.272V7a3 3 0 013-3h1.272a1 1 0 00.707-.293l.9-.9zm2.828 1.414a1 1 0 00-1.414 0l-.9.9A3 3 0 018.273 6H7a1 1 0 00-1 1v1.272a3 3 0 01-.879 2.121l-.9.9a1 1 0 000 1.414l.9.9A3 3 0 016 15.727V17a1 1 0 001 1h1.272a3 3 0 012.121.879l.9.9a1 1 0 001.414 0l.9-.9a3 3 0 012.121-.88H17a1 1 0 001-1v-1.271a3 3 0 01.879-2.121l.9-.9a1 1 0 000-1.414l-.9-.9A3 3 0 0118 8.273V7a1 1 0 00-1-1h-1.272a3 3 0 01-2.121-.879l-.9-.9z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 10a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});