define("ember-svg-jar/inlined/money-bag-sign-gbp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.163 12.103c0-.61.487-1.146 1.353-1.1.508.026.836.13 1.53.6l1.12-1.657c-.9-.61-1.574-.89-2.545-.94-1.834-.097-3.458 1.171-3.458 3.097 0-.062-.025.298.022.72.022.198.06.426.122.677H9v2h.908a1.56 1.56 0 01-.262.936 1.62 1.62 0 01-.472.462.9.9 0 01-.2.1L8.964 17H9v2h6.4v-2h-3.791c.195-.444.307-.948.3-1.5H14v-2h-2.597a3.565 3.565 0 01-.23-.9 2.028 2.028 0 01-.013-.382c.002-.037.003-.073.003-.115z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.94 7H18V5h-2.72l1-4H7.72l1.13 4.53c-4.06 2.7-6.43 7.96-5.34 12.08C4.42 21.04 7.52 23 12 23s7.58-1.96 8.48-5.38c.92-3.47-.61-7.74-3.54-10.62zm-3.22-4l-.5 2h-2.44l-.5-2h3.44zm4.83 14.1C17.87 19.65 15.61 21 12 21s-5.87-1.35-6.55-3.9c-.9-3.39 1.31-7.95 4.84-10.1h3.43c3.52 2.15 5.73 6.71 4.83 10.1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});