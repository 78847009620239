define("ember-svg-jar/inlined/terminal-sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.003 6v1.336c-1.044.42-1.838 1.327-1.838 2.542v.046a2.204 2.204 0 00.032.34c.031.197.095.452.226.726.273.575.805 1.163 1.739 1.484 1.048.36 1.572.664 1.833.91.197.187.26.35.26.638 0 .278-.092.467-.238.596-.156.139-.48.302-1.097.302-.598 0-1.2-.372-1.813-.944L8.744 15.44c.526.49 1.294 1.103 2.26 1.36V18h2v-1.204a3.118 3.118 0 001.34-.681c.626-.556.912-1.317.912-2.093 0-.765-.24-1.478-.886-2.09-.582-.55-1.442-.967-2.559-1.35-.398-.137-.527-.334-.582-.45a.7.7 0 01-.064-.238v-.016c0-.288.354-.843 1.255-.796.478.026.784.121 1.444.568l1.122-1.655c-.713-.483-1.28-.753-1.983-.862V6h-2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm2 0h14v14H5V5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});