define("ember-svg-jar/inlined/receipt-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 3h3a1 1 0 002 0h2a1 1 0 002 0h2a1 1 0 102 0h2a1 1 0 102 0h3v4h4v14H4v-4H0V3zm6 14v2h16V9h-2v8H6zM18 5.236A2.989 2.989 0 0116 6c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0112 6c-.768 0-1.47-.289-2-.764A2.989 2.989 0 018 6c-.768 0-1.47-.289-2-.764A2.989 2.989 0 014 6c-.768 0-1.47-.289-2-.764V15h16V5.236zM4 12v-2h6v2H4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});