define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 2.59l-8 8V18c0 1.65 1.35 3 3 3h10c1.65 0 3-1.35 3-3v-7.41l-8-8zM13 19h-2v-4h2v4zm5-1c0 .55-.45 1-1 1h-2v-6H9v6H7c-.55 0-1-.45-1-1v-6.59l6-6 6 6V18z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});