define("ember-svg-jar/inlined/message-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.002 2.25A7.493 7.493 0 002.51 9.746a7.47 7.47 0 00.871 3.511L2 17.752l4.492-1.383a7.493 7.493 0 0011-6.624 7.493 7.493 0 00-7.49-7.495zM4.508 9.746a5.495 5.495 0 015.494-5.497 5.495 5.495 0 015.493 5.497 5.495 5.495 0 01-8.361 4.689l-.385-.236-1.73.533L5.55 13l-.236-.385a5.469 5.469 0 01-.807-2.87z\" fill=\"#7B61FF\"/><path d=\"M18.492 9.746c0 .264-.012.526-.036.785a5.475 5.475 0 011.036 3.212 5.468 5.468 0 01-.807 2.87l-.236.385.532 1.731-1.73-.532-.385.236a5.462 5.462 0 01-2.868.807 5.466 5.466 0 01-3.21-1.035 8.592 8.592 0 01-2.99-.254 7.482 7.482 0 006.2 3.288 7.46 7.46 0 003.51-.872L22 21.75l-1.382-4.495a7.471 7.471 0 00.871-3.512 7.49 7.49 0 00-3.286-6.204 8.51 8.51 0 01.289 2.207z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});