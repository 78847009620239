define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.64 20.87L12 17.75l-5.64 3.12 1.08-6.6-4.51-4.62 6.23-.95L12 2.65l2.84 6.05 6.23.95-4.51 4.62 1.08 6.6zM12 15.47l3.01 1.66-.58-3.53 2.49-2.56-3.44-.53L12 7.35l-1.48 3.16-3.44.53 2.49 2.56-.58 3.53L12 15.47z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});