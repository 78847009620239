define("ember-svg-jar/inlined/arrow-down-letters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.704 20.707a1 1 0 01-1.414 0l-4-4 1.414-1.414 2.293 2.293V4h2v13.586l2.293-2.293 1.414 1.414-4 4z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.997 11V9.5H16V11h-2V6.999a2.999 2.999 0 115.997 0V11h-2zM16 6.999a.999.999 0 011.997 0V7.5H16v-.501z\" fill=\"#7B61FF\"/><path d=\"M17.117 15H14v-2h6v2.003L16.883 18H20v2h-6v-2.003L17.117 15z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});