define("ember-svg-jar/inlined/service-charge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 2h2v2H8c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h8c.55 0 1-.45 1-1v-9h2v9c0 1.66-1.34 3-3 3H8c-1.66 0-3-1.34-3-3V5c0-1.66 1.34-3 3-3z\" fill=\"#7B61FF\"/><path d=\"M14.077 2h1.641v2.507l2.21-1.276.857 1.484-2.262 1.307 2.272 1.311-.857 1.485-2.22-1.282V10h-1.641V7.434l-2.22 1.281L11 7.231l2.094-1.21-2.085-1.203.858-1.485 2.21 1.276V2zM15 16H9v2h6v-2zM15 12H9v2h6v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});