define("ember-svg-jar/inlined/speaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 1.75H8c-1.66 0-3 1.34-3 3v14c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3v-14c0-1.66-1.34-3-3-3zm1 17c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1v-14c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v14zm-5-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm1-8a1 1 0 11-2 0 1 1 0 012 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});