define("ember-svg-jar/inlined/wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 7.18V3.01H5c-1.66 0-3 1.34-3 3V18c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8c0-1.3-.84-2.4-2-2.82zM5 5.01h13V7H4.97A.995.995 0 014 6.01c0-.55.45-1 1-1zM19 19H5c-.55 0-1-.45-1-1V9l15.03.01c.54.02.97.45.97.99v1h-3.14c-1.66 0-3 1.34-3 3s1.34 3 3 3H20v1c0 .55-.45 1-1 1zm1-4h-3.14c-.55 0-1-.45-1-1s.45-1 1-1H20v2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});