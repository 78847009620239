define("@market/ember/modifiers/did-market-load", ["exports", "@ember/debug", "ember-modifier"], function (_exports, _debug, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Calls the provided function when the element is hydrated.
  
   * Usage:
   * {{!-- example.hbs --}}
   * <market-table {{did-market-load this.onMarketLoad}}>
   *  {{#if this.tableLoaded}}
   *   <VerticalCollection>
   *    {{!-- table content --}}
   *   </VerticalCollection>
   *  {{/if}}
   * </market-table>
   *
   * {{!-- example.js --}}
   * @tracked tableLoaded = false;
   * @action onMarketLoad() {
   *  this.tableLoaded = true;
   * }
   */
  var _default = (0, _emberModifier.modifier)(function didMarketLoad(element, [onMarketLoad]) {
    /*
     * The ability to properly type modifiers was added in ember-modifier@4.0.0.
     * (https://github.com/ember-modifier/ember-modifier/pull/210)
     *
     * This is a workaround to prevent the compiler from complaining about types.
     * This logic should be updated when the ember-modifier package is updated.
     */
    (false && !('componentOnReady' in element) && (0, _debug.assert)('did-market-load can only be installed on market components', 'componentOnReady' in element));
    (false && !(typeof onMarketLoad === 'function') && (0, _debug.assert)('parameter must be a function', typeof onMarketLoad === 'function'));
    const elementAsStencil = element;
    const onMarketLoadAsFunction = onMarketLoad;
    elementAsStencil.componentOnReady().then(el => {
      onMarketLoadAsFunction(el);
    });
  });

  _exports.default = _default;
});