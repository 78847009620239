define("ember-svg-jar/inlined/buildings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.997 6h-2v2h2V6zM5.997 10h2v2h-2v-2zM11.997 6h-2v2h2V6zM9.997 10h2v2h-2v-2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.997 5a3 3 0 013-3h8a3 3 0 013 3v5H19a3 3 0 013 3v9H1.997V5zm6 15h2v-4h-2v4zm4 0v-6h-6v6h-2V5a1 1 0 011-1h8a1 1 0 011 1v15h-2zM19 12h-3v8h4v-7a1 1 0 00-1-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});