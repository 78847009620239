define("ember-svg-jar/inlined/disposable-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 15.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 7V5h1.28l1-3h9.441l1 3H19v2h-1.117L16.21 20.372A3 3 0 0113.234 23h-2.469a3 3 0 01-2.976-2.628L6.117 7H5zm3.633 4l.75 6h5.234l.75-6H8.633zm7.234-4l-.25 2H8.383l-.25-2h7.734zM9.773 20.124L9.633 19h4.734l-.14 1.124a1 1 0 01-.993.876h-2.469a1 1 0 01-.992-.876zM15.613 5l-.333-1H8.72l-.333 1h7.225z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});