define("ember-svg-jar/inlined/message-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-2a.5.5 0 100-1 .5.5 0 000 1z\" fill=\"#7B61FF\"/><path d=\"M9.4 16.5c.521-.899 1.49-1.5 2.6-1.5s2.08.601 2.6 1.5l1.73-1A4.998 4.998 0 0012 13a4.998 4.998 0 00-4.33 2.5l1.73 1z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 11.999C3 7.029 7.03 3 12 3s9 4.03 9 8.999c0 .574-.06 1.129-.16 1.661a9.02 9.02 0 01-4.295 6.102h-.001a8.946 8.946 0 01-8.848.131L2.48 21.52l1.627-5.214A8.915 8.915 0 013 11.999zM12 5a7 7 0 00-7 6.999c0 1.343.384 2.592 1.043 3.658l.24.388-.758 2.43 2.429-.757.387.238a6.946 6.946 0 007.19.082 7.02 7.02 0 003.344-4.746A7 7 0 0019 12 7 7 0 0012 5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});