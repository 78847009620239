define("ember-svg-jar/inlined/fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.975 7.879c.609-1.512 1.38-2.821 2.135-3.702.46-.536.912-.914 1.318-1.08.154-.064.3-.097.439-.097.408 1.859 1.442 3.302 2.476 4.745C17.672 9.599 19 11.453 19 14.189 19 17.951 15.866 21 12 21s-7-3.05-7-6.81c0-1.091 0-3.024 1.197-4.951.392-.63.913-1.26 1.603-1.86a26.587 26.587 0 00.347 1.408c.077.276.162.56.252.82a6.207 6.207 0 00.215.55c.213.456.427.639.586.14a17.071 17.071 0 01.775-2.418zm-2.716 3.873c.121.148.31.346.578.513.37.231.99.456 1.716.25a2.188 2.188 0 001.212-.891c.18-.267.28-.533.34-.718l.023-.07.017-.072c.411-1.716 1.13-3.317 1.872-4.457.542.999 1.17 1.87 1.719 2.631.143.2.282.392.412.576C16.266 11.104 17 12.406 17 14.19 17 16.795 14.813 19 12 19s-5-2.205-5-4.81c0-.674.005-1.528.259-2.438zm6.95-6.815s-.005.001-.014.006a.08.08 0 00.014-.006z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});