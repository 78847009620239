define("ember-svg-jar/inlined/sign-jpy-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.25 12L1.625 5h2.252l3.4 6.566L10.68 5h2.253l-3.628 7h2.32v2H8.277v1h3.348v2H8.277v2h-2v-2H2.625v-2h3.652v-1H2.625v-2H5.25zM13.625 10.997h4.589L16.42 9.204l1.414-1.414 3.5 3.5a1 1 0 010 1.414l-3.5 3.5-1.414-1.414 1.793-1.793h-4.589v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});