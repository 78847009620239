define("ember-svg-jar/inlined/scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3 6a3 3 0 013-3h2v2H6a1 1 0 00-1 1v2H3V6zM16 5V3h2a3 3 0 013 3v2h-2V6a1 1 0 00-1-1h-2zM19 16v2a1 1 0 01-1 1h-2v2h2a3 3 0 003-3v-2h-2zM8 19v2H6a3 3 0 01-3-3v-2h2v2a1 1 0 001 1h2zM21 11H3v2h18v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});