define("ember-svg-jar/inlined/message-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 7v5a1 1 0 00.293.707l2.997 2.997 1.414-1.414L13 11.586V7h-2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3a9 9 0 00-7.908 13.3L2.49 21.511l5.21-1.603A9 9 0 1012 3zm-7 9a7 7 0 113.345 5.971l-.385-.236-2.449.754.754-2.45-.236-.384A6.963 6.963 0 015 12z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});