define("ember-svg-jar/inlined/chase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.803 3.635c0-.35.285-.635.636-.635h5.772l5.359 5.09H8.803V3.635zM20.364 8.802c.354 0 .636.284.636.635l-.002 5.772-5.087 5.36V8.802h4.454zM14.562 21.004c.35 0 .635-.285.635-.636v-4.453H3.43l5.36 5.088 5.772.001zM3.636 15.196A.637.637 0 013 14.56V8.788l5.089-5.36v11.768H3.636z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});