define("ember-svg-jar/inlined/handheld-scanner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.425.056c2.27-.436 4.162 1.743 3.405 3.921l-1.4 4.03a3.002 3.002 0 01-2.838 2.014H12.3l-.506 2.373a2.002 2.002 0 002.374-2.373h2.026a4.004 4.004 0 01-4.815 4.326l-1.553 7.28a3.003 3.003 0 01-3.562 2.307l-.979-.208a2.994 2.994 0 01-2.313-3.551L5.137 10.02h-.134A2.999 2.999 0 012 7.027V5.68a2.997 2.997 0 012.436-2.94L18.426.055zm1.514 3.267a1 1 0 00-1.135-1.307L4.814 4.701a.999.999 0 00-.812.98v1.346a1 1 0 001.001.998h12.59a1 1 0 00.945-.672l1.4-4.03zm-9.712 6.698H7.183L4.93 20.59a.998.998 0 00.771 1.184l.98.207c.54.115 1.072-.23 1.187-.769l2.384-11.186-.025-.005z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});