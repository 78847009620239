define("ember-svg-jar/inlined/audio-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 15.243V20l-5-5H2V9h2.757l2 2H4v2h2.83l.58.59L9 15.17v-1.927l2 2zM2.993 4.407l1.414-1.414 3.8 3.8L11 4v5.586l4.143 4.143c.65-1.685.309-3.677-1.023-5.039l1.48-1.45c2.128 2.17 2.494 5.477 1.098 8.044l1.77 1.77c2.284-3.588 1.888-8.449-1.188-11.594L18.76 4c3.873 3.954 4.274 10.134 1.202 14.548l1.045 1.045-1.414 1.414-16.6-16.6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});