define("ember-svg-jar/inlined/human-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 3a4 4 0 100 8 4 4 0 000-8zM7 7a2 2 0 114 0 2 2 0 01-4 0z\" fill=\"#7B61FF\"/><path d=\"M3 21a6 6 0 0112 0h2a8 8 0 10-16 0h2zM19.586 13l-1.793 1.793 1.414 1.414 3.5-3.5a1 1 0 000-1.414l-3.5-3.5-1.414 1.414L19.586 11h-4.59v2h4.59z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});