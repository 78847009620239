define("ember-svg-jar/inlined/microphone-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 5a3 3 0 116 0v6c0 .723-.256 1.386-.682 1.904l1.419 1.418A4.98 4.98 0 0017 11h2c0 1.828-.7 3.492-1.847 4.738l3.854 3.855-1.414 1.414-16.6-16.6 1.414-1.414L9 7.586V5zm4 6c0 .17-.042.33-.117.47L11 9.585V5a1 1 0 112 0v6z\" fill=\"#7B61FF\"/><path d=\"M11.751 15.994A5 5 0 017 11H5a7.001 7.001 0 006 6.93V22h2v-4.07c.196-.029.389-.065.579-.109l-1.828-1.827z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});