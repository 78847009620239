define("ember-svg-jar/inlined/square-stand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_143790_224711)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.284.628L8.35.004 4.883 16.313l2.935.624a3 3 0 003.558-2.311L11.72 13H15v5H6a3 3 0 00-3 3v3h15a3 3 0 003-3V10a3 3 0 00-3-3h-5.002l.598-2.814a3 3 0 00-2.31-3.558zM7.255 14.772L9.89 2.376l.978.208a1 1 0 01.77 1.186L9.42 14.21a1 1 0 01-1.186.77l-.978-.208zM12.213 11l.39-2H18a1 1 0 011 1v11a1 1 0 01-1 1H5v-1a1 1 0 011-1h11v-9h-4.787z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_143790_224711\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});