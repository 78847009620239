define("ember-svg-jar/inlined/color-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.229 17.808l8.543-8.542a2.724 2.724 0 00-3.852-3.852l-8.543 8.543c-.384.144-.735.38-1.044.689-.363.363-.589.89-.737 1.313a11.6 11.6 0 00-.383 1.435 20.92 20.92 0 00-.279 1.716l-.003.03-.001.009v.004l-.138 1.24 1.24-.137h.005l.008-.001.03-.003.11-.014a20.945 20.945 0 001.608-.266c.46-.096.973-.222 1.434-.383.423-.148.95-.374 1.313-.736.309-.31.544-.66.689-1.045zm6.105-10.98l-7.128 7.129 1.023 1.023 7.128-7.128a.724.724 0 00-1.023-1.024zM6.172 17.8c-.02.09-.037.18-.053.267.087-.017.176-.034.266-.053.42-.087.837-.192 1.181-.313a3.2 3.2 0 00.415-.173c.1-.052.14-.086.144-.09a.975.975 0 10-1.378-1.378.799.799 0 00-.09.145 3.207 3.207 0 00-.173.414 9.65 9.65 0 00-.312 1.181z\" fill=\"#7B61FF\"/><path d=\"M20.01 20.391l-8.01.01-.002-2 8.01-.01.002 2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});