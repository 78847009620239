define("ember-svg-jar/inlined/arrows-inward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 7.586L3.707 2.293 2.293 3.707 7.586 9H3v2h7a1 1 0 001-1V3H9v4.586zM13 14a1 1 0 011-1h7v2h-4.586l5.293 5.293-1.414 1.414L15 16.414V21h-2v-7z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});