define("ember-svg-jar/inlined/bank-of-america", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.501 8.479c-.283.08-.574.162-.84.25a25.72 25.72 0 00-3.68-1.287l-.29-.081c.31-.096.718-.207 1.004-.285L11.97 7c1.323.33 2.797.857 3.97 1.353-.14.042-.289.084-.439.126zM8.18 15.007c-.315-.222-.63-.444-.932-.623C11.564 11 15.218 9.54 17.353 9c.528.257.948.473 1.413.713l.076.039c-3.564 1.113-6.812 3.083-10.105 5.639-.184-.123-.37-.254-.556-.384zM9.414 7.827c1.368.316 3.353 1.053 3.924 1.368l-.052.023c-.388.164-.928.392-1.316.579-1.547-.78-3.354-1.273-3.893-1.42l-.032-.009c.391-.165.963-.39 1.369-.541zM10.526 10.489a25.732 25.732 0 00-.86-.39c-.181-.08-.354-.156-.508-.227-1.188.587-2.737 1.474-4.692 2.872.26.14.53.295.812.458l.541.31c1.504-1.114 3.023-2.166 4.707-3.023zM11.97 17.842c3.158-2.707 7.594-5.338 10.03-6.286-.376-.24-.962-.586-1.624-.962-3.143 1.038-6.812 3.338-10.09 5.94l.227.171c.487.368.989.747 1.457 1.137zM6.617 9c-1.76.812-3.61 1.925-4.617 2.571.14.065.28.124.427.187.234.1.487.207.791.355 2.226-1.504 3.97-2.421 4.662-2.722a14.728 14.728 0 00-1.009-.317c-.094-.027-.18-.051-.254-.074z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});