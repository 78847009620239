define("ember-svg-jar/inlined/qp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.713 16.134l-.789-.456 1.691-2.936 1.325.763a6.057 6.057 0 10-1.698 2.933l.39.225 1.69-2.937-.918-.529-1.69 2.937zm-3.968-.828a3.595 3.595 0 113.585-3.305l-3.203-1.845-1.692 2.936 3.059 1.762a3.58 3.58 0 01-1.749.452zm5.795 1.88l1.691-2.938.571.329-1.69 2.938-.572-.33zm3.487-1.903l-1.704 2.93.192.11 1.703-2.929-.191-.111zm4.918-6.24a.187.187 0 01.055.132v1.553a.187.187 0 01-.187.186H19.42a.186.186 0 00-.186.187v2.392a.187.187 0 01-.186.186h-1.553a.186.186 0 01-.187-.186v-2.392a.187.187 0 00-.186-.187h-2.392a.186.186 0 01-.186-.186V9.175a.187.187 0 01.186-.186h2.392a.187.187 0 00.186-.186V6.41a.185.185 0 01.186-.186h1.554a.187.187 0 01.186.186v2.392a.185.185 0 00.115.172c.022.01.046.014.07.014h2.393c.05 0 .097.02.132.055z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});