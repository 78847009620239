define("ember-svg-jar/inlined/wireless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.22 3.51l1.41-1.41c5.46 5.46 5.46 14.34 0 19.79l-1.41-1.41c4.68-4.68 4.68-12.29 0-16.97zM10.8 4.93L9.39 6.34c3.12 3.12 3.12 8.2 0 11.31l1.41 1.41c3.9-3.89 3.9-10.23 0-14.13zM7.97 16.24A5.928 5.928 0 009.73 12c0-1.6-.62-3.11-1.76-4.24L6.56 9.17c.76.76 1.17 1.76 1.17 2.83s-.42 2.07-1.17 2.83l1.41 1.41z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});