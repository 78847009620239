define("ember-svg-jar/inlined/globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 3c-4.96 0-9 4.04-9 9s4.03 9 8.99 9h.02c4.96 0 8.99-4.04 8.99-9s-4.04-9-9-9zm5.45 4.61c-.56.21-1.18.39-1.84.53-.2-.95-.47-1.81-.8-2.55a7.1 7.1 0 012.64 2.02zM19 12c0 .94-.19 1.83-.52 2.65-.77-.32-1.63-.57-2.57-.76.06-.61.09-1.24.09-1.89 0-.65-.03-1.28-.09-1.88.93-.19 1.8-.45 2.57-.76.33.81.52 1.7.52 2.64zm-7 6.99c-.4-.19-1.16-1.37-1.62-3.41.53-.05 1.07-.07 1.62-.07s1.09.03 1.62.07c-.46 2.04-1.22 3.22-1.62 3.41zm0-5.49c-.66 0-1.3.04-1.93.09-.04-.5-.07-1.02-.07-1.59 0-.57.03-1.09.07-1.59.63.06 1.27.09 1.93.09.66 0 1.3-.04 1.93-.09.04.5.07 1.02.07 1.59 0 .57-.03 1.09-.07 1.59-.63-.06-1.27-.09-1.93-.09zM5 12c0-.94.19-1.83.52-2.65.77.32 1.63.57 2.57.76C8.03 10.72 8 11.35 8 12c0 .65.03 1.28.09 1.88-.93.19-1.8.45-2.57.76A6.98 6.98 0 015 12zm7-6.99c.4.19 1.16 1.37 1.62 3.41-.53.05-1.07.08-1.62.08s-1.09-.03-1.62-.07C10.84 6.38 11.6 5.2 12 5.01zm-2.81.58c-.33.73-.6 1.59-.8 2.55-.66-.14-1.28-.32-1.84-.53a7.1 7.1 0 012.64-2.02zm-2.64 10.8c.56-.21 1.17-.39 1.84-.53.2.95.47 1.81.8 2.55a7.1 7.1 0 01-2.64-2.02zm8.26 2.02c.33-.73.6-1.59.8-2.55.66.14 1.28.32 1.84.53a7.1 7.1 0 01-2.64 2.02z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});