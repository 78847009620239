define("ember-svg-jar/inlined/food-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 9c0-3.938-3.431-6.997-7.494-7H12.5C8.435 2 5 5.06 5 9v1h6.5v3H5v2H4v-1H2v4h2v-1h1v.833c0 .71.363 1.262.768 1.607.39.332.915.56 1.44.56H8a1 1 0 100 2 1 1 0 000-2h8.17A3.001 3.001 0 1020 16.17V13h-6.5v-3H20V9zM7.112 8H9.39c.097-1.29.316-2.466.631-3.412l.022-.065C8.54 5.207 7.451 6.497 7.112 8zm5.973-2.78c.244.73.431 1.687.522 2.78h-2.21c.09-1.093.278-2.05.522-2.78.176-.53.365-.887.525-1.09.022-.028.041-.05.058-.069.017.019.036.041.058.069.16.203.349.56.525 1.09zM17.889 8h-2.274c-.097-1.29-.316-2.466-.631-3.412l-.021-.063c1.5.685 2.587 1.973 2.926 3.475zM18 16.17V15H7v2.833s0 .005.005.015c.007.014.025.04.06.07A.428.428 0 007.22 18h8.952A3.009 3.009 0 0118 16.17zM20 19a1 1 0 11-2 0 1 1 0 012 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});