define("ember-svg-jar/inlined/dogeared-paper-sign-gbp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.421 12.577c0-.458.365-.86 1.015-.825.381.02.627.098 1.147.45l.84-1.242c-.674-.457-1.18-.668-1.908-.706-1.376-.072-2.593.879-2.593 2.323 0-.046-.02.224.016.54.02.181.058.395.125.633H9.5v1.5h.899c-.049.18-.146.35-.28.503a1.986 1.986 0 01-.497.405 1.324 1.324 0 01-.214.1.434.434 0 01.092-.008v1.5h5v-1.5h-2.904a2.603 2.603 0 00.324-1h1.58v-1.5h-1.847a2.76 2.76 0 01-.225-.8c-.016-.147-.013-.218-.01-.286.002-.028.004-.055.004-.087z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 2a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V7.586L13.414 2H8zM7 5a1 1 0 011-1h4v5h5v10a1 1 0 01-1 1H8a1 1 0 01-1-1V5zm8.586 2L14 5.414V7h1.586z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});