define("@market/ember/index", ["exports", "ember", "@ember/test"], function (_exports, _ember, _test) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openModal = openModal;
  _exports.dismissModal = dismissModal;

  async function openModal(templateElement, customSelector) {
    const selector = customSelector !== null && customSelector !== void 0 ? customSelector : 'market-context-manager';
    const contextManager = document.querySelector(selector);

    if (!contextManager) {
      throw new Error(`Could not find ${selector}`);
    }

    if (customSelector && contextManager.tagName !== 'MARKET-CONTEXT-MANAGER') {
      throw new Error(`Invalid tag for ${customSelector}, got: ${contextManager.tagName}, expected: MARKET-CONTEXT-MANAGER`);
    }

    const templateTagName = templateElement.tagName.toLowerCase();

    if (templateTagName !== 'template') {
      throw new Error(`Expected modifier to be applied to a template element, but got '${templateTagName} instead'`);
    }

    const modal = templateElement.firstElementChild;

    if (!['market-modal-partial', 'market-modal-full', 'market-dialog', 'market-blade'].includes(modal === null || modal === void 0 ? void 0 : modal.tagName.toLowerCase())) {
      throw new Error('Could not find a market-modal-partial, market-modal-full, market-dialog or market-blade element in template');
    }

    function alwaysWait() {
      return false;
    }

    function contextContentsChangedHandler(event) {
      if (contextManager && event.detail.action === 'marketNewDialogOpened' && event.detail.currentDialog.el === modal) {
        _ember.default.testing && (0, _test.unregisterWaiter)(alwaysWait);
        contextManager.removeEventListener('marketContextContentsChanged', contextContentsChangedHandler);
      }
    }

    contextManager.addEventListener('marketContextContentsChanged', contextContentsChangedHandler);
    _ember.default.testing && (0, _test.registerWaiter)(alwaysWait);
    await contextManager.open(modal);
    return modal;
  }

  async function dismissModal(modal) {
    const context = modal.parentElement;

    function alwaysWait() {
      return false;
    }

    function dialogClosedHandler(event) {
      if (event.detail.action === 'marketDialogClosed' && event.detail.currentDialog.el === modal) {
        _ember.default.testing && (0, _test.unregisterWaiter)(alwaysWait);
        context.removeEventListener('marketContextContentsChanged', dialogClosedHandler);
      }
    }

    context.addEventListener('marketContextContentsChanged', dialogClosedHandler);

    if (modal.tagName === 'MARKET-DIALOG') {
      // This function should always close the modal, even if it's persistent,
      // so we need to turn off `persistent` here. This also ensures the
      // `marketContextContentsChanged` event is fired, and the test waiter
      // registered below will correctly be unregistered by our `dialogClosedHandler`.
      modal.persistent = false;
    }

    _ember.default.testing && (0, _test.registerWaiter)(alwaysWait);
    await modal.dismiss();
  }
});