define("ember-svg-jar/inlined/bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 6H4.142L2.366 17.544A3 3 0 005.33 21h13.337a3 3 0 002.965-3.456L19.857 6H16a4 4 0 00-8 0zm2 0a2 2 0 114 0h-4zm-5.066 8l.923-6h12.285l.923 6H4.935zm-.307 2h14.745l.285 1.848A1 1 0 0118.668 19H5.331a1 1 0 01-.989-1.152L4.627 16z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});