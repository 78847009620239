define("ember-svg-jar/inlined/screwdriver-ruler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.347 22H5.653l-.833-5H6v-5H3.747l.734-3.04-.464-2.88V2H10v4.083l-.48 2.876.761 3.041h-2.28v5h1.18l-.834 5zM6.52 9.04l-.232.96H7.72l-.24-.96L8 5.918V4H6.017v1.92l.502 3.12zM20 2h-8v20h8V2zm-2 17v1h-4V4h4v1h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v2h2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});