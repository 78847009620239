define("ember-svg-jar/inlined/store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22 10l-1.18-6H3.18L2.02 9.8 2 10c0 1.47.81 2.75 2 3.44V20h10v-6.56c.38-.22.71-.5 1-.83.73.84 1.8 1.38 3 1.38v6h2v-6.56c1.19-.68 2-1.96 2-3.43zm-2.82-4l.4 2H4.42l.4-2h14.36zM4 10.09l.02-.09H8c0 1.1-.9 2-2 2-1.07 0-1.95-.85-2-1.91zM12 18H6v-4c1.2 0 2.27-.54 3-1.38.73.84 1.8 1.38 3 1.38v4zm0-6c-1.1 0-2-.9-2-2h4c0 1.1-.9 2-2 2zm4-2h3.98l.02.09A2.009 2.009 0 0118 12c-1.1 0-2-.9-2-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});