define("ember-svg-jar/inlined/circle-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.028 11.828c0 2.64-1.32 4.092-3.223 4.092-1.694 0-2.651-.924-2.794-2.178h1.925c.077.407.396.704.935.704 1.012 0 1.309-.979 1.342-2.354h-.088c-.209.517-.77 1.034-1.661 1.034-1.375 0-2.464-.946-2.464-2.563C9 9.023 10.177 8 11.816 8c1.925 0 3.212 1.265 3.212 3.828zm-4.268-1.221c0 .649.418 1.155 1.078 1.155.66 0 1.078-.506 1.078-1.155s-.418-1.155-1.078-1.155c-.66 0-1.078.506-1.078 1.155z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 100-14 7 7 0 000 14z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});