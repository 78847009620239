define("@square/environment-utils/services/current-environment", ["exports", "@ember/service", "@ember/application", "@ember/object/computed", "@square/environment-utils/environment"], function (_exports, _service, _application, _computed, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentEnvironment = (_dec = (0, _computed.alias)('_instance.isMobile'), _dec2 = (0, _computed.alias)('_instance.isAndroid'), _dec3 = (0, _computed.alias)('_instance.isIOS'), _dec4 = (0, _computed.alias)('_instance.isDevelopment'), _dec5 = (0, _computed.alias)('_instance.isTesting'), _dec6 = (0, _computed.alias)('_instance.isStaging'), _dec7 = (0, _computed.alias)('_instance.isSandbox'), _dec8 = (0, _computed.alias)('_instance.isProduction'), _dec9 = (0, _computed.alias)('_instance.name'), (_class = class CurrentEnvironment extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "_instance", void 0);

      _initializerDefineProperty(this, "isMobile", _descriptor, this);

      _initializerDefineProperty(this, "isAndroid", _descriptor2, this);

      _initializerDefineProperty(this, "isIOS", _descriptor3, this);

      _initializerDefineProperty(this, "isDevelopment", _descriptor4, this);

      _initializerDefineProperty(this, "isTesting", _descriptor5, this);

      _initializerDefineProperty(this, "isStaging", _descriptor6, this);

      _initializerDefineProperty(this, "isSandbox", _descriptor7, this);

      _initializerDefineProperty(this, "isProduction", _descriptor8, this);

      _initializerDefineProperty(this, "name", _descriptor9, this);
    }

    init() {
      super.init();
      this.set('_instance', (0, _application.getOwner)(this).lookup('environment:current') || (0, _environment.default)());
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isMobile", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isAndroid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isIOS", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isDevelopment", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isTesting", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isStaging", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isSandbox", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isProduction", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = CurrentEnvironment;
});