define("ember-svg-jar/inlined/stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.316 1.051a1 1 0 00-.632 0l-6 2a1 1 0 000 1.898l6 2a1 1 0 00.632 0l6-2a1 1 0 000-1.898l-6-2z\" fill=\"#7B61FF\"/><path d=\"M1.052 7.684a1 1 0 011.264-.633L8 8.946l5.684-1.895a1 1 0 01.633 1.897l-6 2a1 1 0 01-.633 0l-6-2a1 1 0 01-.632-1.264z\" fill=\"#7B61FF\"/><path d=\"M2.317 11.051a1 1 0 00-.633 1.897l6 2a1 1 0 00.632 0l6-2a1 1 0 00-.632-1.897L8 12.946 2.316 11.05z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});