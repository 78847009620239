define("ember-svg-jar/inlined/indicator-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_94354_207811)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.333 12A9.333 9.333 0 0012 2.667V0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12h2.667A9.333 9.333 0 0012 21.333 9.333 9.333 0 0021.333 12z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_94354_207811\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});