define("ember-svg-jar/inlined/diagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 2a4 4 0 00-1 7.874V11H5v3.126A4.002 4.002 0 006 22a4 4 0 001-7.874V13h10v1.126A4.002 4.002 0 0018 22a4 4 0 001-7.874V11h-6V9.874A4.002 4.002 0 0012 2zm-2 4a2 2 0 114 0 2 2 0 01-4 0zM4 18a2 2 0 114 0 2 2 0 01-4 0zm14-2a2 2 0 100 4 2 2 0 000-4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});