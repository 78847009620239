define("ember-svg-jar/inlined/digital-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 16H5v-2h6v2zM14 16c.547 0 1-.448 1-1s-.453-1-1-1c-.547 0-1 .448-1 1s.453 1 1 1zM19 15c0 .552-.453 1-1 1-.547 0-1-.448-1-1s.453-1 1-1c.547 0 1 .448 1 1z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.806 4.293a.984.984 0 00-1.4 0 1.007 1.007 0 000 1.414l1.4 1.414A2.957 2.957 0 003.907 8H7v2H4.46a2.972 2.972 0 00-2.783 1.947l-1.485 4C-.537 17.907.899 20 2.974 20h18.052c2.075 0 3.51-2.092 2.782-4.053l-1.485-4A2.972 2.972 0 0019.54 10H17V8h3.093c.788 0 1.544-.316 2.1-.879l1.401-1.414a1.007 1.007 0 000-1.414.984.984 0 00-1.4 0l-1.4 1.414a.985.985 0 01-.701.293H3.907a.986.986 0 01-.7-.293l-1.4-1.414zM15 8H9v2h6V8zM4.46 12a.99.99 0 00-.928.649l-1.486 4A.999.999 0 002.974 18h18.052a.999.999 0 00.927-1.351l-1.485-4a.99.99 0 00-.927-.649H4.459z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});