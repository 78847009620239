define("ember-svg-jar/inlined/backspace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 4h-.414l-.293.293-7 7a1 1 0 000 1.414l7 7 .293.293H23V4H9zm-5.586 8l6-6H21v12H9.414l-6-6zm13.88 3.707L15 13.414l-2.293 2.293-1.414-1.414L13.586 12l-2.293-2.293 1.414-1.414L15 10.586l2.293-2.293 1.414 1.414L16.414 12l2.293 2.293-1.414 1.414z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});