define("ember-svg-jar/inlined/dogeared-paper-sign-eur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.168 13.983c0-.092.004-.181.01-.268h1.675v-1.5h-1.036c.318-.307.72-.465 1.133-.465.408 0 .767.142 1.055.395l.99-1.127a3.066 3.066 0 00-2.045-.768c-1.34 0-2.395.792-2.918 1.965H9v1.5h.676a4.736 4.736 0 00.002.57H9v1.5h1.034c.514 1.163 1.544 1.965 2.916 1.965.782 0 1.488-.27 2.043-.754l-.986-1.13a1.567 1.567 0 01-1.057.384 1.61 1.61 0 01-1.137-.465h1.04v-1.5h-1.672a3.642 3.642 0 01-.013-.302z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 2a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V7.586L13.414 2H8zM7 5a1 1 0 011-1h4v5h5v10a1 1 0 01-1 1H8a1 1 0 01-1-1V5zm8.586 2L14 5.414V7h1.586z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});