define("ember-svg-jar/inlined/file-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.99 11.588v-8.59h2v8.59l2.3-2.29 1.41 1.41-4 4a.996.996 0 01-1.41 0l-4-4 1.41-1.41 2.29 2.29zM19 18c.55 0 1-.45 1-1v-4h2v4c0 1.66-1.34 3-3 3H5c-1.66 0-3-1.34-3-3l.04-3.96h2L4 17c0 .55.45 1 1 1h14z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});