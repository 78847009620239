define("ember-svg-jar/inlined/t-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.994 4.408L19.595 21.01l1.414-1.414-6.75-6.751 1.27-7.193h5.336l.463-2.648H7.624l-.463 2.648h5.283l-.808 4.571-7.228-7.228-1.414 1.414zM9.732 21l1.055-5.972 2.622 2.623-.591 3.35H9.732z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});