define("ember-svg-jar/inlined/passkey-triangle-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M82.83 88H13.17c-6.125 0-10.02-6.56-7.085-11.935l34.83-63.86c3.06-5.61 11.11-5.61 14.17 0l34.83 63.855c2.935 5.38-.96 11.935-7.085 11.935V88z\" fill=\"url(#paint0_linear_688_11295)\"/><path d=\"M50.945 63.235h-5.82l-3.13-24.005v-11.2h12v11.2L50.94 63.235h.005zM42 80V68h12v12H42z\" fill=\"#121212\"/><defs><linearGradient id=\"paint0_linear_688_11295\" x1=\"18.865\" y1=\"27.7\" x2=\"63.305\" y2=\"104.675\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#D9FF80\"/><stop offset=\".25\" stop-color=\"#BFE736\"/><stop offset=\".75\" stop-color=\"#006AFF\"/></linearGradient></defs>",
    "attrs": {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});