define("ember-svg-jar/inlined/cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 9V7h-2c0 1.1.9 2 2 2zM8 7h8c0 2.21 1.79 4 4 4v2c-2.21 0-4 1.79-4 4H8c0-2.21-1.79-4-4-4v-2c2.21 0 4-1.79 4-4zM6 7H4v2c1.1 0 2-.9 2-2zM4 17v-2c1.1 0 2 .9 2 2H4zm16-2c-1.1 0-2 .9-2 2h2v-2zM2 5h20v14H2V5zm11 7c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zm-4 0c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});