define("ember-svg-jar/inlined/italics-i", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.436 5.731c1.04 0 1.893-.853 1.893-1.867 0-1.04-.853-1.866-1.893-1.866a1.872 1.872 0 00-1.867 1.866 1.89 1.89 0 001.867 1.867zm-1.654 16.27L15.29 7.917h-3.094L9.69 22h3.093z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});