define("ember-svg-jar/inlined/right-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.71 7.71l-4 4-1.41-1.42L17.59 8H4V6h13.59L15.3 3.71l1.41-1.41 4 4c.39.39.39 1.02 0 1.41zM8.7 20.3l-1.41 1.41-4-4a.996.996 0 010-1.41l4-4 1.41 1.41L6.41 16H20v2H6.41l2.29 2.3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});