define("ember-svg-jar/inlined/shopping-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22 9h-3.32l-1.75-4.37-1.86.74L16.52 9H7.48l1.45-3.63-1.86-.74L5.32 9H2v1.96h1.39L5 19h14l1.61-8.04H22V9zm-4.64 8H6.64l-1.2-6h13.12l-1.2 6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});