define("ember-svg-jar/inlined/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 10a1 1 0 11-2 0 1 1 0 012 0zM9 15a1 1 0 100-2 1 1 0 000 2zM10 18a1 1 0 11-2 0 1 1 0 012 0zM11 11h5V9h-5v2zM16 15h-5v-2h5v2zM11 19h5v-2h-5v2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 3a2 2 0 012-2h6a2 2 0 012 2h1a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2h1zm2 0h6v2H9V3zm8 2a2 2 0 01-2 2H9a2 2 0 01-2-2H6v16h12V5h-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});