define("ember-svg-jar/inlined/card-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M32 1H4a3 3 0 00-3 3v16a3 3 0 003 3h28a3 3 0 003-3V4a3 3 0 00-3-3zM4 0a4 4 0 00-4 4v16a4 4 0 004 4h28a4 4 0 004-4V4a4 4 0 00-4-4H4z\" fill=\"#7B61FF\"/><path d=\"M5 13h26v2H5v-2zM5 17h8v2H5v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});