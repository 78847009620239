define("ember-svg-jar/inlined/arrow-up-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.704 3.293a1 1 0 00-1.414 0l-4 4 1.414 1.414 2.293-2.293V20h2V6.414l2.293 2.293 1.414-1.414-4-4zM13.997 9h6V7h-6v2zM19.997 13h-8v-2h8v2zM11.997 17h8v-2h-8v2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});