define("ember-svg-jar/inlined/cash-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 3h20v4h4v14H4v-4H0V3zm18 6c-2.21 0-4-1.79-4-4H6c0 2.21-1.79 4-4 4v2c2.21 0 4 1.79 4 4h8c0-2.21 1.79-4 4-4V9zm-2-4c0 1.1.9 2 2 2V5h-2zm2 10v-2c-1.1 0-2 .9-2 2h2zM2 7c1.1 0 2-.9 2-2H2v2zm0 6v2h2c0-1.1-.9-2-2-2zm4 4v2h16V9h-2v8H6zm7-7c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-2 0c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});