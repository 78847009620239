define("ember-svg-jar/inlined/bluetooth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 2l7 6-4.259 4L18 16l-7 6v-7.396l-3.811 3.621-1.378-1.45L11 12 5.811 7.225l1.378-1.45L11 9.5V2zm2 12.054L15 16l-2 1.773v-3.719zm0-4.108v-3.72L15 8l-2 1.946z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});