define("ember-svg-jar/inlined/clothes-hanger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10 7a2 2 0 112 2h-1.002v2.63l-.487.2c-.678.28-1.605.664-2.6 1.08-1.98.83-4.263 1.803-5.376 2.34l-.006.002c-1.387.68-1.717 2.204-1.433 3.37C1.391 19.827 2.411 21 4.053 21h15.893c1.642 0 2.663-1.173 2.957-2.378.285-1.166-.045-2.69-1.432-3.37l-.006-.003c-1.113-.536-3.398-1.51-5.378-2.338-.995-.417-1.923-.801-2.602-1.08l-.487-.202v-.754A4.002 4.002 0 0012 3a4 4 0 00-4 4h2zm1.273 6.679l.725-.298a510.535 510.535 0 013.317 1.375c1.992.833 4.22 1.784 5.278 2.293.293.145.503.546.368 1.098-.126.513-.488.853-1.015.853H4.053c-.526 0-.888-.34-1.014-.853-.135-.552.075-.953.368-1.098 1.058-.51 3.285-1.46 5.276-2.293.991-.416 1.915-.798 2.59-1.077z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});