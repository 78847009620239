define("ember-svg-jar/inlined/passkey-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M66.825 76.408h-.001l-18.846 12.76-18.822-12.757-.002-.001C19.698 69.994 14 59.196 14 47.59V17.748L48.046 6.223 82 17.746v29.832c0 11.607-5.71 22.427-15.175 28.83z\" fill=\"#000\" fill-opacity=\".9\" stroke=\"url(#paint0_linear_1097_16515)\" stroke-width=\"8\"/><path d=\"M46.125 42.5c4.764 0 8.625-3.778 8.625-8.438s-3.861-8.437-8.625-8.437c-4.764 0-8.625 3.778-8.625 8.438s3.861 8.437 8.625 8.437zM69.125 42.5a6.453 6.453 0 00-.922-3.344 6.642 6.642 0 00-2.534-2.419 6.828 6.828 0 00-6.828.188 6.618 6.618 0 00-2.391 2.554 6.442 6.442 0 00.407 6.67 6.669 6.669 0 002.685 2.257v10.032l2.875 2.812 4.791-4.687-2.874-2.813 2.874-2.812-2.376-2.325a6.687 6.687 0 003.114-2.407 6.463 6.463 0 001.18-3.706zm-6.708 0c-.38 0-.75-.11-1.065-.316a1.886 1.886 0 01-.706-.841 1.837 1.837 0 01-.109-1.084c.074-.364.256-.697.525-.96.268-.262.61-.44.98-.513a1.956 1.956 0 011.108.107c.35.142.65.382.86.69a1.846 1.846 0 01-.238 2.368c-.36.352-.847.55-1.355.55zM53.677 46.288A11.718 11.718 0 0049 45.313h-5.75c-3.05 0-5.975 1.185-8.132 3.294a11.127 11.127 0 00-3.368 7.956v3.75h24.917V49.98a9.713 9.713 0 01-2.99-3.694z\" fill=\"#fff\"/><defs><linearGradient id=\"paint0_linear_1097_16515\" x1=\"31.502\" y1=\"13.398\" x2=\"81.385\" y2=\"84.79\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".06\" stop-color=\"#006AFF\"/><stop offset=\".59\" stop-color=\"#B4DC36\"/><stop offset=\".92\" stop-color=\"#CEFF80\"/></linearGradient></defs>",
    "attrs": {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});