define("@square/environment-utils/-private/host-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HostMatcher {
    /**
     * @param {RegExp} pattern
     * @param {function(Array<string>): string} reducer
     */
    constructor(pattern, reducer) {
      this.pattern = pattern;
      this.reducer = reducer;
    }
    /**
     * @param {string} host
     * @returns {string|null}
     */


    match(host) {
      const match = host.match(this.pattern);

      if (!match) {
        return null;
      }

      return this.reducer(match);
    }

  }

  _exports.default = HostMatcher;
});