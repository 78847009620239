define("ember-svg-jar/inlined/square-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><path d=\"M22.993 4.5h-9.986A2.506 2.506 0 0010.5 7.006v9.986a2.508 2.508 0 002.507 2.508h9.986a2.508 2.508 0 002.507-2.508V7.006A2.506 2.506 0 0022.993 4.5zm-.22 11.482a.792.792 0 01-.791.791h-7.963a.79.79 0 01-.79-.791V8.019a.79.79 0 01.79-.792h7.963a.791.791 0 01.79.792v7.963z\" fill=\"#000\" fill-opacity=\".9\"/><path d=\"M16.406 14.035a.454.454 0 01-.453-.456v-3.178a.455.455 0 01.453-.457h3.188a.455.455 0 01.453.457v3.178a.452.452 0 01-.453.456h-3.188z\" fill=\"#000\" fill-opacity=\".9\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});