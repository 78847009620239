define("ember-svg-jar/inlined/question-mark-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-2a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM9 9.75C9 8.23 10.35 7 12 7s3 1.23 3 2.75c0 1.11-.66 1.77-1.19 2.29-.53.53-.81.83-.81 1.46h-2c0-1.48.8-2.28 1.388-2.868l.012-.012c.44-.43.6-.61.6-.87 0-.41-.46-.75-1-.75s-1 .34-1 .75H9z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});