define("ember-svg-jar/inlined/folder-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.997 4a3 3 0 00-3 3v10a3 3 0 003 3H14v-2H4.997a1 1 0 01-1-1V7a1 1 0 011-1h4.086l2 2h7.914a1 1 0 011 1v4h2V9a3 3 0 00-3-3h-7.086l-2-2H4.997z\" fill=\"#7B61FF\"/><path d=\"M18.793 21.793L20.586 20h-4.589v-2h4.589l-1.793-1.793 1.414-1.414 3.5 3.5a1 1 0 010 1.414l-3.5 3.5-1.414-1.414z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});