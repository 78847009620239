define("ember-svg-jar/inlined/money-bag-sign-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 7h-1.06c2.93 2.88 4.46 7.15 3.54 10.62-.9 3.42-4 5.38-8.48 5.38s-7.58-1.96-8.49-5.39C2.42 13.49 4.79 8.23 8.85 5.53L7.72 1h8.56l-1 4H18v2zm-4.78-2l.5-2h-3.44l.5 2h2.44zM12 21c3.61 0 5.87-1.35 6.55-3.9.9-3.39-1.31-7.95-4.83-10.1h-3.43c-3.53 2.15-5.74 6.71-4.84 10.1C6.13 19.65 8.39 21 12 21zm-.47-8.67c0 .29.5.49 1.03.7l.025.01c.947.379 2.235.894 2.245 2.48 0 1.07-.74 1.98-1.85 2.29V19h-1.76v-1.18c-.66-.17-1.29-.52-1.83-1.02l-.2-.19 1.28-1.34.19.21c.4.44.95.7 1.48.7.45 0 .77-.26.77-.63 0-.41-.38-.6-1.17-.91l-.033-.013c-.338-.135-.656-.263-.947-.437-.76-.44-1.14-1.06-1.14-1.85 0-.95.61-1.75 1.59-2.1V9h1.76v1.13c.54.1 1.03.31 1.5.66l.21.15-1.04 1.49-.23-.21c-.26-.24-.68-.4-1.05-.4-.03 0-.83.01-.83.51z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});