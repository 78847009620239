define("ember-svg-jar/inlined/call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.198 2.31l5.597 5.597-2.312 2.312a26.869 26.869 0 004.236 4.25l2.319-2.32 5.657 5.657-3.032 3.032c-.645.645-1.591 1.003-2.563.809C9.506 20.327 3.68 14.499 2.358 7.905c-.195-.972.164-1.918.808-2.563L6.198 2.31zm0 2.828L4.581 6.756c-.23.23-.31.516-.262.756 1.162 5.803 6.371 11.012 12.174 12.174.24.048.526-.033.756-.262l1.617-1.618-2.828-2.828-2.137 2.137-.694-.516a28.719 28.719 0 01-5.855-5.872l-.512-.693 2.127-2.127-2.769-2.769z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});