define("ember-svg-jar/inlined/loud-speaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22 5L4 9.5V9H2v6h2v-.5l2 .5v1c0 1.65 1.35 3 3 3a2.99 2.99 0 002.95-2.51L22 19V5zM9 17c-.55 0-1-.45-1-1v-.5l2 .5c0 .55-.45 1-1 1zm11-.56l-16-4v-.88l16-4v8.88z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});