define("ember-svg-jar/inlined/bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 6v5h-2v5h2v6H3v-6h2v-5H3V6l9-4 9 4zm-9-1.81L5 7.3V9h14V7.3l-7-3.11zM11 11v5h2v-5h-2zm-2 0H7v5h2v-5zm-4 7v2h14v-2H5zm10-2h2v-5h-2v5zm-2-9a1 1 0 11-2 0 1 1 0 012 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});