define("ember-svg-jar/inlined/circle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.728 14.365v1.54H9.349v-1.54l2.761-2.376c.572-.484.825-.902.825-1.463 0-.495-.363-.891-.946-.891-.616 0-.946.385-.99.968H9.272c.022-1.353.957-2.508 2.695-2.508 1.76 0 2.728.869 2.728 2.343 0 1.034-.407 1.628-1.397 2.497l-1.584 1.386v.044h3.014z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-2 0a7 7 0 11-14 0 7 7 0 0114 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});