define("@square/sq-ember-eventstream/instance-initializers/eventstream", ["exports", "eventstream.js", "@square/environment-utils/environment"], function (_exports, _eventstream, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // Prefer getting a name from an eventstream configuration but fallback to
  // APP name.
  function getApplicationName(config) {
    if (config.eventstream) {
      return config.eventstream.name || config.APP.name;
    } else {
      return config.APP.name;
    }
  }

  function getEnvironmentName(config) {
    if (config.eventstream) {
      return config.eventstream.environment || (0, _environment.default)().name;
    } else {
      return (0, _environment.default)().name;
    }
  }

  function configureDebugLogging(env, eventstreamService) {
    // If the DEBUG_LOG_EVENTS config option is not enabled make the debug
    // function a no-op.
    if (env.eventstream && !env.eventstream.DEBUG_LOG_EVENTS) {
      eventstreamService.debug = () => {};
    }
  } // This initializer sets the "client" variable of the Eventstream service to
  // an instance of the EventstreamClient.
  //
  // We do this at initialization to 1) enable us to detect the environment after
  // the application has bootup and 2) decouple the service from the application
  // environment.
  //


  function initialize(appInstance) {
    const config = appInstance.resolveRegistration('config:environment');
    const eventstream = appInstance.lookup('service:eventstream');
    const client = new _eventstream.default({
      applicationName: getApplicationName(config),
      environment: getEnvironmentName(config)
    });
    appInstance.registerOptionsForType('eventstream-client', {
      instantiate: false,
      singleton: true
    });
    appInstance.register('eventstream-client:main', client);
    eventstream.set('client', client);
    configureDebugLogging(config, eventstream);
  }

  var _default = {
    name: 'eventstream',
    initialize
  };
  _exports.default = _default;
});