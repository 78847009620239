define("ember-svg-jar/inlined/bone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.109 3.305c1.067-1.848 3.425-2.512 5.276-1.443.686.396 1.201.973 1.523 1.634a3.805 3.805 0 012.177.502c1.852 1.07 2.456 3.443 1.39 5.291a3.885 3.885 0 01-4.345 1.828l-2.828 4.898a3.884 3.884 0 01.59 4.676c-1.068 1.848-3.425 2.512-5.277 1.443a3.805 3.805 0 01-1.523-1.635 3.805 3.805 0 01-2.177-.502c-1.852-1.069-2.456-3.442-1.39-5.29a3.884 3.884 0 014.345-1.829l2.828-4.897a3.884 3.884 0 01-.59-4.676zm4.276.29c-.873-.505-2.017-.204-2.544.71-.528.914-.216 2.055.657 2.558l.866.5-4.696 8.133-.866-.5c-.873-.504-2.016-.203-2.544.71-.528.915-.216 2.055.657 2.56.473.273 1.021.31 1.514.141l1.086-.373.22 1.127c.1.511.406.967.88 1.24.873.505 2.017.204 2.544-.71.528-.914.216-2.055-.657-2.559l-.866-.5L15.332 8.5l.866.5c.872.504 2.016.203 2.544-.71.528-.915.216-2.056-.657-2.56a1.825 1.825 0 00-1.514-.141l-1.086.373-.22-1.128c-.1-.51-.406-.966-.88-1.24z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});