define("ember-svg-jar/inlined/scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.064 6.148a1 1 0 01.88-1.106L11 4.125v-.107a1 1 0 011.993-.12l7.836-.891a1 1 0 11.226 1.985l-1.637.186 2.502 5.833c.099.227.081.393.081.393a3.998 3.998 0 01-4 3.996c-2.209 0-4-1.79-4-3.996 0 0-.017-.166.082-.393l2.356-5.494L13 5.908v13.094h8A1 1 0 1121 21H3a1 1 0 110-1.998h8V6.136l-3.68.419 2.6 6.06c.098.227.08.393.08.393a3.998 3.998 0 01-4 3.996c-2.209 0-4-1.789-4-3.996 0 0-.017-.166.082-.393l2.463-5.744-1.374.156a1 1 0 01-1.107-.88zm14.454 4.257L18 6.947l1.484 3.458h-2.967zm-.25 1.998a2 2 0 003.466 0h-3.465zm-11.751-.394L6 8.552l1.483 3.457H4.517zm-.25 1.998a2 2 0 003.466 0H4.268z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});