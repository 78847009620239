define("ember-svg-jar/inlined/sign-eur-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.07 11.967c0-.337.025-.66.072-.967h5.483V9H5.87c.807-1.27 2.15-2 3.673-2 1.056 0 1.987.343 2.718.938l1.262-1.551C12.427 5.495 11.048 5 9.543 5c-2.71 0-4.919 1.58-5.91 4H1.625v2h1.5a8.69 8.69 0 00.006 2H1.625v2H3.64c.974 2.399 3.125 4 5.903 4 1.519 0 2.89-.483 3.977-1.36l-1.256-1.557c-.715.578-1.637.917-2.721.917-1.556 0-2.879-.732-3.673-2h4.755v-2H5.148a6.744 6.744 0 01-.077-1.033zM13.625 10.997h4.588l-1.792-1.793 1.414-1.414 3.5 3.5a1 1 0 010 1.414l-3.5 3.5-1.414-1.414 1.793-1.793h-4.589v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});