define("ember-svg-jar/inlined/pencil-writing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 4.17c.72 0 1.45.28 2 .83 1.1 1.1 1.1 2.9 0 4l-2 2-9 9H4v-4l9-9 2-2c.55-.55 1.28-.83 2-.83zm.01 2c-.13 0-.38.03-.59.24l-.58.6 1.17 1.17.59-.59c.21-.22.24-.46.24-.59 0-.13-.02-.38-.24-.59a.843.843 0 00-.59-.24zM6 18h1.17l8.41-8.41-1.17-1.17L6 16.83V18zm6 0h8v2h-8v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});