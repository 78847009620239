define("ember-svg-jar/inlined/eye-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.548 17.96h.002l2.75 2.75 1.41-1.41-2.4-2.4c1.5-1.12 2.75-2.63 3.6-4.47.12-.27.12-.58 0-.84C20.03 7.52 16.23 5 12 5c-1.61 0-3.15.37-4.55 1.04L4.71 3.29 3.294 4.706l10.724 10.725.002-.001.99.99h-.002l1.54 1.54zm-5.058-7.89l2.44 2.44c.04-.16.07-.33.07-.51 0-1.1-.9-2-2-2-.18 0-.35.03-.51.07zm3.94 3.95l1.43 1.43A9.59 9.59 0 0019.89 12c-1.61-3.1-4.6-5-7.89-5-1.05 0-2.06.21-3.01.58l.99.99C10.57 8.22 11.26 8 12 8c2.21 0 4 1.79 4 4 0 .74-.21 1.43-.57 2.02z\" fill=\"#7B61FF\"/><path d=\"M11.751 15.992L8.008 12.25a4 4 0 003.743 3.743z\" fill=\"#7B61FF\"/><path d=\"M5.617 9.858A9.612 9.612 0 004.11 12c1.61 3.1 4.6 5 7.89 5 .244 0 .486-.011.725-.033l1.739 1.738c-.795.193-1.62.295-2.464.295-4.23 0-8.03-2.52-9.91-6.58-.12-.26-.12-.57 0-.84A11.793 11.793 0 014.2 8.441l1.417 1.417z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});