define("ember-svg-jar/inlined/custom-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.243 7.757L2 12l4.243 4.243L10.485 12 6.243 7.757zM11.9 2.1L7.657 6.343l4.243 4.243 4.242-4.243L11.9 2.1zM7.657 17.657l4.243-4.243 4.242 4.243L11.9 21.9l-4.243-4.243zm9.9-9.9L13.314 12l4.243 4.243L21.799 12l-4.242-4.243z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});