define("ember-svg-jar/inlined/gas-pump", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 5a2 2 0 012-2h7a2 2 0 012 2v9h1a3 3 0 013 3v1.5a.5.5 0 101 0v-5.67a3 3 0 01-2.188-5.585l-1.546-1.673 1.468-1.358 3.414 3.692c.527.54.852 1.28.852 2.094v8.5a2.5 2.5 0 01-5 0V17a1 1 0 00-1-1h-1v5H3V5zm2 0h7v4H5V5zm0 6v8h7v-8H5zm13.703-1.712a1 1 0 10.033.035l-.034-.035z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});