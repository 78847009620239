define("ember-svg-jar/inlined/receipt-printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 17v-2H4v2h12zM20 16a1 1 0 11-2 0 1 1 0 012 0zM9 8h6V6H9v2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 1a1 1 0 001-1h2a1 1 0 002 0h2a1 1 0 102 0h2a1 1 0 001 1v2h3a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V6a3 3 0 013-3h3V1zm12 9V5h3a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h3v5H4v2h16v-2h-2zm-2 0H8V2.236C8.53 2.711 9.232 3 10 3s1.47-.289 2-.764c.53.475 1.232.764 2 .764s1.47-.289 2-.764V10z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});