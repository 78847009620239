define("ember-svg-jar/inlined/diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.5 3h-13L1.75 8 12 21.67 22.25 8 18.5 3zM11 7l1-1.33L13 7h-2zm2.72 2L12 15.88 10.28 9h3.44zM14 5h2l-1 1.33L14 5zM9 6.33L8 5h2L9 6.33zM7 7H5l1-1.33L7 7zm1.22 2l1.61 6.44L5 9h3.22zm7.56 0H19l-4.83 6.44L15.78 9zM17 7l1-1.33L19 7h-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});