define("ember-svg-jar/inlined/deposit-sign-gbp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.75 9.067c.432-.432 1.154-.466 1.734.178.341.379.5.684.658 1.506l1.964-.378C16.9 9.305 16.62 8.63 15.97 7.907c-1.229-1.365-3.274-1.616-4.636-.254.045-.044-.229.193-.493.523-.125.157-.26.345-.393.566l-.217-.217L8.818 9.94l.642.642a1.56 1.56 0 01-.847.477 1.62 1.62 0 01-.66-.007.898.898 0 01-.22-.075l.006.006.018.018-1.414 1.414 4.526 4.526 1.414-1.415-2.68-2.68a3.583 3.583 0 001.271-.85l1.48 1.48 1.414-1.414-1.837-1.837c.16-.366.336-.627.474-.8.123-.154.193-.217.26-.278l.085-.079z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.488 14a9 9 0 10-16.975 0H0v8h24v-8h-3.512zm-3.538 1.95A7.013 7.013 0 0115.609 17H8.39a7 7 0 118.558-1.05zM5.29 17a9.008 9.008 0 01-.776-1H2v4h20v-4h-2.515l.027-.04A9.01 9.01 0 0118.71 17H21v2H3v-2h2.291z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});