define("ember-svg-jar/inlined/card-swipe-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21 7h-1V6c0-1.66-1.34-3-3-3H3C1.34 3 0 4.34 0 6v8c0 1.66 1.34 3 3 3h1v1c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8c0-1.66-1.34-3-3-3zM3 5h14c.55 0 1 .45 1 1v1H2V6c0-.55.45-1 1-1zm0 10c-.55 0-1-.45-1-1V9h16v5c0 .55-.45 1-1 1H3zm19 3c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-1h11c1.66 0 3-1.34 3-3V9h1c.55 0 1 .45 1 1v8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});