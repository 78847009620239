define("@square/ember-qr-code/utils/square-qrcode", ["exports", "@square/qrcode-encoder"], function (_exports, _qrcodeEncoder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "SquareQRCodeEncoder", {
    enumerable: true,
    get: function get() {
      return _qrcodeEncoder.SquareQRCodeEncoder;
    }
  });
});