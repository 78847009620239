define("ember-svg-jar/inlined/dashed-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 3h-1v2h1a7 7 0 11-7 7v-1H3v1a9 9 0 109-9z\" fill=\"#7B61FF\"/><path d=\"M8.762 5.792l.886-.463-.926-1.773-.886.464a9.002 9.002 0 00-3.168 2.76 9.007 9.007 0 00-.645 1.048l-.464.886 1.772.928.464-.885A6.969 6.969 0 017.05 7.05a7.042 7.042 0 011.712-1.259zM11 7v5a1 1 0 00.293.707l2.997 2.997 1.414-1.414L13 11.586V7h-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});