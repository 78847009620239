define("ember-svg-jar/inlined/visa-multicolor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z\" fill=\"#fff\"/><path d=\"M.5 4A3.5 3.5 0 014 .5h28A3.5 3.5 0 0135.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4z\" stroke=\"#000\" stroke-opacity=\".15\"/><path d=\"M20.49 10.071c-.005.409.499.678 1.322 1.081 1.358.622 1.985 1.377 1.976 2.37-.018 1.809-1.625 2.978-4.099 2.978-1.055-.011-2.072-.222-2.621-.465l.33-1.952.303.139c.773.325 1.274.457 2.216.457.676 0 1.402-.267 1.408-.851.004-.382-.304-.654-1.22-1.082-.893-.417-2.075-1.116-2.062-2.369.014-1.694 1.652-2.877 3.977-2.877.913 0 1.643.19 2.11.366l-.32 1.89-.21-.1a4.306 4.306 0 00-1.762-.335c-.92 0-1.347.388-1.347.75z\" fill=\"#1434CB\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.346 7.66h1.82l1.904 8.713h-2.184s-.216-1-.286-1.306l-1.652-.002h-.004l-1.358-.002c-.092.236-.496 1.31-.496 1.31h-2.471l3.495-7.99c.247-.568.67-.723 1.232-.723zm-.145 3.182s-.743 1.935-.938 2.438h1.954l-.545-2.518-.16-.752c-.061.171-.148.398-.214.57v.001c-.06.158-.104.273-.097.261z\" fill=\"#1434CB\"/><path d=\"M11.267 7.658l-2.304 5.946-.246-1.209-.825-4.006c-.142-.553-.556-.717-1.067-.737H3.03L3 7.834a9.813 9.813 0 012.472.956l2.093 7.573 2.49-.003 3.705-8.702h-2.493zM13.241 16.37l1.472-8.719h2.353l-1.472 8.72H13.24z\" fill=\"#1434CB\"/>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});