define("ember-svg-jar/inlined/pin-slash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 2a1 1 0 00-1 1v3.586L4.407 2.993 2.993 4.407l16.6 16.6 1.414-1.414L16.414 15H19v-1c0-2.1-1.237-3.874-3-4.927V3a1 1 0 00-1-1H9zm1 6.586L14.414 13h2.426c-.323-.993-1.122-1.874-2.274-2.43L14 10.297V4h-4v4.586z\" fill=\"#7B61FF\"/><path d=\"M13 17.242l-2-2V21l1 1 1-1v-3.758zM10.757 15l-2-2H7.16c.12-.368.305-.721.55-1.048l-1.424-1.423C5.487 11.493 5 12.682 5 14v1h5.757z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});