define("ember-svg-jar/inlined/ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 18a1 1 0 01-1-1v-1.126a4.002 4.002 0 000-7.748V7a1 1 0 011-1h14a1 1 0 011 1v1.126a4.002 4.002 0 000 7.748V17a1 1 0 01-1 1H5zm17-9V7a3 3 0 00-3-3H5a3 3 0 00-3 3v3h1a2 2 0 110 4H2v3a3 3 0 003 3h14a3 3 0 003-3v-3h-1a2 2 0 110-4h1V9zM11 9a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2h-2zm0 2h2v2h-2v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});