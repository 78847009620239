define("ember-svg-jar/inlined/ic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.947 5.072a.824.824 0 00-.679.002l-3.04 1.388a.378.378 0 00-.008.69l3.033 1.461a.824.824 0 00.71.002l3.073-1.461a.378.378 0 00-.008-.692l-3.08-1.39zm.001.525a.824.824 0 00-.681 0L3.709 6.763a.042.042 0 000 .077l2.548 1.204c.221.105.48.105.702 0L9.505 6.84a.042.042 0 000-.077L6.947 5.597zM4.605 9.461c0-.19.157-.345.35-.345h3.346c.193 0 .35.154.35.345v8.036c0 .19-.157.345-.35.345H4.955a.348.348 0 01-.35-.345V9.46zm13.999 4.704v-.003H14.56a.688.688 0 01-.692-.684c0-.377.31-.683.692-.683h4.471c.02 0 .039 0 .058.002h1.562c.193 0 .35-.154.35-.345V9.463a.348.348 0 00-.35-.345h-6.752a.353.353 0 00-.13.024c-2.218.233-3.946 2.087-3.946 4.339 0 1.952 1.298 3.604 3.088 4.162v.043c0 .726.595 1.314 1.33 1.314.68 0 1.241-.504 1.32-1.155h1.83c.08.65.64 1.155 1.321 1.155.68 0 1.242-.504 1.321-1.155h.618c.193 0 .35-.155.35-.346v-2.988a.348.348 0 00-.35-.346h-2.046zm-3.513 3.571c0 .465-.381.841-.851.841a.846.846 0 01-.852-.84c0-.465.381-.841.852-.841.47 0 .85.376.85.84zm3.62.841c.47 0 .851-.376.851-.84a.846.846 0 00-.851-.841.846.846 0 00-.852.84c0 .465.381.841.852.841z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});