define("ember-svg-jar/inlined/no-entry-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 3c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9zm7 9c0 1.57-.53 3.02-1.4 4.18L7.82 6.4C8.98 5.53 10.43 5 12 5c3.86 0 7 3.14 7 7zM5 12c0-1.57.53-3.02 1.4-4.18l9.78 9.78c-1.16.87-2.61 1.4-4.18 1.4-3.86 0-7-3.14-7-7z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});