define("ember-svg-jar/inlined/x-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 12a9 9 0 1118.001.001A9 9 0 013 12zm2 0c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7zm7-1.41l2.29-2.3 1.42 1.42-2.3 2.29 2.3 2.29-1.42 1.42L12 13.42l-2.29 2.29-1.42-1.42 2.3-2.29-2.3-2.29 1.42-1.42 2.29 2.3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});