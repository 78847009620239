define("ember-svg-jar/inlined/coin-sign-jpy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.989 11.125L7.985 7.218h2.248l1.766 3.444 1.752-3.444h2.244l-1.988 3.907h1.207v2h-2.211v.75h2.21v2h-2.21V17h-2v-1.125H8.785v-2h2.218v-.75H8.785v-2H9.99z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-2 0a7 7 0 11-14 0 7 7 0 0114 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});