define("ember-svg-jar/inlined/thumbs-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.584 22a3.013 3.013 0 002.835-1.991l.604-1.69a5 5 0 013.984-3.266c.042.53.485.947 1.026.947h2.94a1.03 1.03 0 001.03-1.03V3.03A1.03 1.03 0 0020.973 2h-2.94a1.03 1.03 0 00-1.03 1.03v.14A9 9 0 0012.565 2H8.822a6 6 0 00-5.788 4.421l-1.306 4.79A3 3 0 004.622 15h2.85l-.818 3.272C6.178 20.18 7.645 22 9.584 22zm9.42-8h1V4h-1v10zm-8.468 5.336c-.14.393-.524.664-.952.664-.665 0-1.144-.625-.99-1.242l1.13-4.515A1 1 0 008.753 13H4.621a1 1 0 01-.965-1.263l1.306-4.79A4 4 0 018.823 4h3.742a7 7 0 013.892 1.182l.546.365v7.49a7 7 0 00-5.864 4.609l-.603 1.69z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});