define("ember-svg-jar/inlined/folder-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.997 4a3 3 0 00-3 3v10a3 3 0 003 3h7v-2h-7a1 1 0 01-1-1V7a1 1 0 011-1h4.086l2 2h7.914a1 1 0 011 1v1.5h2V9a3 3 0 00-3-3h-7.086l-2-2H4.997zM19 21a1 1 0 100-2 1 1 0 000 2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.997 15v1H22a2 2 0 012 2v4a2 2 0 01-2 2h-6a2 2 0 01-2-2v-4a2 2 0 011.997-2v-1a3 3 0 116 0zm-2 0v1h-2v-1a1 1 0 112 0zM16 18h6v4h-6v-4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});