define("ember-svg-jar/inlined/pill-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 14v-2h2v2h2v2h-2v2h-2v-2H9v-2h2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 4a3 3 0 00-3-3H9a3 3 0 00-3 3H4v6h2v10a3 3 0 003 3h6a3 3 0 003-3V10h2V4h-2zM9 3a1 1 0 00-1 1h8a1 1 0 00-1-1H9zM6 6v2h12V6H6zm10 4H8v10a1 1 0 001 1h6a1 1 0 001-1V10z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});