define("ember-svg-jar/inlined/gold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.294 2.708l1.414-1.414 2.998 2.998-1.414 1.414-2.998-2.998zm15.998-1.414l-2.998 2.998 1.414 1.414 2.998-2.998-1.414-1.414zm-.57 6.742l4.001-1.172.562 1.92-4.002 1.171-.562-1.92zM1.28 6.868l-.562 1.92L4.72 9.96l.562-1.92L1.28 6.868zM21.72 17h-1.83l-1.67-5h-1.83l-1.67-5H9.28l-1.67 5H5.78l-1.67 5H2.28L.61 22h22.78l-1.67-5zm-4.94-3l.33 1h-3.22l.33-1h2.56zm-6.06-5h2.56l.33 1h-3.22l.33-1zm2.06 3L12 14.34 11.22 12h1.56zm-5.56 2h2.56l.33 1H6.89l.33-1zm-3.83 6l.33-1h2.56l.33 1H3.39zm5.89-3l-.78 2.34L7.72 17h1.56zm1.11 3l.33-1h2.56l.33 1h-3.22zm4.33-3h1.56l-.78 2.34-.78-2.34zm2.67 3l.33-1h2.56l.33 1h-3.22z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});