define("ember-svg-jar/inlined/sign-gbp-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.37 9.154c0-1.245 1.014-2.23 2.555-2.15.878.047 1.456.246 2.548.985l1.121-1.656c-1.299-.879-2.223-1.255-3.564-1.326-2.51-.132-4.66 1.587-4.66 4.147 0-.041-.038.429.029 1.024.052.472.17 1.066.413 1.745H2.625v2h1.813a2.904 2.904 0 01-.555 1.983c-.276.384-.614.68-.91.875a2.254 2.254 0 01-.361.199.599.599 0 01-.079.028S2.566 17 2.625 17v2h10v-2H5.559c.562-.81.946-1.85.884-3.077h4.182v-2H5.97a7.28 7.28 0 00-.078-.18 6.04 6.04 0 01-.507-1.788c-.037-.336-.028-.524-.02-.667a2.7 2.7 0 00.004-.134zM13.624 10.997h4.59L16.42 9.204l1.415-1.414 3.5 3.5a1 1 0 010 1.414l-3.5 3.5-1.415-1.414 1.793-1.793h-4.589v-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});