define("ember-svg-jar/inlined/celebrate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_45548_153603)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14 8c-1.1 0-2-.9-2-2s.9-2 2-2c-1.1 0-2-.9-2-2s.9-2 2-2v2c1.1 0 2 .9 2 2s-.9 2-2 2v2zM9 4a1 1 0 100-2 1 1 0 000 2zm8.294 1.292l2.998-2.998 1.414 1.414-2.998 2.998-1.414-1.414zM0 24L8 6l10 10-18 8zm10.66-6.93l-3.73-3.73-2.99 6.72 6.72-2.99zm1.96-.86l1.91-.85-5.88-5.89-.85 1.91 4.82 4.83zM22 15a1 1 0 11-2 0 1 1 0 012 0zm0-5c0-1.1-.9-2-2-2s-2 .9-2 2h-2c0 1.1.9 2 2 2s2-.9 2-2c0 1.1.9 2 2 2s2-.9 2-2h-2z\" fill=\"#7B61FF\"/></g><defs><clipPath id=\"clip0_45548_153603\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});