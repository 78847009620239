define("@market/ember/services/market-toasts", ["exports", "ember", "@ember/service", "@ember/debug", "@ember/test", "@ember/runloop"], function (_exports, _ember, _service, _debug, _test, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var MarketToastVariant;

  (function (MarketToastVariant) {
    MarketToastVariant["Critical"] = "critical";
    MarketToastVariant["Info"] = "info";
    MarketToastVariant["Success"] = "success";
    MarketToastVariant["Warning"] = "warning";
  })(MarketToastVariant || (MarketToastVariant = {}));

  /**
   * Convenience service for displaying market toasts in an Ember app.
   *
   * `marketToasterSelector` can be configured by apps to specify which `market-toaster` to use to show the toast.
   *
   * `dismissText` should be overridden by apps concerned with localization.
   *
   * Usage:
   *
   * this.marketToasts.critical('Server was unable to complete your request', { persistent: true });
   */
  class MarketToasts extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "marketToasterSelector", 'market-toaster');

      _defineProperty(this, "dismissText", 'Dismiss');

      _defineProperty(this, "activeToasts", new Map());

      _defineProperty(this, "nextToastId", 1);
    }

    _getToaster() {
      const toaster = document.querySelector(this.marketToasterSelector);
      (false && !(toaster) && (0, _debug.assert)(`[market-toasts] could not find an element for selector '${this.marketToasterSelector}'. You may need to add a 'market-toaster' to your application's template, or if one exists, make sure the configured selector matches the element.`, toaster));
      (false && !(toaster.tagName === 'MARKET-TOASTER') && (0, _debug.assert)(`[market-toasts] the element specified by selector '${this.marketToasterSelector}' must match a 'market-toaster' element. Got ${toaster.tagName}`, toaster.tagName === 'MARKET-TOASTER'));
      return toaster;
    }

    critical(message, options) {
      return this.show(message, MarketToastVariant.Critical, options !== null && options !== void 0 ? options : {});
    }

    info(message, options) {
      return this.show(message, MarketToastVariant.Info, options !== null && options !== void 0 ? options : {});
    }

    success(message, options) {
      return this.show(message, MarketToastVariant.Success, options !== null && options !== void 0 ? options : {});
    }

    warning(message, options) {
      return this.show(message, MarketToastVariant.Warning, options !== null && options !== void 0 ? options : {});
    } // eslint-disable-next-line @typescript-eslint/no-explicit-any


    appendAction(toast, action, actionText) {
      const toastButton = document.createElement('button');
      toastButton.slot = 'action';
      toastButton.innerText = actionText !== null && actionText !== void 0 ? actionText : '';

      toastButton.onclick = function (ev) {
        (0, _runloop.run)(() => {
          action.call(this, ev);
        });
      };

      toast.appendChild(toastButton);
    }

    show(message, variant, {
      persistent = false,
      progress = -1,
      action,
      actionText,
      secondaryAction,
      secondaryActionText,
      htmlSafe
    }) {
      // create toast element
      const toast = document.createElement('market-toast');
      toast.variant = variant;
      toast.progress = progress;

      if (htmlSafe) {
        toast.innerHTML = message;
      } else {
        toast.appendChild(document.createTextNode(message));
      }

      if (persistent) {
        toast.persistent = true;
      } // dismiss button is required, but aria-label can be localized


      toast.dismissButtonAriaLabel = this.dismissText; // append optional actions if passed

      if (action) {
        this.appendAction(toast, action, actionText);
      }

      if (secondaryAction) {
        this.appendAction(toast, secondaryAction, secondaryActionText);
      }

      const toastId = this.nextToastId++;
      this.activeToasts.set(toastId, toast);
      let showFinished = false;

      this._getToaster().show(toast).finally(() => {
        showFinished = true;
      });

      _ember.default.testing && (0, _test.registerWaiter)(() => showFinished);
      return toastId;
    }

    remove(toastId) {
      const toast = this.activeToasts.get(toastId);

      if (toast) {
        let exitAnimationComplete = false;
        this.activeToasts.delete(toastId);

        this._getToaster().hide(toast).finally(() => {
          exitAnimationComplete = true;
        });

        _ember.default.testing && (0, _test.registerWaiter)(() => exitAnimationComplete);
      }
    }

    updateProgress(toastId, progress) {
      const toast = this.activeToasts.get(toastId);

      if (toast) {
        toast.progress = progress;
      }
    }

  }

  _exports.default = MarketToasts;
});