define("ember-svg-jar/inlined/timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12a9 9 0 01-18 0c0-4.63 3.5-8.44 8-8.94V1h2v2.06c1.74.19 3.32.88 4.62 1.92l1.46-1.46 1.41 1.41-1.46 1.46A8.963 8.963 0 0121 12zm-2 0c0-3.86-3.14-7-7-7s-7 3.14-7 7 3.14 7 7 7 7-3.14 7-7zm-8 0V7h2v5h-2z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});