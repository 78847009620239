define("ember-svg-jar/inlined/envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 5H5C3.34 5 2 6.34 2 8v8c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3zm-.17 2L12 11.78 5.17 7h13.66zM19 17H5c-.55 0-1-.45-1-1V8.62l7.43 5.2a.988.988 0 001.14 0L20 8.62V16c0 .55-.45 1-1 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});