define("ember-svg-jar/inlined/square-reader-magstripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_143790_224710)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#7B61FF\"><path d=\"M14.305 6.405a1.67 1.67 0 00-2.362 0L8.648 9.7a1.67 1.67 0 000 2.361l3.295 3.296a1.67 1.67 0 002.362 0L17.6 12.06a1.67 1.67 0 000-2.361l-3.296-3.295zm-4.01 4.475l2.829-2.828 2.828 2.829-2.828 2.828-2.829-2.829z\"/><path d=\"M15.245 1.688a3 3 0 00-4.243 0L3.932 8.76a3 3 0 000 4.243l1.413 1.414-2.354 2.355a1.67 1.67 0 000 2.361l.233.234-2.121 2.121a1 1 0 101.414 1.414l2.121-2.121.234.233a1.67 1.67 0 002.361 0l2.355-2.354 1.414 1.414a3 3 0 004.243 0l7.071-7.071a3 3 0 000-4.243l-7.071-7.07zm-2.829 1.414a1 1 0 011.415 0l7.07 7.071a1 1 0 010 1.415l-7.07 7.07a1 1 0 01-1.415 0l-7.07-7.07a1 1 0 010-1.415l7.07-7.07zM6.76 15.83l-2.122 2.122 1.415 1.414 2.12-2.122L6.76 15.83z\"/></g><defs><clipPath id=\"clip0_143790_224710\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});