define("ember-svg-jar/inlined/scan-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 6a3 3 0 013-3h3v2H3a1 1 0 00-1 1v3H0V6zM18 5V3h3a3 3 0 013 3v3h-2V6a1 1 0 00-1-1h-3zM22 15v3a1 1 0 01-1 1h-3v2h3a3 3 0 003-3v-3h-2zM6 19v2H3a3 3 0 01-3-3v-3h2v3a1 1 0 001 1h3zM7 8H5v8h2V8zM17 8h-2v8h2V8zM10 8h2v8h-2V8zM9 8H8v8h1V8zM18 8h1v8h-1V8zM14 8h-1v8h1V8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});