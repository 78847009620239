define("@market/ember/modifiers/open-modal", ["exports", "ember-modifier", "@market/ember"], function (_exports, _emberModifier, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /*
   * Automatically opens the modal template it's attached to on first render.
   *
   * Usage:
   *
   * {!-- parent.hbs --}
   * <CoolModal {{open-modal}} />
   *
   * {!-- cool-modal.hbs --}
   * <template ...attributes>
   *   <market-modal-partial>
   *     Cool modal content goes here
   *   </market-modal-partial>
   * </template>
   */
  class OpenModal extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modal", void 0);
    }

    async didInstall() {
      const selector = this.args.positional[0];
      this.modal = await (0, _ember.openModal)(this.element, selector);
    }

    willDestroy() {
      // Don't dismiss the modal if it is no longer present in the DOM.
      if (this.modal !== undefined && document.getElementById(this.modal.id)) {
        (0, _ember.dismissModal)(this.modal);
      }

      this.modal = undefined;
    }

  }

  _exports.default = OpenModal;
});