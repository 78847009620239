define("ember-svg-jar/inlined/cloud-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.046 6.273A5.5 5.5 0 0120 9.5v.028A6 6 0 0116 20H7a5 5 0 01-2.975-9.02 4.5 4.5 0 016.02-4.708zM14.5 6a3.5 3.5 0 00-3.167 2.007L10.84 9.05l-.963-.637a2.5 2.5 0 00-3.782 2.774l.222.78-.716.378A3 3 0 007 18h9a4 4 0 002.38-7.216l-.46-.34.06-.569A3.5 3.5 0 0014.5 6z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});