define("ember-svg-jar/inlined/receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7 11h10V9H7v2zM7 15h10v-2H7v2z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 0h2a1 1 0 002 0h2a1 1 0 002 0h2a1 1 0 102 0h2a1 1 0 102 0h2v24h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0h-2a1 1 0 10-2 0H7a1 1 0 10-2 0H3V0zm2 21.17c.313-.11.65-.17 1-.17.768 0 1.47.289 2 .764A2.989 2.989 0 0110 21c.768 0 1.47.289 2 .764A2.989 2.989 0 0114 21c.768 0 1.47.289 2 .764A2.989 2.989 0 0118 21c.35 0 .687.06 1 .17V2.83c-.313.11-.65.17-1 .17-.768 0-1.47-.289-2-.764A2.989 2.989 0 0114 3c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0110 3c-.768 0-1.47-.289-2-.764A2.989 2.989 0 016 3c-.35 0-.687-.06-1-.17v18.34z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});