define("ember-svg-jar/inlined/hair-dryer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.5 8.414L7.914 6 6.5 4.586 4.086 7 5.5 8.414zM7 11.414L10.914 7.5 9.5 6.086 5.586 10 7 11.414z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 13.745A7 7 0 018 1h14v6.026l-7.044 1.765A7.007 7.007 0 0110 14.711V20H8.524a3.038 3.038 0 01-.795 1.578c-.507.534-1.156.85-1.758 1.045C4.801 23 3.53 23 3.023 23H2v-2h1c.511 0 1.507-.006 2.357-.28.419-.136.726-.313.922-.518.056-.06.107-.126.151-.202H4v-6.255zM3 8a5 5 0 015-5h9v3.217L13 7.22V8a5 5 0 01-5 5H7v2h1v3H6v-5.38l-.5-.288A4.998 4.998 0 013 8zm16-5v2.716l1-.25V3h-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});