define("ember-svg-jar/inlined/location-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 2c3.86 0 7 3.14 7 7 0 3.52-2.61 6.44-6 6.92V22h-2v-6.08c-3.39-.49-6-3.4-6-6.92 0-3.86 3.14-7 7-7zM7 9c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5zm6.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});