define("ember-svg-jar/inlined/binoculars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.68 10.32L16.86 6.5H16c0-.83-.67-1.5-1.5-1.5S13 5.67 13 6.5h-2c0-.83-.67-1.5-1.5-1.5S8 5.67 8 6.5h-.86l-3.82 3.82C2.47 11.17 2 12.3 2 13.5 2 15.98 4.02 18 6.5 18c2.38 0 4.31-1.86 4.47-4.19.32.12.67.19 1.03.19s.71-.07 1.03-.19c.16 2.33 2.09 4.19 4.47 4.19 2.48 0 4.5-2.02 4.5-4.5 0-1.2-.47-2.33-1.32-3.18zM6.5 16A2.5 2.5 0 014 13.5c0-.67.26-1.3.73-1.77S5.83 11 6.5 11a2.5 2.5 0 010 5zm2.71-6.07c-.53-.4-1.15-.7-1.83-.84l.58-.59h2.38c-.51.34-.91.84-1.13 1.43zM12 12c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm1.65-3.5h2.38l.59.59c-.68.14-1.3.44-1.83.84-.22-.59-.62-1.09-1.14-1.43zM17.5 16a2.5 2.5 0 11.002-5.002A2.5 2.5 0 0117.5 16z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});