define("ember-svg-jar/inlined/empty-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 2a2 2 0 00-2 2v3h2V4h3V2H4zm5 0v2h3v3h2V4a2 2 0 00-2-2H9zm5 7h-2v3H9v2h3a2 2 0 002-2V9zm-7 5v-2H4V9H2v3a2 2 0 002 2h3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});