define("ember-svg-jar/inlined/finger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 8c-.39 0-.77.08-1.12.22A2.994 2.994 0 0014 6c-.77 0-1.47.3-2 .78-.53-.48-1.23-.78-2-.78-.35 0-.69.07-1 .18V3c0-1.65-1.35-3-3-3S3 1.35 3 3v12c0 4.96 4.04 9 9 9s9-4.04 9-9v-4c0-1.65-1.35-3-3-3zm-8 0c.55 0 1 .45 1 1v1H9V9c0-.55.45-1 1-1zM6 2c.55 0 1 .45 1 1v7H6c-.35 0-.69.07-1 .18V3c0-.55.45-1 1-1zm6 20c-3.86 0-7-3.14-7-7v-2c0-.55.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1H7v2c2.21 0 4 1.79 4 4h2c0-1.54-.59-2.94-1.54-4H12c1.65 0 3-1.35 3-3 0-1.3-.84-2.4-2-2.82V9c0-.55.45-1 1-1s1 .45 1 1v4c0 1.65 1.35 3 3 3 .33 0 .65-.07.95-.17C18.53 19.3 15.58 22 12 22zm6-8c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});