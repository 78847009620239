define("ember-svg-jar/inlined/pay-pay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.506 21h-.844A2.67 2.67 0 013 18.338V8.636a33.584 33.584 0 016.573-.047L6.506 21zm10.831-8.392c.416-1.72-2.357-3.31-6.793-3.91L8.93 15.401c3.723.176 7.95-.943 8.405-2.794h.001zM18.338 3H5.662a2.67 2.67 0 00-2.655 2.52c10.619-.3 15.783 3.392 14.973 7.068-.73 3.315-4.1 4.586-9.566 5.003l-.82 3.403h10.744A2.67 2.67 0 0021 18.334V5.66A2.669 2.669 0 0018.338 3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});