define("@square/environment-utils/api-urls", ["exports", "@square/environment-utils/-private/host-matcher", "@ember/debug"], function (_exports, _hostMatcher, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apiHost = apiHost;
  // Mappings of Square domains to API sub-domains.
  //
  // TODO: while most of the entries in this array are general to Square,
  // consumers may want to configure or add to this array (evidenced by the
  // presence of one Dashboard specific mapping here).
  const HOST_MATCHERS = [new _hostMatcher.default(/^(.+\.)*squareup.com$/, () => 'api.squareup.com'), new _hostMatcher.default(/^(.+\.)*squareupsandbox.com$/, () => 'api.squareupsandbox.com'), new _hostMatcher.default(/^(.+\.)*squareupstaging.com$/, () => 'api.squareupstaging.com'), new _hostMatcher.default(/^(.+\.)*dashboard-frontend.squareup.com.([^.]+\.local)$/, match => `api.dashboard-frontend.squareup.com.${match[2]}`), new _hostMatcher.default(/^(.+\.)*squareup.com.([^.]+\.local)$/, match => `api.squareup.com.${match[2]}`), new _hostMatcher.default(/^localhost:(\d+)$/, match => `localhost:${match[1]}`)];
  /**
   * Determines the API sub-domain for a given domain.
   *
   * This function allows easily configuring an API call to be sent to
   * the API sub-domain. For example:
   *
   * - `api.squareup.com` instead of `squareup.com`
   *
   * @param {string} host
   * @returns {string} The API sub-domain
   */

  function apiHost(host) {
    for (let i = 0; i < HOST_MATCHERS.length; i++) {
      const match = HOST_MATCHERS[i].match(host);

      if (match) {
        return match;
      }
    }

    (false && (0, _debug.warn)(`cannot determine API host for host: ${host}`, false, {
      id: 'apiHost'
    }));
    return host;
  }
});