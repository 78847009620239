define("ember-svg-jar/inlined/gif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.5 10.75a.25.25 0 01.25-.25H10V9H7.75A1.75 1.75 0 006 10.75v2.5c0 .966.784 1.75 1.75 1.75H10v-3.5H8.5v2h-.75a.25.25 0 01-.25-.25v-2.5zM14 9h4v1.5h-2.5v1h2V13h-2v2H14V9zM11.25 9v6h1.5V9h-1.5z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 2a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H5zM4 5a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1H5a1 1 0 01-1-1V5z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});