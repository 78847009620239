define("ember-svg-jar/inlined/circle-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 9.496L12.006 15.7H9.983l3.135-6.16v-.044h-3.63V8H15v1.496z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 100-14 7 7 0 000 14z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});