define("ember-svg-jar/inlined/passkey-shield-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M88 48c0 3.434-3.635 6.35-4.485 9.52-.875 3.28.79 7.614-.87 10.484-1.685 2.91-6.29 3.625-8.655 5.99-2.365 2.365-3.075 6.97-5.99 8.655-2.915 1.685-7.205-.005-10.485.87-3.17.845-6.08 4.485-9.52 4.485-3.44 0-6.35-3.635-9.52-4.485-3.28-.875-7.615.79-10.485-.87-2.91-1.685-3.625-6.29-5.99-8.655-2.365-2.365-6.97-3.075-8.655-5.99-1.685-2.915.005-7.205-.87-10.485C11.63 54.35 7.99 51.44 7.99 48c0-3.44 3.635-6.35 4.485-9.52.875-3.28-.79-7.615.87-10.485 1.685-2.91 6.29-3.625 8.655-5.99 2.365-2.365 3.075-6.97 5.99-8.655 2.915-1.685 7.205.005 10.485-.87 3.17-.845 6.08-4.485 9.52-4.485 3.44 0 6.35 3.635 9.52 4.485 3.28.875 7.615-.79 10.485.87 2.87 1.66 3.625 6.29 5.99 8.655 2.365 2.365 6.97 3.075 8.655 5.99 1.66 2.87-.005 7.205.87 10.485C84.36 41.65 88 44.56 88 48z\" fill=\"url(#paint0_linear_1071_25295)\"/><path d=\"M48 74c14.36 0 26-11.64 26-26S62.36 22 48 22 22 33.64 22 48s11.64 26 26 26z\" fill=\"#121212\"/><path d=\"M55.76 60.17L48 66l-7.77-5.83A13.988 13.988 0 0134 48.525v-10.6L48 32l14 5.925V48.52c0 4.685-2.34 9.055-6.24 11.65z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M58.414 43.79L45.75 56.453l-8.164-8.165 2.828-2.828 5.336 5.336 9.836-9.836 2.828 2.828z\" fill=\"#006AFF\"/><defs><linearGradient id=\"paint0_linear_1071_25295\" x1=\"31.245\" y1=\"77.019\" x2=\"71.255\" y2=\"7.724\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".25\" stop-color=\"#006AFF\"/><stop offset=\".57\" stop-color=\"#B6DE36\"/><stop offset=\"1\" stop-color=\"#D0FF80\"/></linearGradient></defs>",
    "attrs": {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});