define("ember-svg-jar/inlined/umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.997 14h2v-2c0-5.523-4.477-10-10-10s-10 4.477-10 10v2h2a2 2 0 114 0h2a2 2 0 011-1.732V19a1 1 0 11-2 0v-1h-2v1a3 3 0 106 0v-6.732a2 2 0 011 1.732h2a2 2 0 114 0zm-8-10a8.003 8.003 0 00-7.85 6.453A3.983 3.983 0 015.997 10a3.99 3.99 0 013 1.354 3.99 3.99 0 013-1.354 3.99 3.99 0 013 1.354 3.99 3.99 0 013-1.354c.668 0 1.297.164 1.85.453A8.003 8.003 0 0011.998 4z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});