define("ember-svg-jar/inlined/withdraw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 2h22v8h-5v12H5V10H0V2h2zm5 16v2h2c0-1.1-.9-2-2-2zm8 2h2v-2c-1.1 0-2 .9-2 2zm-2 0c0-2.21 1.79-4 4-4V7H7v9c2.21 0 4 1.79 4 4h2zm6-12h3V4H2v4h3V7H3V5h18v2h-2v1zm-4 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-2 0c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});