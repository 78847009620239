define("ember-svg-jar/inlined/cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 10.93c0 2.472 1.811 4.32 3.973 4.888l-1.368 2.735 1.79.894L8.117 16h2.264l-.607 1.213 1.798.877L12.618 16h2.264l-1.277 2.553 1.79.894L17.117 16h.085c1.534 0 2.803-.677 3.644-1.736C21.65 13.252 22 11.982 22 10.77c0-1.303-.46-2.437-1.294-3.294a4.951 4.951 0 00-1.205-.9c-.124-1.282-.638-2.426-1.52-3.319-1.205-1.22-2.88-1.756-4.55-1.756-1.468 0-3.419.375-4.666 1.696a4.229 4.229 0 00-2.962.882 3.936 3.936 0 00-1.496 2.77C2.975 7.676 2 9.082 2 10.93zm2 0c0-1.006.505-1.827 1.292-2.343a3.624 3.624 0 011.192-.497 2.373 2.373 0 01-.19-.841c-.038-1.23 1.022-2.139 2.272-2.063.364.022.744.128 1.118.333.1-.335.264-.625.48-.872.692-.795 1.919-1.147 3.268-1.147 2.526 0 4.463 1.615 4.042 4.442C18.905 8.265 20 9.154 20 10.77 20 12.465 19.014 14 17.203 14H7.368C5.558 14 4 12.627 4 10.93zm3.605 10.623l1.276-2.55 1.797.876-1.284 2.568-1.789-.894z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});