define("ember-svg-jar/inlined/phone-wireless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.51 5.51L2.1 4.1c5.46-5.46 14.34-5.46 19.79 0l-1.41 1.41C15.8.83 8.19.83 3.51 5.51z\" fill=\"#7B61FF\"/><path d=\"M4.93 6.93l1.41 1.41A7.949 7.949 0 0112 6c2.14 0 4.15.83 5.66 2.34l1.41-1.41A9.932 9.932 0 0012 4C9.33 4 6.82 5.04 4.93 6.93z\" fill=\"#7B61FF\"/><path d=\"M7.76 9.76l1.41 1.41a4.004 4.004 0 015.66 0l1.41-1.41A5.928 5.928 0 0012 8c-1.6 0-3.11.62-4.24 1.76zM17 16c0-.55-.45-1-1-1H8c-.55 0-1 .45-1 1v8H5v-8c0-1.66 1.34-3 3-3h8c1.66 0 3 1.34 3 3v8h-2v-8z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});