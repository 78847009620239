define("ember-svg-jar/inlined/coin-sign-eur", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.567 11.978c0-.121.005-.239.015-.353H12.5v-2h-1.982A2.535 2.535 0 0112.211 9c.628 0 1.173.203 1.6.55L15.072 8a4.49 4.49 0 00-2.862-1C10.35 7 8.83 8.037 8.077 9.625H7v2h.577a6.025 6.025 0 00.003.75H7v2h1.08C8.82 15.948 10.305 17 12.212 17c1.085 0 2.073-.346 2.86-.981l-1.258-1.556c-.414.335-.953.537-1.602.537a2.518 2.518 0 01-1.7-.625H12.5v-2H9.585a4.36 4.36 0 01-.018-.397z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-2 0a7 7 0 11-14 0 7 7 0 0114 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});