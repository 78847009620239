define("ember-svg-jar/inlined/circle-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.844 13.216c0 1.518-1.034 2.684-2.86 2.684-1.793 0-2.773-1.034-2.828-2.497h1.76c.066.616.451 1.067 1.079 1.067.638 0 1.089-.462 1.089-1.254 0-.803-.451-1.254-1.111-1.254a1.11 1.11 0 00-.958.561H9.3l.352-4.433h4.774v1.496H11.18l-.187 1.826h.056c.23-.396.726-.759 1.463-.759 1.341 0 2.332.935 2.332 2.563z\" fill=\"#7B61FF\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-2 0a7 7 0 11-14 0 7 7 0 0114 0z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});