define("ember-svg-jar/inlined/bullet-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.5 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8 7.5h13v-2H8v2zM21 13H8v-2h13v2zM4.5 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM21 18.5H8v-2h13v2zM4.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" fill=\"#7B61FF\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});